// Demo page
import React from "react";
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  Divider,
  Image,
  Box,
} from "@chakra-ui/react";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Demo = () => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <Center w="100vw" h="100vh">
      <Stack align="center" spacing={5}>
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
          alt="IDOONA Logo"
        />
        <Text
          fontSize={["2xl", "4xl"]}
          fontWeight="bold"
          color={colorMode === "light" ? "primary" : "secondary"}
        >
          {t("demo")}
        </Text>
        <Divider
          size="50"
          borderColor={colorMode === "light" ? "primary" : "secondary"}
        />
        <Box
          maxW="auto"
          mx="auto"
          h={["215px", "565px"]}
          w={["380px", "1000px"]}
        >
          <ReactPlayer
            url="https://youtu.be/0viMriBrQkI?si=-M71R7wUmDSVEstV"
            controls={true}
            width="100%"
            height="100%"
            borderRadius="5px"
          />
        </Box>
        <Divider
          size="50"
          borderColor={colorMode === "light" ? "primary" : "secondary"}
        />
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={handleBackClick}
        >
          {t("back")}
        </Button>
      </Stack>
    </Center>
  );
};

export default Demo;
