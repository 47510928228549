// Getting Started page index
import FirstStage from "./FirstStage";
import Amazing from "./Amazing";
import GeneralInformation from "./GeneralInformation";
import { Box } from "@chakra-ui/react";
import { useWizardStore } from "store";

const GettingStarted = () => {
  const steps = useWizardStore((state) => state.steps);
  let currentPage;
  switch (steps) {
    case 0:
      currentPage = <FirstStage />;
      break;
    case 1:
      currentPage = <Amazing />;
      break;
    case 2:
      currentPage = <GeneralInformation />;
      break;
    default:
      currentPage = <FirstStage />;
      break;
  }

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
    >
      {currentPage}
    </Box>
  );
};

export default GettingStarted;
