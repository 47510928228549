// Questions Page
import {
  Text,
  Box,
  Stack,
  Image,
  Button,
  useColorMode,
  ScaleFade,
  Fade,
  ButtonGroup,
  chakra,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import QuestionsPrimary from "assets/illustrations/questions-primary.svg";
import QuestionsSecondary from "assets/illustrations/questions-secondary.svg";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { ModuleItem } from "components/Others";
import { useEffect, useState } from "react";
import { useQuestionnaireStore } from "store";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import { getModuleQuestionList } from "utils/getModuleQuestionList";
import { useTranslation } from "react-i18next";

function Questions() {
  const { t } = useTranslation();
  const [questionsData, setQuestionsData] = useState([]);
  const [isQuestionsDataLoaded, setIsQuestionsDataLoaded] = useState(false);
  const { colorMode } = useColorMode();
  const {
    nextStep,
    prevStep,
    setCurrentQuestionId,
    resetCurrentQuestionId,
    currentModuleAnswers,
    currentModuleData,
    resetCurrentModuleData,
    resetCurrentModuleAnswers,
    setQuestionsIsAnswered,
    currentQuestionId,
    setAllModuleAnswers,
    resetCurrentDependentAnswers,
  } = useQuestionnaireStore();

  useEffect(() => {
    setQuestionsIsAnswered();
    resetCurrentDependentAnswers();
  }, [currentQuestionId]);

  useEffect(() => {
    const questionList = getModuleQuestionList(currentModuleData.questions);
    setQuestionsData(questionList);
  }, [currentModuleData]);

  useEffect(() => {
    if (Array.isArray(questionsData) && questionsData.length > 0) {
      setIsQuestionsDataLoaded(true);
    }
  }, [questionsData]);

  useEffect(() => {
    resetCurrentQuestionId();
  }, [resetCurrentQuestionId]);

  const isAnsweredChecker = (id) => {
    return currentModuleAnswers.some((answerObj) => {
      return (
        answerObj.id === id &&
        (Boolean(answerObj.answer) ||
          Boolean(answerObj.otherAnswer) ||
          Boolean(answerObj.answers.length > 0) ||
          Boolean(answerObj.dependentAnswers.length > 0))
      );
    });
  };

  const answerPull = (id) => {
    return currentModuleAnswers.find((answerObj) => {
      return answerObj.id === id
        ? answerObj.otherAnswer
          ? answerObj.otherAnswer
          : answerObj.answer
        : null;
    });
  };

  const answersPull = (id) => {
    return currentModuleAnswers.find((answerObj) => {
      return answerObj.id === id
        ? answerObj.answers
          ? answerObj.answers.length
            ? answerObj.answers
            : null
          : null
        : null;
    });
  };

  const dependentAnswersPull = (id) => {
    return currentModuleAnswers.find((answerObj) => {
      return answerObj.id === id
        ? answerObj.dependentAnswers
          ? answerObj.dependentAnswers.length
            ? answerObj.dependentAnswers
            : null
          : null
        : null;
    });
  };

  const isQuestionnaireComplete = () =>
    currentModuleAnswers.length === currentModuleData.questions.length &&
    currentModuleAnswers.every(
      (answerObj) =>
        Boolean(answerObj.answer) ||
        Boolean(answerObj.otherAnswer) ||
        Boolean(answerObj.answers.length > 0)
    );

  const handleClick = (id) => {
    setCurrentQuestionId(id);
    nextStep();
  };

  const handleButtonClick = () => {
    setAllModuleAnswers();
    resetCurrentModuleData();
    resetCurrentModuleAnswers();
    prevStep();
  };

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      h="100vh"
      w="100vw"
    >
      <Stack w={["90vw", "75vw"]} align="center" spacing="5">
        <ScaleFade initialScale="0.8" in>
          {isQuestionnaireComplete() ? (
            <Image
              objectFit="cover"
              src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
              alt={t("idoonaLogoAlt")}
              w="100px"
            />
          ) : (
            <Image
              w={"180px"}
              src={
                colorMode === "light" ? QuestionsPrimary : QuestionsSecondary
              }
              alt={t("questionsPage.image")}
              marginTop={["2vh", null]}
            />
          )}
        </ScaleFade>
        {isQuestionnaireComplete() ? (
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "gray.100"}
          >
            {t("questionsPage.congratulations")}
          </Text>
        ) : (
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "gray.100"}
          >
            {t("questionsPage.listOfQuestions")}
          </Text>
        )}
        {isQuestionnaireComplete() ? (
          <Text
            w={["90vw", "60vw"]}
            color={colorMode === "light" ? "gray.900" : "gray.300"}
            fontSize="lg"
          >
            {t("questionsPage.completedModuleMessage")}
          </Text>
        ) : (
          <Text
            w={["90vw", "60vw"]}
            color={colorMode === "light" ? "gray.900" : "gray.300"}
            fontSize="lg"
          >
            {t("questionsPage.listOfQuestionsMessage1")}
            <chakra.span
              color={colorMode === "light" ? "primary" : "secondary"}
              fontWeight="bold"
            >
              {currentModuleData.moduleName}
            </chakra.span>
            {t("questionsPage.listOfQuestionsMessage2")}
          </Text>
        )}
        <Stack
          display="flex"
          p="10px"
          spacing="2"
          w={["90vw", "50vw"]}
          h={[null, "40vh"]}
          overflow="auto"
          sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
        >
          {isQuestionsDataLoaded &&
            questionsData?.map((question, i) => (
              <Fade in key={i}>
                <ModuleItem
                  id={question.id}
                  variant={question.variant}
                  isAnswered={
                    !isQuestionnaireComplete()
                      ? isAnsweredChecker(question.id)
                      : "edit"
                  }
                  answer={
                    isQuestionnaireComplete()
                      ? answerPull(question.id)?.otherAnswer
                        ? answerPull(question.id)?.otherAnswer
                        : answerPull(question.id)?.answer
                      : null
                  }
                  answers={
                    isQuestionnaireComplete()
                      ? answersPull(question.id)?.answers
                        ? answersPull(question.id)?.answers
                        : []
                      : []
                  }
                  dependentAnswers={
                    isQuestionnaireComplete()
                      ? dependentAnswersPull(question.id)?.dependentAnswers
                        ? dependentAnswersPull(question.id)?.dependentAnswers
                        : []
                      : []
                  }
                  label={question.label}
                  description={question.question}
                  onClick={(e) => handleClick(question.id)}
                />
              </Fade>
            ))}
        </Stack>
        <ButtonGroup>
          {!isQuestionnaireComplete() && (
            <Button
              variant={
                colorMode === "light" ? "primaryOutline" : "secondaryOutline"
              }
              width="120px"
              onClick={handleButtonClick}
            >
              {t("back")}
            </Button>
          )}
          <Button
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            onClick={handleButtonClick}
            isDisabled={!isQuestionnaireComplete()}
          >
            {t("complete")}
          </Button>
        </ButtonGroup>
        <IconButton
          icon={<ArrowBackIcon />}
          variant={
            colorMode === "light" ? "primaryOutline" : "secondaryOutline"
          }
          pos="absolute"
          top="0"
          left="5"
          margin="20px"
          onClick={handleButtonClick}
        >
          {t("back")}
        </IconButton>
        <Box h="2vh" display={["block", "none"]}></Box>
      </Stack>
    </Box>
  );
}

export default Questions;
