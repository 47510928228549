// The Problem Section
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  chakra,
  HStack,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useWizardStore } from "store";
import theProblemPrimary from "assets/illustrations/the-problem-primary.svg";
import theProblemSecondary from "assets/illustrations/the-problem-secondary.svg";

function TheProblem() {
  const { colorMode } = useColorMode();
  const { setStep } = useWizardStore();
  const { t } = useTranslation();

  const handleClick = () => setStep(2);
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Stack align="center" spacing={5}>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize={["4xl", "6xl"]}
            fontWeight="bold"
            paddingTop={["25px", "none"]}
            paddingRight={["15px", "none"]}
          >
            {t("problem.title")}
          </Text>
          <Image
            display={["inline", "none"]}
            maxWidth={["20", "48"]}
            h={["20", "48"]}
            src={
              colorMode === "light" ? theProblemPrimary : theProblemSecondary
            }
          />
        </Stack>
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <HStack textAlign="left">
            <Image
              display={["none", "inline"]}
              maxWidth={["20", "48"]}
              h={["20", "48"]}
              src={
                colorMode === "light" ? theProblemPrimary : theProblemSecondary
              }
            />
            <Stack paddingLeft={["0px", "25px"]} w={["90vw", "40vw"]}>
              <Text fontSize="xl" w={["90vw", "40vw"]}>
                <chakra.span fontWeight="bold">
                  {t("problem.message1.bold")}
                </chakra.span>{" "}
                {t("problem.message1.text")}
              </Text>
              <Text fontSize="xl">
                <chakra.span
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("problem.message2.highlight")}
                </chakra.span>{" "}
                {t("problem.message2.text")}
                <chakra.span fontWeight="bold">
                  {t("problem.message2.bold")}
                </chakra.span>{" "}
                {t("problem.message2.end")}
              </Text>
              <Text fontSize="xl" w={["90vw", "40vw"]}>
                {t("problem.message3")}
              </Text>
            </Stack>
          </HStack>
          <HStack textAlign="left" w={["90vw", "40vw"]}>
            <Text
              display={["none", "inline"]}
              fontSize="7xl"
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              88%
            </Text>
            <Stack paddingLeft={["0px", "70px"]} paddingTop={["0px", "40px"]}>
              <Text fontSize="xl">
                <chakra.span
                  color={colorMode === "light" ? "primary" : "secondary"}
                  display={["inline", "none"]}
                >
                  88%
                </chakra.span>{" "}
                {t("problem.message4")}
              </Text>
              <Text
                fontSize="l"
                w={["90vw", "40vw"]}
                textAlign="right"
                color={colorMode === "light" ? "primary" : "secondary"}
              >
                * {t("problem.source")}
              </Text>
            </Stack>
          </HStack>
        </Stack>
        {!isMobile && (
          <Button
            variant={colorMode === "light" ? "primary" : "secondary"}
            onClick={handleClick}
          >
            {t("problem.cta")}
          </Button>
        )}
      </Stack>
    </Center>
  );
}

export default TheProblem;
