// New Landing Page
import {
  Text,
  Stack,
  Center,
  HStack,
  Image,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";

export const Module = ({ name, description, regulations = [] }) => {
  const jurisdictionFlag = regulations[0]?.jurisdictionId.toLowerCase();
  const { colorMode } = useColorMode();
  return (
    <Center display="flex" justifyContent="center">
      <Stack align="center">
        <Image
          mt={["2", "0"]}
          ml={["2", "0"]}
          objectFit="cover"
          display={["none", "block"]}
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
        />
        <Text fontSize={["2xl", "6xl"]} fontWeight="bold">
          {name}
        </Text>
        <Stack spacing="1" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text w={["90vw", "60vw"]} fontSize={["l", "lg"]}>
            {description}
          </Text>
          <Text w={["90vw", "60vw"]} fontSize={["l", "lg"]}>
            It is relevant for your compliance reporting in:
          </Text>
        </Stack>
        <HStack>
          <Box className={`react-tel-input flag ${jurisdictionFlag}`}></Box>
        </HStack>
      </Stack>
    </Center>
  );
};
