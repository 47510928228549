import {
  Text,
  useColorMode,
  Image,
  Divider,
  HStack,
  Box,
  Button,
  Stack,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useWizardStore, useQuestionnaireStore } from "store";
import NaturePrimary from "assets/illustrations/work-environment-primary.svg";
import manufacturingPrimary from "assets/illustrations/manufacturing-primary.svg";
import NatureSecondary from "assets/illustrations/work-environment-secondary.svg";
import manufacturingSecondary from "assets/illustrations/manufacturing-secondary.svg";
import climateActionPrimary from "assets/illustrations/climate-action-primary.svg";
import climateActionSecondary from "assets/illustrations/climate-action-secondary.svg";
import humanRightsPrimary from "assets/illustrations/human-rights-primary.svg";
import humanRightsSecondary from "assets/illustrations/human-rights-secondary.svg";
import revenueGrowthPrimary from "assets/illustrations/revenue-growth-primary.svg";
import revenueGrowthSecondary from "assets/illustrations/revenue-growth-secondary.svg";
import impactOnLocalPrimary from "assets/illustrations/impact-on-local-primary.svg";
import impactOnLocalSecondary from "assets/illustrations/impact-on-local-secondary.svg";
import impactOnGlobalPrimary from "assets/illustrations/impact-on-global-primary.svg";
import impactOnGlobalSecondary from "assets/illustrations/impact-on-global-secondary.svg";
import energyUsagePrimary from "assets/illustrations/energy-usage-primary.svg";
import energyUsageSecondary from "assets/illustrations/energy-usage-secondary.svg";
import wasteManagementPrimary from "assets/illustrations/waste-management-primary.svg";
import wasteManagementSecondary from "assets/illustrations/waste-management-secondary.svg";
import waterUsePrimary from "assets/illustrations/water-use-primary.svg";
import waterUseSecondary from "assets/illustrations/water-use-secondary.svg";
import responsibleConsumptionPrimary from "assets/illustrations/responsible-consumption-primary.svg";
import responsibleConsumptionSecondary from "assets/illustrations/responsible-consumption-secondary.svg";
import naturalHabitatPrimary from "assets/illustrations/natural-habitat-primary.svg";
import naturalHabitatSecondary from "assets/illustrations/natural-habitat-secondary.svg";
import laborPracticesPrimary from "assets/illustrations/labor-practices-primary.svg";
import laborPracticesSecondary from "assets/illustrations/labor-practices-secondary.svg";
import promotionOfDiversityPrimary from "assets/illustrations/promotion-of-diversity-primary.svg";
import promotionOfDiversitySecondary from "assets/illustrations/promotion-of-diversity-secondary.svg";
import qualityEducationPrimary from "assets/illustrations/quality-education-primary.svg";
import qualityEducationSecondary from "assets/illustrations/quality-education-secondary.svg";
import transparencyAndCompliancePrimary from "assets/illustrations/transparency-and-compliance-primary.svg";
import transparencyAndComplianceSecondary from "assets/illustrations/transparency-and-compliance-secondary.svg";
import processEfficiencyPrimary from "assets/illustrations/process-efficiency-primary.svg";
import processEfficiencySecondary from "assets/illustrations/process-efficiency-secondary.svg";
import peaceJusticePrimary from "assets/illustrations/peace-justice-primary.svg";
import peaceJusticeSecondary from "assets/illustrations/peace-justice-secondary.svg";
import { useTranslation } from "react-i18next";

export const Module = ({
  index,
  image,
  title,
  description = "This module is unfortunately not available yet. Please stay in touch to get notified when new modules and features are launched.",
  isReady,
  isAnswered,
  onClick,
}) => {
  const { colorMode } = useColorMode();
  const setCurrentStep = useWizardStore((state) => state.nextStep);
  const setCurrentQuestionnaireStep = useQuestionnaireStore(
    (state) => state.nextStep
  );
  const { t } = useTranslation();

  const handleClick = (page) => {
    isReady
      ? image.length > 10
        ? setCurrentQuestionnaireStep(page)
        : setCurrentStep(page)
      : onClick();
  };

  let moduleImage;
  switch (image) {
    case "644804ec389f1a6e1ce5033e": // Climate Action
      if (colorMode === "light") moduleImage = climateActionPrimary;
      else moduleImage = climateActionSecondary;
      break;
    case "6447fcce389f1a6e1ce50211": // Human Rights and Supply Chain Design
      if (colorMode === "light") moduleImage = humanRightsPrimary;
      else moduleImage = humanRightsSecondary;
      break;
    case "6447fd28389f1a6e1ce5023b": // Revenue Growth and Business Model
      if (colorMode === "light") moduleImage = revenueGrowthPrimary;
      else moduleImage = revenueGrowthSecondary;
      break;
    case "6447fe1a389f1a6e1ce50263": // Impact on Local Communities and Markets
      if (colorMode === "light") moduleImage = impactOnLocalPrimary;
      else moduleImage = impactOnLocalSecondary;
      break;
    case "6447fe7c389f1a6e1ce502a3": // Impact on Global Communities and Markets
      if (colorMode === "light") moduleImage = impactOnGlobalPrimary;
      else moduleImage = impactOnGlobalSecondary;
      break;
    case "644800f9389f1a6e1ce502c1": // Emissions and Carbon Footprint
      if (colorMode === "light") moduleImage = manufacturingPrimary;
      else moduleImage = manufacturingSecondary;
      break;
    case "6448012d389f1a6e1ce502d5": // Energy Use and Efficiency
      if (colorMode === "light") moduleImage = energyUsagePrimary;
      else moduleImage = energyUsageSecondary;
      break;
    case "644801b1389f1a6e1ce502e9": // Waste Management
      if (colorMode === "light") moduleImage = wasteManagementPrimary;
      else moduleImage = wasteManagementSecondary;
      break;
    case "644804ae389f1a6e1ce502fd": // Water Use and Management
      if (colorMode === "light") moduleImage = waterUsePrimary;
      else moduleImage = waterUseSecondary;
      break;
    case "644804bf389f1a6e1ce50311": // Responsible Consumption and Production
      if (colorMode === "light") moduleImage = responsibleConsumptionPrimary;
      else moduleImage = responsibleConsumptionSecondary;
      break;
    case "644804d1389f1a6e1ce50325": // Natural Habitats and Life on Our Planet
      if (colorMode === "light") moduleImage = naturalHabitatPrimary;
      else moduleImage = naturalHabitatSecondary;
      break;
    case "644804fe389f1a6e1ce50352": // Labor Practices and Working Conditions
      if (colorMode === "light") moduleImage = laborPracticesPrimary;
      else moduleImage = laborPracticesSecondary;
      break;
    case "64480514389f1a6e1ce50366": // Promotion of Diversity, Equity and Inclusion
      if (colorMode === "light") moduleImage = promotionOfDiversityPrimary;
      else moduleImage = promotionOfDiversitySecondary;
      break;
    case "64480523389f1a6e1ce5037a": // Quality Education
      if (colorMode === "light") moduleImage = qualityEducationPrimary;
      else moduleImage = qualityEducationSecondary;
      break;
    case "64480534389f1a6e1ce5038e": // Transparency and Compliance with Laws and Regulations
      if (colorMode === "light") moduleImage = transparencyAndCompliancePrimary;
      else moduleImage = transparencyAndComplianceSecondary;
      break;
    case "64480543389f1a6e1ce503a2": // Process Efficiency and Digitization
      if (colorMode === "light") moduleImage = processEfficiencyPrimary;
      else moduleImage = processEfficiencySecondary;
      break;
    case "64480552389f1a6e1ce503b6": // Peace, Justice and Strong Institutions
      if (colorMode === "light") moduleImage = peaceJusticePrimary;
      else moduleImage = peaceJusticeSecondary;
      break;
    default:
      if (colorMode === "light") moduleImage = NaturePrimary;
      else moduleImage = NatureSecondary;
      break;
  }

  return (
    <>
      {index !== 0 && (
        <Divider
          borderColor={colorMode === "light" ? "primary" : "secondary"}
        />
      )}
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Stack position="relative">
          {isAnswered ? (
            <CheckCircleIcon
              color={colorMode === "light" ? "green" : "lightGreen"}
              position="absolute"
              bottom="0"
              right="-4"
              width={25}
              height={25}
            />
          ) : null}
          {moduleImage && (
            <Image
              maxWidth="20"
              h="20"
              src={moduleImage}
              marginTop={["20px", "0px"]}
            />
          )}
        </Stack>
        <Box w={["85vw", "50vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {title}
          </Text>
          {description}
        </Box>
        <Box p="10px">
          <Button
            variant={
              colorMode === "light"
                ? "primarySlimPointer"
                : "secondarySlimPointer"
            }
            onClick={() => handleClick(3)}
          >
            {t("accessModule")}
          </Button>
        </Box>
      </HStack>
    </>
  );
};
