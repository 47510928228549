// Jurisdictions
import { useState, useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Image,
  chakra,
  HStack,
  Divider,
  useColorMode,
  Fade,
  ButtonGroup,
  useDisclosure,
} from "@chakra-ui/react";
import "assets/css/flags.css";
import { useSignUpStore } from "store";
import JurisdictionsPrimary from "assets/illustrations/jurisdictions-primary.svg";
import JurisdictionsBlue from "assets/illustrations/jurisdictions-blue.svg";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { ModuleItem } from "components/Others";
import PopularRegions from "../../../constants/popularRegions.json";
import countriesData from "../../../constants/countries.json";
import { CustomModal } from "components/Others";
import { useTranslation } from "react-i18next";

const Jurisdictions = () => {
  const [checkedCountries, setCheckedCountries] = useState({});
  const [headquartersCode, setHeadquartersCode] = useState("");
  const [headquartersName, setHeadquartersName] = useState("");
  const [unCheckedCountries, setUnCheckedCountries] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    addJurisdictions,
    addEmployees,
    addRevenues,
    user,
    steps,
    nextStep,
    prevStep,
    setIsRedirected,
  } = useSignUpStore();
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const modalTitle = t("jurisdictions.modalTitle");
  const modalDescription = t("jurisdictions.modalDescription");
  const modalDescription2 = t("jurisdictions.modalDescription2");
  const icon = "mdi-light:flag";

  useEffect(() => {
    setIsRedirected(false);
  }, []);

  useEffect(() => {
    let countryCode;
    let countryName;
    let url;
    let addressParts = user.basic.address.split(",");
    var payload = addressParts[addressParts.length - 1].replace(/\s/g, "");
    if (payload.length > 3) {
      url = "https://restcountries.com/v3.1/name/" + payload + "?fullText=true";
    } else {
      url = "https://restcountries.com/v3.1/alpha/" + payload;
    }
    // TODO: Make into a conditional fetch function depending on any change in data of user.jurisdictions (prevState !== currentState)
    if (user.jurisdictions)
      fetch(url)
        .then((response) => response.json())
        .then((jsonResponse) => {
          countryCode = jsonResponse[0].cca2;
          countryName = jsonResponse[0].name.common;
          setHeadquartersCode(countryCode);
          setHeadquartersName(countryName);
        })
        .catch((error) => {
          console.log(error);
        });
    // end of fetch conditional
    if (headquartersCode !== "") {
      setCheckedCountries((prevState) => ({
        ...prevState,
        [headquartersCode]: headquartersName,
      }));
    }
  }, [user.basic.address, headquartersCode, headquartersName]);

  const checkHandler = (e, code, name) => {
    if (e.target.checked) {
      setCheckedCountries((prevState) => ({
        ...prevState,
        [code]: name,
      }));
    } else {
      setCheckedCountries((prevState) => {
        prevState = Object.keys(prevState)
          .filter((key) => key !== code)
          .reduce((obj, key) => {
            obj[key] = prevState[key];
            return obj;
          }, {});
        return prevState;
      });
      setUnCheckedCountries((prevState) => {
        if (!prevState.includes(code)) {
          return [...prevState, code];
        }
        return prevState;
      });
    }
  };

  useEffect(() => {
    if (user.jurisdictions) {
      setCheckedCountries(user.jurisdictions);
    }
  }, [steps, user.jurisdictions]);

  const handleBackClick = (e) => {
    addJurisdictions(checkedCountries);
    prevStep();
  };

  const handleNextClick = (e) => {
    addJurisdictions(checkedCountries);
    unCheckedCountries.forEach((code) => {
      addRevenues((prevState) => {
        const newState = {};
        Object.keys(prevState).forEach((key) => {
          if (key !== code) {
            newState[key] = prevState[key];
          }
        });
        return newState;
      });

      addEmployees((prevState) => {
        const newState = {};
        Object.keys(prevState).forEach((key) => {
          if (key !== code) {
            newState[key] = prevState[key];
          }
        });
        return newState;
      });
    });

    nextStep();
  };

  const yourJurisdiction = () => {
    return (
      <Stack
        display="flex"
        w={["90vw", "450px"]}
        overflow="auto"
        sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
      >
        <Fade in>
          <ModuleItem
            code={headquartersCode}
            name={headquartersName}
            variant="jurisdictions"
          />
        </Fade>
      </Stack>
    );
  };

  const popularRegions = () => {
    return (
      <Stack
        display="flex"
        w={["90vw", "450px"]}
        h={["120px", "150px"]}
        overflow="auto"
        sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
      >
        {PopularRegions?.map((country, i) => (
          <Fade in key={i}>
            <ModuleItem
              code={country.code}
              name={country.name}
              onChange={(e) => checkHandler(e, country.code, country.name)}
              isChecked={checkedCountries[country.code] !== undefined}
            />
          </Fade>
        ))}
      </Stack>
    );
  };

  const moreRegions = () => {
    return (
      <Stack
        display="flex"
        w={["90vw", "450px"]}
        h={["180px", "150px"]}
        overflow="auto"
        sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
      >
        {countriesData?.map((country, i) => (
          <Fade in key={i}>
            <ModuleItem
              code={country.code}
              name={country.name}
              onChange={(e) => checkHandler(e, country.code, country.name)}
              isChecked={checkedCountries[country.code] !== undefined}
            />
          </Fade>
        ))}
      </Stack>
    );
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={
              colorMode === "light" ? JurisdictionsPrimary : JurisdictionsBlue
            }
            alt="IDOONA Logo"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={3}
              p="1.5rem"
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("jurisdictions.title")}
              </Heading>
              <Text
                width={["90vw", "450px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("jurisdictions.description")}
              </Text>
              <Box display="flex" w="100%">
                <chakra.span
                  onClick={onOpen}
                  cursor="pointer"
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("whatDoesThisMean")}
                </chakra.span>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Text
                  textAlign={["center", "left"]}
                  fontWeight="bold"
                  color={colorMode === "light" ? "gray.900" : "gray.100"}
                >
                  {t("jurisdictions.yourJurisdictions")}
                </Text>

                <chakra.span
                  onClick={handleBackClick}
                  cursor="pointer"
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("jurisdictions.changeHQ")}
                </chakra.span>
              </Box>
              {yourJurisdiction()}
              <Divider />
              <Text
                fontWeight="bold"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("jurisdictions.popularRegions")}
              </Text>
              {popularRegions()}
              <Divider />
              <Text
                fontWeight="bold"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("jurisdictions.moreRegions")}
              </Text>
              {moreRegions()}

              <ButtonGroup>
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleBackClick}
                >
                  {t("back")}
                </Button>
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleNextClick}
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </HStack>
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        modalDescription2={modalDescription2}
      />
    </Flex>
  );
};

export default Jurisdictions;
