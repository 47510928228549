// Coming Soon Page
import React from "react";
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  chakra,
  ButtonGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import BackgroundPrimary from "assets/illustrations/under-construction-primary.svg";
import BackgroundSecondary from "assets/illustrations/under-construction-secondary.svg";
import { EmailIcon } from "@chakra-ui/icons";

function ComingSoon() {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  const handleSignUpClicked = () => {
    window.open(
      "https://idoona.us14.list-manage.com/subscribe?u=aabfd80d34cb83e83d15e0a8f&id=6ddb54356c",
      "_blank",
      "noreferrer"
    );
  };

  return (
    <Center
      display="flex"
      justifyContent="center"
      w="100vw"
      h="100vh"
      id="welcome-section"
      bgImage={`url(${
        colorMode === "light" ? BackgroundPrimary : BackgroundSecondary
      })`}
      backgroundRepeat="no-repeat"
      backgroundPosition="left bottom"
      backgroundSize={["50%", "30%"]}
    >
      <Stack textAlign="center" display="flex" align="center" spacing={5}>
        <Image
          objectFit="cover"
          width="300px"
          src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
          alt="IDOONA Logo"
        />
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text
            fontSize="3xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("comingSoon.title")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("comingSoon.description")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "60vw", "60vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("comingSoon.signUpPrompt")}
            <chakra.span fontWeight="bold">
              {t("comingSoon.companyNews")}
            </chakra.span>{" "}
            {t("comingSoon.and")}
            <chakra.span fontWeight="bold">
              {t("comingSoon.launchAnnouncement")}
            </chakra.span>
          </Text>
        </Stack>
        <ButtonGroup flexDirection={["column-reverse", "row"]} gap={4}>
          <Button
            marginLeft={2}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            aria-label="Go back"
            onClick={handleBackClick}
          >
            {t("goBack")}
          </Button>
          <Button
            leftIcon={<EmailIcon />}
            iconSpacing={3}
            variant={
              colorMode === "light"
                ? "primarySlimPointer"
                : "secondarySlimPointer"
            }
            aria-label="Sign up for email list"
            onClick={handleSignUpClicked}
          >
            {t("comingSoon.signUp")}
          </Button>
        </ButtonGroup>
      </Stack>
    </Center>
  );
}

export default ComingSoon;
