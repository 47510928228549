import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import fonts from "./fonts";
import { ButtonStyles as Button } from "assets/theme/components/buttonStyles";
import { CheckboxStyles as Checkbox } from "assets/theme/components/checkboxStyles";
import { RadioStyles as Radio } from "assets/theme/components/radioStyles";

/**
 * @DESC - Use this file to define colors, font styles, custom variant styles for Buttons and other components that are used from Chakra UI.
 * Check Chakra UI documentation on custom theming for more information
 *
 */

const overrides = {
  colors,
  fonts,
  components: {
    Button,
    Checkbox,
    Radio,
  },
  // Text,
  // Card,
  // Others go here
  //   },
};

export default extendTheme(overrides);
