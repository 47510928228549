// Choose Modules Page
import {
  Text,
  Box,
  Stack,
  Image,
  Button,
  useColorMode,
  ScaleFade,
  useDisclosure,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import { Module } from "components/Module";
import { useQuestionnaireStore } from "store";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { CustomModal } from "components/Others/CustomModal";
import { useTranslation } from "react-i18next";

function ChooseModules({ isLoading }) {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const {
    nextStep,
    setStep,
    questionBank,
    setCurrentModuleData,
    setCurrentModuleAnswers,
    setModuleIsAnswered,
  } = useQuestionnaireStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalTitle = t("chooseModules.modalTitle");
  const modalDescription = t("chooseModules.modalDescription");
  const icon = "mdi-light:alert";
  const moduleDescription = t("chooseModules.moduleDescription");
  const completedModuleDescription = t(
    "chooseModules.completedModuleDescription"
  );
  const completedModalTitle = t("chooseModules.completedModalTitle");
  const completedModalDescription = t(
    "chooseModules.completedModalDescription"
  );
  const completedIcon = "mdi-light:clipboard-check";

  useEffect(() => {
    if (!questionBank.length) {
      setStep(0);
    }
    setModuleIsAnswered();
  }, []);

  const handleClick = (id) => {
    const moduleDataObj = questionBank.find(
      (moduleData) => moduleData.moduleId === id
    );
    setCurrentModuleData(moduleDataObj);
    setCurrentModuleAnswers();
    nextStep();
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      h="100vh"
      w={["90vw", "100vw"]}
    >
      <Stack w={["85vw", "75vw"]} align={["flex-start", "center"]} spacing="5">
        {isMobile && <Box height="30px"></Box>}
        <ScaleFade initialScale="0.8" in>
          <Image
            mt={["2", "0"]}
            ml={["2", "0"]}
            objectFit="cover"
            src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
            alt={t("idoonaLogoAlt")}
            w="100px"
          />
        </ScaleFade>
        <Text
          fontSize="5xl"
          fontWeight="bold"
          color={colorMode === "light" ? "primary" : "gray.100"}
        >
          {questionBank.every((module) => module.isModuleAnswered)
            ? t("chooseModules.goodJob")
            : t("chooseModules.moduleSelection")}
        </Text>
        <Text
          w={["85vw", "60vw"]}
          color={colorMode === "light" ? "gray.900" : "gray.300"}
          fontSize="lg"
        >
          {questionBank.every((module) => module.isModuleAnswered)
            ? moduleDescription
            : completedModuleDescription}
        </Text>
        <Stack
          display="flex"
          p="10px"
          spacing="2"
          w={["85vw", "75vw", "60vw"]}
          h={[null, "60vh", "40vh"]}
          overflow="auto"
          sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
        >
          {questionBank.map((module, i) => (
            <Module
              key={i}
              title={module.moduleName}
              description={module.moduleDescription}
              image={module.moduleId}
              onClick={() => handleClick(module.moduleId)}
              isAnswered={module.isModuleAnswered}
              index={i}
            />
          ))}
        </Stack>
        <HStack position="relative" h="10px" mt={5}>
          {questionBank.every((module) => module.isModuleAnswered) ? (
            <Button
              margin={["5", "20"]}
              variant={
                colorMode === "light" ? "primaryOutline" : "secondaryOutline"
              }
              type="submit"
              isLoading={isLoading}
            >
              {t("submit")}
            </Button>
          ) : (
            <Button
              margin={["5", "20"]}
              variant={
                colorMode === "light" ? "primaryOutline" : "secondaryOutline"
              }
              onClick={onOpen}
            >
              {t("submit")}
            </Button>
          )}
        </HStack>
        {isMobile && <Box height="30px"></Box>}
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        isLoading={isLoading}
        modalDescription={modalDescription}
      />
    </Box>
  );
}

export default ChooseModules;
