import axios from "axios";
import Cookies from "js-cookie";
import { CONFIG } from "config";

const pdfApi = axios.create({
  baseURL: CONFIG.baseURL,
  withCredentials: true,
});

const endpoint = "report/pdf";
const pitchendpoint = "report/pitch-deck";

// POST Request
export const fetchReportPdfFn = async (reportId) => {
  try {
    const token = Cookies.get("token");
    const response = await pdfApi.post(
      endpoint,
      {
        report: reportId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching pdf", error);
  }
};

// GET Request
export const fetchPitchPdfFn = async () => {
  try {
    const response = await pdfApi.get(pitchendpoint, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    console.error("Error fetching pdf", error);
  }
};

// GET Request
export const fetchPdfFnWithUrl = async () => {
  try {
    const token = Cookies.get("token");
    // GET Request Fetches FROM URL
    const response = await pdfApi.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/pdf",
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching pdf", error);
  }
};
