// Reporting Scope
import { useState, useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Image,
  chakra,
  HStack,
  useColorMode,
  Fade,
  ButtonGroup,
  RadioGroup,
  useDisclosure,
} from "@chakra-ui/react";
import "assets/css/flags.css";
import { useSignUpStore } from "store";
import ScopePrimary from "assets/illustrations/scope-selection-primary.svg";
import ScopeBlue from "assets/illustrations/scope-selection-blue.svg";
import { ModuleItem } from "components/Others";
import { CustomModal } from "components/Others";
import { useTranslation } from "react-i18next";

const ReportingScope = () => {
  const [scope, setScope] = useState(null);
  const { addScope, user, steps, nextStep, prevStep, isRedirected } =
    useSignUpStore();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const modalTitle = t("reportingScope.title");
  const modalDescription = t("reportingScope.description");
  const icon = "mdi-light:sitemap";

  useEffect(() => {
    if (user.scope) {
      setScope(user.scope);
    }
  }, [steps, user.scope]);

  const handleBackClick = (e) => {
    addScope(scope);
    prevStep();
  };

  const handleNextClick = (e) => {
    addScope(scope);
    nextStep();
  };

  const modulesData = [
    {
      id: 1,
      variant: "scope",
      value: "minimum",
      label: t("reportingScope.minimum.label"),
      description: t("reportingScope.minimum.description"),
    },
    {
      id: 2,
      variant: "scope",
      value: "myJurisdictions",
      label: t("reportingScope.myJurisdictions.label"),
      description: t("reportingScope.myJurisdictions.description"),
    },
    {
      id: 3,
      variant: "scope",
      value: "all",
      label: t("reportingScope.all.label"),
      description: t("reportingScope.all.description"),
    },
  ];

  return (
    <Flex
      flexDirection="column"
      width={["90vw", "100vw"]}
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={colorMode === "light" ? ScopePrimary : ScopeBlue}
            alt="IDOONA Logo"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={3}
              p="1.5rem"
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("reportingScope.title")}
              </Heading>
              <Text
                width={["90vw", "400px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("reportingScope.description")}
              </Text>
              <Box display="flex" w={["85vw", "100%"]}>
                <chakra.span
                  onClick={onOpen}
                  cursor="pointer"
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("whatDoesThisMean")}
                </chakra.span>
              </Box>
              <RadioGroup onChange={setScope} value={scope}>
                <Stack
                  display="flex"
                  spacing="2"
                  paddingTop="20px"
                  paddingBottom="20px"
                  w={["90vw", "570px"]}
                >
                  {modulesData.map((module, i) => (
                    <Fade in key={i}>
                      <ModuleItem
                        id={module.id}
                        image={module.image}
                        variant={module.variant}
                        label={module.label}
                        description={module.description}
                        value={module.value}
                      />
                    </Fade>
                  ))}
                </Stack>
              </RadioGroup>
              <ButtonGroup>
                {!isRedirected ? (
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                ) : null}
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleNextClick}
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </HStack>
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
    </Flex>
  );
};

export default ReportingScope;
