import { Box, Input, Text, useColorMode } from "@chakra-ui/react";

export const FileUrl = ({
  setAnswer,
  label = "label",
  placeholder = "placeholder",
  value,
}) => {
  const { colorMode } = useColorMode();
  return (
    <Box align="start">
      <Text>{label}</Text>
      <Input
        onChange={setAnswer}
        width={["80vw", "40vw"]}
        variant="flushed"
        placeholder={placeholder}
        color={colorMode === "light" ? "primary" : "secondary"}
        value={value}
      />
    </Box>
  );
};
