// Imprint page
import React from "react";
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  Divider,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import { useNavigate } from "react-router-dom";

const Imprint = () => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <Center w="100vw" h="100vh">
      <Stack align="center" spacing={5}>
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
          alt="IDOONA Logo"
        />
        <Text
          fontSize={["3xl", "6xl"]}
          fontWeight="bold"
          color={colorMode === "light" ? "primary" : "secondary"}
        >
          {t("imprint.title")}
        </Text>
        <Divider
          size="50"
          borderColor={colorMode === "light" ? "primary" : "secondary"}
        />
        <Stack
          display="flex"
          flexDirection={["column", "row"]}
          alignItems="flex-start"
          textAlign="left"
          spacing="2"
          color={colorMode === "light" ? "black" : "gray.200"}
        >
          <Stack>
            <Text fontSize="l" w={["90vw", "25vw"]} fontWeight="bold">
              {t("imprint.editorialResponsibility")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.disclosures")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]} fontWeight="bold">
              {t("imprint.company")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.address1")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.address2")}
            </Text>
          </Stack>
          <Stack>
            <Text fontSize="l" w={["90vw", "25vw"]} fontWeight="bold">
              {t("imprint.registrationInfo")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.managingDirector")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.registryCourt")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.registryNumber")}
            </Text>
          </Stack>
          <Stack>
            <Text fontSize="l" w={["90vw", "25vw"]} fontWeight="bold">
              {t("imprint.contactDetails")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.email")}
            </Text>
            <Text fontSize="l" w={["90vw", "25vw"]}>
              {t("imprint.website")}
            </Text>
          </Stack>
        </Stack>
        <Divider
          size="50"
          borderColor={colorMode === "light" ? "primary" : "secondary"}
        />
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={handleBackClick}
        >
          {t("back")}
        </Button>
      </Stack>
    </Center>
  );
};

export default Imprint;
