// Your Report Page
import {
  Text,
  Stack,
  Image,
  useColorMode,
  Box,
  useDisclosure,
  IconButton,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import IdoonaBadge from "assets/icons/idoona-badge-draft-3-2.svg";
import { CustomModal } from "components/Others/CustomModal";
import { useReportStore } from "store";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoDark from "assets/icons/idoona-logo-blue.svg";
import ConfluenceLogo from "assets/icons/confluence-primary.svg";
import ConfluenceLogoDark from "assets/icons/confluence-secondary.svg";
import GithubLogo from "assets/icons/github-primary.svg";
import GithubLogoDark from "assets/icons/github-secondary.svg";
import NotionLogo from "assets/icons/notion-primary.svg";
import NotionLogoDark from "assets/icons/notion-secondary.svg";
import SharepointLogo from "assets/icons/sharepoint-primary.svg";
import SharepointLogoDark from "assets/icons/sharepoint-secondary.svg";
import { fetchReportPdfFn } from "config/pdf";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function YourReport() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const { nextStep, reportData } = useReportStore();
  const toast = useToast();
  const { t } = useTranslation();

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  const formatDate = (inputDate) => {
    return new Date(inputDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleDownloadPdf = async () => {
    setIsLoading(true);
    try {
      const response = await fetchReportPdfFn(reportData.id);
      // In case we want to generate directly from URL in future (if html/css doc gets too big)
      // const response = await fetchPdfFnWithUrl();
      if (response && response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `your-file-name.pdf`;
        link.click();
        setIsLoading(false);
        toastFunction("success", t("yourReport.downloadStarted"));
      }
    } catch (error) {
      setIsLoading(false);
      toastFunction("error", t("yourReport.downloadError"));
    }
  };

  function formatTimeFrame(timeFrame) {
    const fromDate = new Date(timeFrame.from);
    const toDate = new Date(timeFrame.to);

    const fromMonth = fromDate.toLocaleString("default", { month: "short" });
    const toMonth = toDate.toLocaleString("default", { month: "short" });

    const fromYear = fromDate.getFullYear();
    const toYear = toDate.getFullYear();

    const formattedTimeFrame = `${fromMonth} ${fromYear} to ${toMonth} ${toYear}`;
    return formattedTimeFrame;
  }

  /*
   TODO: To display the below data, are using tabular display with 4 columns here and in two other places on the PublishReport page.
   The code needs to be unified and made into a CustomTable component which will take in an array of objects, where each object will represent 1 row of data (4 columns).
   The CustomTable component should be able to receive any type of data as values within the passed array of objects and display them properly: string, numbers, display elements: <View> etc.
  */
  const leftColHeaders = [
    { key: t("yourReport.leftColHeaders.companyName") },
    { key: t("yourReport.leftColHeaders.creationDate") },
    { key: t("yourReport.leftColHeaders.reportId") },
    { key: t("yourReport.leftColHeaders.expirationDate") },
  ];

  const leftColValues = [
    { key: reportData.displayData.companyName },
    { key: formatDate(reportData.displayData.creationDate) },
    { key: reportData.id },
    { key: formatDate(reportData.displayData.expirationDate) },
  ];

  const rightColHeaders = [
    { key: t("yourReport.rightColHeaders.validity") },
    { key: t("yourReport.rightColHeaders.timeFrame") },
    { key: t("yourReport.rightColHeaders.contactPerson") },
    { key: t("yourReport.rightColHeaders.visibility") },
  ];

  const rightColValues = [
    { key: t("yourReport.current") },
    {
      key: formatTimeFrame(reportData.displayData.timeFrame),
    },
    { key: reportData.displayData.contactPerson },
    { key: t("yourReport.notPublished") },
  ];

  return (
    <Stack align="center" spacing="3">
      <Box h={20}></Box>
      <Image objectFit="cover" src={IdoonaBadge} alt="Idoona Badge" />
      <Text fontSize="6xl" fontWeight="bold">
        {t("yourReport.title")}
      </Text>
      <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
        <Text w={["90vw", "50vw"]}>{t("yourReport.description")}</Text>
      </Stack>
      <Box display="flex" gap="5">
        <Box display="flex" flexDirection={"column"} width={145}>
          {leftColHeaders.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text fontWeight="bold" p={2}>
                {item.key}
              </Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={200}>
          {leftColValues.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text p={2}>{item.key}</Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={145}>
          {rightColHeaders.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text fontWeight="bold" p={2}>
                {item.key}
              </Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={200} marginTop={-1}>
          {rightColValues.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              {i === 0 ? (
                <HStack p={0}>
                  <Button variant="currentTag">{item.key}</Button>
                  <IconButton
                    icon={<InfoOutlineIcon />}
                    aria-label="Info"
                    onClick={onOpen}
                    ml={2}
                    variant={
                      colorMode === "light"
                        ? "primaryRegular"
                        : "secondaryRegular"
                    }
                  >
                    More Info
                  </IconButton>
                </HStack>
              ) : i === 3 ? (
                <HStack p={0}>
                  <Button variant="notPublishedTag" onClick={nextStep}>
                    {item.key}
                  </Button>
                  <IconButton
                    icon={<InfoOutlineIcon />}
                    aria-label="Info"
                    onClick={onOpen}
                    ml={2}
                    variant={
                      colorMode === "light"
                        ? "primaryRegular"
                        : "secondaryRegular"
                    }
                  >
                    More Info
                  </IconButton>
                </HStack>
              ) : (
                <Text p={2}>{item.key}</Text>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Image
        objectFit="cover"
        src={colorMode === "light" ? IdoonaLogo : IdoonaLogoDark}
        alt="IDOONA Logo"
        w={10}
      />
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={500} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("yourReport.downloadReportTitle")}
          </Text>
          <Text>{t("yourReport.downloadReportDescription")}</Text>
        </Box>
        <Box p="10px">
          <Button
            w={200}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            isLoading={isLoading}
            onClick={handleDownloadPdf}
          >
            {t("yourReport.downloadPdfButton")}
          </Button>
        </Box>
      </HStack>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={500} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("yourReport.websiteBadgeTitle")}
          </Text>
          <Text>{t("yourReport.websiteBadgeDescription")}</Text>
        </Box>
        <Box p="10px">
          <Button
            w={200}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            onClick={nextStep}
          >
            {t("yourReport.getBadgeButton")}
          </Button>
        </Box>
      </HStack>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={500} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("yourReport.favoriteToolTitle")}
          </Text>
          <Text>{t("yourReport.favoriteToolDescription")}</Text>
        </Box>
        <Box p="10px">
          <HStack w={200}>
            <Image
              objectFit="cover"
              src={colorMode === "light" ? ConfluenceLogo : ConfluenceLogoDark}
              alt="Confluence Logo"
              cursor="pointer"
              onClick={onOpen}
              w={10}
            />
            <Image
              objectFit="cover"
              src={colorMode === "light" ? SharepointLogo : SharepointLogoDark}
              alt="Sharepoint Logo"
              cursor="pointer"
              onClick={onOpen}
              w={10}
            />
            <Image
              objectFit="cover"
              src={colorMode === "light" ? NotionLogo : NotionLogoDark}
              alt="IDOONA Logo"
              cursor="pointer"
              onClick={onOpen}
              w={10}
            />
            <Image
              objectFit="cover"
              src={colorMode === "light" ? GithubLogo : GithubLogoDark}
              alt="IDOONA Logo"
              cursor="pointer"
              onClick={onOpen}
              w={10}
            />
          </HStack>
        </Box>
      </HStack>
      <Box h={10}></Box>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={t("modal.icon")}
        modalTitle={t("modal.title")}
        modalDescription={t("modal.description")}
      />
    </Stack>
  );
}

export default YourReport;
