export const darkScrollBar = {
  "&::-webkit-scrollbar": {
    width: "10px",
    borderRadius: "8px",
    backgroundColor: `rgba(0, 0, 0, 0.10)`,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: `rgba(0, 0, 0, 0.25)`,
  },
};
