import axios from "axios";
import Cookies from "js-cookie";
import { CONFIG } from "config";

const questionnaireApi = axios.create({
  baseURL: CONFIG.baseURL,
  withCredentials: true,
});

const endpoint = "questionnaire/by-company";

export const fetchQuestionnaireFn = async (language) => {
  try {
    const token = Cookies.get("token");
    const response = await questionnaireApi.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        Language: language,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching questionnaire data:", error);
    Cookies.remove("token");
  }
};
