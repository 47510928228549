// Generate Report Page
import React, { useState, useEffect } from "react";
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  Box,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import { CustomModal } from "components/Others/CustomModal";
import { useReportStore } from "store";
import { AdBanner } from "components/Others/AdBanner";
import { fetchReportFn } from "config/reportApi";
import { useTranslation } from "react-i18next";

function GenerateReport() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { nextStep, surveyData, setReportData } = useReportStore();
  const toast = useToast();
  const { t } = useTranslation();
  const [isSurveyDataFetched, setIsSurveyDataFetched] = useState(false);

  useEffect(() => {
    setIsSurveyDataFetched(
      surveyData ? !!Object.keys(surveyData).length : false
    );
  }, [surveyData]);

  const fetchData = async () => {
    const data = await fetchReportFn(surveyData.id);
    setReportData(data);
  };

  const handleGenerateReportClick = () => {
    if (isSurveyDataFetched) {
      fetchData();
      nextStep();
    } else {
      toastFunction("error", t("generateReport.error"));
    }
  };

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Box
        pos="absolute"
        display="flex"
        align="center"
        flexDirection={["column", "row"]}
        top="0"
        right={["1", "75"]}
        marginTop={["5px", "20px"]}
        marginRight={["55px", "80px"]}
        gap={["2", "10"]}
      ></Box>
      <Stack align="center" spacing="5">
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
        />
        <Text fontSize="6xl" fontWeight="bold">
          {t("generateReport.title")}
        </Text>
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text w={["90vw", "40vw"]}>{t("generateReport.description")}</Text>
        </Stack>
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={handleGenerateReportClick}
        >
          {t("generateReport.generateButton")}
        </Button>
        <Box h="20px"></Box>
        <AdBanner />
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={t("modal.icon")}
        modalTitle={t("modal.title")}
        modalDescription={t("modal.description")}
      />
    </Center>
  );
}

export default GenerateReport;
