import axios from "axios";
import Cookies from "js-cookie";
import { CONFIG } from "config";

const surveyApi = axios.create({
  baseURL: CONFIG.baseURL,
  withCredentials: true,
});

const endpoint = "survey";

export const submitSurveyFn = async (allModuleAnswers) => {
  let allUserAnswers = [];
  for (let moduleID in allModuleAnswers) {
    const moduleAnswers = allModuleAnswers[moduleID].map((answer) => {
      return { ...answer, questionId: answer.id };
    });
    allUserAnswers = allUserAnswers.concat(moduleAnswers);
  }
  try {
    const token = Cookies.get("token");
    const response = await surveyApi.post(
      endpoint,
      { userAnswers: allUserAnswers },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error: Survey submit failed for current Questionnaire:",
      error
    );
  }
};
