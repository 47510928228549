// Contact Section
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  chakra,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import { Link } from "react-router-dom";
import BackgroundPrimary from "assets/illustrations/tree-one-primary.svg";
import BackgroundSecondary from "assets/illustrations/tree-one-secondary.svg";
import {
  EmailIcon,
  PlusSquareIcon,
  QuestionIcon,
  Search2Icon,
} from "@chakra-ui/icons";

function Contact() {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const handleButtonClick = (action) => {
    switch (action) {
      case "investor":
        window.location.href = "mailto:investor-relations@idoona.eu";
        break;
      case "product":
        window.location.href = "mailto:sales@idoona.eu";
        break;
      case "other":
        window.location.href = "mailto:hello@idoona.eu";
        break;
      case "linkedIn":
        window.open(
          "https://www.linkedin.com/company/idoona/",
          "_blank",
          "noreferrer"
        );
        break;
      case "signUp":
        window.open(
          "https://idoona.us14.list-manage.com/subscribe?u=aabfd80d34cb83e83d15e0a8f&id=6ddb54356c",
          "_blank",
          "noreferrer"
        );
        break;
      default:
        window.open(
          "https://idoona.us14.list-manage.com/subscribe?u=aabfd80d34cb83e83d15e0a8f&id=6ddb54356c",
          "_blank",
          "noreferrer"
        );
        break;
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Center
        display="flex"
        flexDirection="column"
        justifyContent="center"
        w="100vw"
        h="100vh"
        gap={10}
        bgImage={`url(${
          colorMode === "light" ? BackgroundPrimary : BackgroundSecondary
        })`}
        backgroundRepeat="no-repeat"
        backgroundPosition="left bottom"
        backgroundSize={["25%", "30%"]}
      >
        <Stack
          display="flex"
          alignItems="center"
          justifyItems="center"
          spacing={5}
        >
          <Image
            objectFit="cover"
            src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
            alt="IDOONA Logo"
          />
          <Stack
            spacing="2"
            color={colorMode === "light" ? "black" : "gray.200"}
            display="flex"
            alignItems="center"
            justifyItems="center"
          >
            <Text
              fontSize="2xl"
              w={["90vw", "60vw", "60vw", "50vw"]}
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              {t("contact.getInTouch")}
            </Text>
            <Text
              fontSize={["l", "xl"]}
              w={["90vw", "60vw", "60vw", "50vw"]}
              textAlign={["left", "center"]}
            >
              {t("contact.description")}
              <chakra.span fontWeight="bold">
                {t("contact.investorRelations")}
              </chakra.span>{" "}
              {t("contact.and")}
              <chakra.span fontWeight="bold">
                {t("contact.corporatePartners")}
              </chakra.span>{" "}
              {t("contact.toBring")}
              <chakra.span fontWeight="bold">
                {t("contact.idoona")}
              </chakra.span>{" "}
              {t("contact.nextLevel")}
              <chakra.span fontWeight="bold">
                {t("contact.detailedInvestmentInfo")}
              </chakra.span>{" "}
              {t("contact.pitchDeck")}
            </Text>
            <Stack
              gap="4"
              display="flex"
              flexDirection={["column", "row"]}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                leftIcon={<PlusSquareIcon />}
                variant={
                  colorMode === "light"
                    ? "primarySlimPointer"
                    : "secondarySlimPointer"
                }
                aria-label="Sign up for email list"
                onClick={() => handleButtonClick("investor")}
                w={["280px", "230px"]}
                iconSpacing={3}
                mt="10px"
              >
                {t("contact.investorRelationsButton")}
              </Button>
              <Button
                leftIcon={<Search2Icon />}
                variant={
                  colorMode === "light"
                    ? "primarySlimPointer"
                    : "secondarySlimPointer"
                }
                aria-label="Sign up for email list"
                onClick={() => handleButtonClick("product")}
                w={["280px", "230px"]}
                iconSpacing={3}
              >
                {t("contact.productInquiriesButton")}
              </Button>
              <Button
                leftIcon={<QuestionIcon />}
                variant={
                  colorMode === "light"
                    ? "primarySlimPointer"
                    : "secondarySlimPointer"
                }
                aria-label="Sign up for email list"
                onClick={() => handleButtonClick("other")}
                w={["280px", "230px"]}
                iconSpacing={3}
              >
                {t("contact.otherRequestsButton")}
              </Button>
              <Button
                leftIcon="in"
                variant={
                  colorMode === "light"
                    ? "primarySlimPointer"
                    : "secondarySlimPointer"
                }
                aria-label="Sign up for email list"
                onClick={() => handleButtonClick("linkedIn")}
                w={["280px", "230px"]}
                iconSpacing={3}
              >
                {t("contact.visitLinkedIn")}
              </Button>
            </Stack>
            <Box h={["20px", "30px"]}></Box>
            <Text
              fontSize="2xl"
              w={["90vw", "60vw", "60vw", "50vw"]}
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              {t("contact.stayInTouch")}
            </Text>
            <Text
              fontSize={["l", "xl"]}
              w={["90vw", "60vw", "60vw", "50vw"]}
              textAlign={["left", "center"]}
            >
              {t("contact.stayInTouchDescription")}
              <chakra.span fontWeight="bold">
                {t("contact.companyNews")}
              </chakra.span>{" "}
              {t("contact.and")}
              <chakra.span fontWeight="bold">
                {t("contact.investorUpdates")}
              </chakra.span>{" "}
              {t("contact.noSpam")}
            </Text>
          </Stack>
          <Button
            leftIcon={<EmailIcon />}
            iconSpacing={3}
            w={["280px", "230px"]}
            variant={
              colorMode === "light"
                ? "primarySlimPointer"
                : "secondarySlimPointer"
            }
            aria-label="Sign up for email list"
            onClick={() => handleButtonClick("signUp")}
          >
            {t("contact.signUpNow")}
          </Button>
          <Link left="50%" transform="translateX(-50%)" to="/imprint">
            <chakra.span
              color="#CA8D7B"
              fontWeight="bold"
              display={["block", "none"]}
            >
              {t("contact.imprint")}
            </chakra.span>
          </Link>
        </Stack>
        <Link left="50%" transform="translateX(-50%)" to="/imprint">
          <chakra.span
            color="#CA8D7B"
            fontWeight="bold"
            display={["none", "block"]}
          >
            {t("contact.imprint")}
          </chakra.span>
        </Link>
      </Center>
      <Text
        pos="relative"
        fontSize="xl"
        textAlign="center"
        bottom={10}
        left="50%"
        transform="translateX(-50%)"
        w={["90vw", "60vw", "60vw", "50vw"]}
        color={colorMode === "light" ? "primary" : "secondary"}
      >
        {t("contact.copyright")}
      </Text>
      <Text
        pos="relative"
        textAlign="center"
        bottom={5}
        left="50%"
        transform="translateX(-50%)"
        w={["90vw", "60vw", "60vw", "50vw"]}
        color={colorMode === "light" ? "black" : "gray.200"}
      >
        {t("contact.builtWith")}{" "}
        <chakra.a
          href="https://chakra-ui.com/"
          target="_blank"
          rel="noopener noreferrer"
          color={colorMode === "light" ? "primary" : "secondary"}
        >
          CHAKRA UI
        </chakra.a>
      </Text>
    </Box>
  );
}

export default Contact;
