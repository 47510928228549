import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useColorMode,
  ButtonGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

export const CustomModal = ({
  onClose,
  isOpen,
  icon,
  modalTitle,
  modalDescription,
  modalDescription2,
  onSubmit = false,
  goBack = null,
  save = null,
  isLoading,
}) => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay backdropFilter="blur(10px)" zIndex="6" />
        <ModalContent
          flexDir="column"
          padding="5"
          justifyContent="center"
          alignItems="center"
          borderRadius="20"
          bg={colorMode === "light" ? "gray.100" : "gray.900"}
          boxShadow={
            "0px 0px 50px " +
            (colorMode === "light"
              ? "rgba(0, 0, 0, 0.2)"
              : "rgba(255, 255, 255, 0.2)")
          }
          zIndex="7"
        >
          <Icon width="50" icon={icon} />
          <ModalHeader fontSize="3xl">{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={3}>{modalDescription}</Text>
            <Text>{modalDescription2}</Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              {typeof save === "function" && (
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  onClick={() => save()}
                  width="120px"
                >
                  {t("save")}
                </Button>
              )}
              {typeof goBack === "function" && (
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryRedOutline"
                      : "secondaryRedOutline"
                  }
                  onClick={() => goBack()}
                  width="120px"
                >
                  {t("goBack")}
                </Button>
              )}
              {onSubmit && (
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  isLoading={isLoading}
                  width="120px"
                  type="submit"
                >
                  {t("submit")}
                </Button>
              )}
              <Button
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
                onClick={onClose}
                width="120px"
              >
                {t("close")}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
