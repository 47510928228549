import create from "zustand";
import Cookies from "js-cookie";

export const useAuthStore = create((set) => ({
  isAuthenticated: !!Cookies.get("token"),
  user: localStorage.getItem("user") || {},
  setIsAuthenticated: (authValue, userData = null) => {
    if (authValue) {
      Cookies.set("token", userData.token.accessToken, { secure: true });
      set(() => ({ isAuthenticated: authValue, user: userData.user }));
      localStorage.setItem("user", JSON.stringify(userData.user));
    } else {
      Cookies.remove("token");
      set(() => ({ isAuthenticated: false, user: null, token: null }));
    }
  },
}));
