// Our Process Section
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { InfoModule } from "InfoModule";
import { useWizardStore } from "store";

function OurProcess() {
  const { colorMode } = useColorMode();
  const { setStep } = useWizardStore();
  const { t } = useTranslation();

  const handleClick = () => setStep(3);
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  const ourProcessData = [
    {
      title: t("ourProcess.dataEntry.title"),
      image: 1,
      type: "process",
      description: t("ourProcess.dataEntry.description"),
    },
    {
      title: t("ourProcess.marketData.title"),
      image: 2,
      type: "process",
      description: t("ourProcess.marketData.description"),
    },
    {
      title: t("ourProcess.getScore.title"),
      image: 3,
      type: "process",
      description: t("ourProcess.getScore.description"),
    },
    {
      title: t("ourProcess.dataOverview.title"),
      image: 4,
      type: "process",
      description: t("ourProcess.dataOverview.description"),
    },
  ];

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Stack align="center" spacing={[1, 10]}>
        <Text fontSize={["4xl", "6xl"]} fontWeight="bold">
          {t("ourProcess.title")}
        </Text>
        <Box display="flex" flexDirection={["column", "row"]} gap={[1, 8]}>
          {ourProcessData.map((process, i) => (
            <InfoModule
              key={i}
              index={i + 1}
              image={process.image}
              title={process.title}
              description={process.description}
            />
          ))}
        </Box>
        {!isMobile && (
          <Button
            variant={colorMode === "light" ? "primary" : "secondary"}
            onClick={handleClick}
          >
            {t("ourProcess.useCasesButton")}
          </Button>
        )}
      </Stack>
    </Center>
  );
}

export default OurProcess;
