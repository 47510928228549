import {
  Text,
  useColorMode,
  Image,
  HStack,
  Box,
  Button,
  chakra,
} from "@chakra-ui/react";
import TagPrimary from "assets/icons/tag-primary.svg";
import TagSecondary from "assets/icons/tag-secondary.svg";
import { useTranslation } from "react-i18next";

export const AdBanner = () => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleClick = () => {
    console.log("Ad Clicked");
  };

  return (
    <>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
        cursor="pointer"
        padding="15px"
        border="2px solid"
        borderRadius="15px"
        borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
        boxShadow={
          colorMode === "light" ? "0 0 0 2px #2A5050" : "0 0 0 2px #6BA6C7"
        }
        onClick={() => openInNewTab("https://idoona.eu")}
      >
        <Image
          maxWidth="10"
          h="10"
          src={colorMode === "light" ? TagPrimary : TagSecondary}
          marginTop={["20px", "0px"]}
          marginLeft={"20px"}
        />
        <Box w={["90vw", "40vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            lineHeight="40px"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("adBanner.title")}
          </Text>
          <Text lineHeight="20px">{t("adBanner.description")}</Text>
          <Button
            marginTop="2"
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            onClick={() => handleClick(3)}
          >
            {t("adBanner.button")}
          </Button>
        </Box>
      </HStack>
    </>
  );
};
