import {
  NumberInput,
  NumberInputField,
  Box,
  HStack,
  Text,
  useColorMode,
  Stack,
  Select,
} from "@chakra-ui/react";
import currenciesData from "../../constants/currencies.json";
import { useTranslation } from "react-i18next";

export const RevenueDataInput = ({
  flag,
  label,
  setValue,
  onChange,
  max,
  min,
  isDisabled,
  onFocus,
  value,
  onSelect,
  selectValue,
}) => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  let countryFlag = "react-tel-input flag " + flag.toLowerCase();

  const currencySelector = () => {
    return (
      <Select
        placeholder={t("currency")}
        variant="flushed"
        onChange={onSelect}
        value={selectValue}
      >
        {currenciesData?.map((currency, i) => (
          <option key={i} value={currency.code}>
            {currency.code}
          </option>
        ))}
      </Select>
    );
  };

  return (
    <Stack>
      <HStack w={["90vw", "240px"]}>
        <Text
          textAlign={["center", "left"]}
          fontWeight="bold"
          color={colorMode === "light" ? "gray.900" : "gray.100"}
        >
          {t("revenueIn")} {label}
        </Text>
        <Box className={countryFlag}></Box>
      </HStack>
      {isDisabled && (
        <HStack
          display="flex"
          flexDirection={["column", "row"]}
          align="center"
          spacing={["0", "6"]}
        >
          <NumberInput
            variant="flushed"
            setvalue={setValue}
            max={max}
            min={min}
            onFocus={onFocus}
            value={value}
            onChange={onChange}
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            <NumberInputField w={["90vw", "270px"]} />
          </NumberInput>
          {currencySelector()}
        </HStack>
      )}
    </Stack>
  );
};
