// Getting Started page index
import Credentials from "./Credentials";
import BasicInformation from "./BasicInformation";
import Jurisdictions from "./Jurisdictions";
import EmployeeData from "./EmployeeData";
import { Box, useToast } from "@chakra-ui/react";
import RevenueData from "./RevenueData";
import ReportingScope from "./ReportingScope";
import ConfirmChoices from "./ConfirmChoices";
import { useSignUpStore } from "store";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { signUpUserFn } from "config/authApi";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const steps = useSignUpStore((state) => state.steps);
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const resetSignupSteps = useSignUpStore((state) => state.resetSteps);
  const resetSignupForm = useSignUpStore((state) => state.resetForm);

  // Entire Signup Wizard from Store
  const { user: formState } = useSignUpStore();

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  const { mutate: signupUser } = useMutation(
    (signupData) => signUpUserFn(signupData),
    {
      onSuccess: () => {
        toastFunction("success", t("signup.accountCreated"));
        setTimeout(() => {
          navigate("/login");
        }, 1000);
        resetSignupSteps();
        resetSignupForm();
      },
      onError: (error) => {
        toastFunction(
          "error",
          t("signup.signupError") + error.response.data.message
        );
      },
    }
  );

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm();

  // useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     reset();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSubmitSuccessful]);

  const onSubmitHandler = () => {
    signupUser(formState);
    // add signup api here from /config/authApi.js

    // Send Signup Data to API
    // signupUser(formState);

    // On Backend => Transform the Data according to MongoDB Structure
    // Then save and return a 200 response once user is signed up

    console.log(JSON.stringify(formState, null, 2));
  };

  let currentPage;
  switch (steps) {
    case 0:
      currentPage = <Credentials />;
      break;
    case 1:
      currentPage = <BasicInformation />;
      break;
    case 2:
      currentPage = <Jurisdictions />;
      break;
    case 3:
      currentPage = <EmployeeData />;
      break;
    case 4:
      currentPage = <RevenueData />;
      break;
    case 5:
      currentPage = <ReportingScope />;
      break;
    case 6:
      currentPage = <ConfirmChoices isLoading={isSubmitting} />;
      break;
    default:
      currentPage = <Credentials />;
      break;
  }

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>{currentPage}</form>
    </Box>
  );
};

export default SignUp;
