// Publish Report Page
import {
  Text,
  Stack,
  Image,
  useColorMode,
  Box,
  HStack,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import IdoonaBadge from "assets/icons/idoona-badge-draft-3-2.svg";
import { useReportStore } from "store";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoDark from "assets/icons/idoona-logo-blue.svg";
import { useTranslation } from "react-i18next";

function PublishReport() {
  const { colorMode } = useColorMode();
  const { prevStep, nextStep } = useReportStore();
  const { t } = useTranslation();

  return (
    <Stack align="center" spacing="3">
      <Box h={18}></Box>
      <Image objectFit="cover" src={IdoonaBadge} alt="Idoona Badge" />
      <Text fontSize="6xl" fontWeight="bold">
        {t("publishReport.title")}
      </Text>
      <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
        <Text w={["90vw", "60vw"]}>{t("publishReport.congratulations")}</Text>
        <Text w={["90vw", "60vw"]}>{t("publishReport.instruction")}</Text>
      </Stack>
      <Image
        objectFit="cover"
        src={colorMode === "light" ? IdoonaLogo : IdoonaLogoDark}
        alt="IDOONA Logo"
        w={10}
      />
      <Text w={["90vw", "60vw"]} fontSize="2xl" fontWeight="bold">
        {t("publishReport.faqTitle")}
      </Text>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={["90vw", "60vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("publishReport.faq.benefits.question")}
          </Text>
          <Text>{t("publishReport.faq.benefits.answer")}</Text>
        </Box>
      </HStack>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={["90vw", "60vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("publishReport.faq.visibility.question")}
          </Text>
          <Text>{t("publishReport.faq.visibility.answer")}</Text>
        </Box>
      </HStack>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={["90vw", "60vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("publishReport.faq.unpublish.question")}
          </Text>
          <Text>{t("publishReport.faq.unpublish.answer")}</Text>
        </Box>
      </HStack>
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={["90vw", "60vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("publishReport.faq.newReport.question")}
          </Text>
          <Text>{t("publishReport.faq.newReport.answer")}</Text>
        </Box>
      </HStack>
      <Button
        variant={colorMode === "light" ? "primaryOutline" : "secondaryOutline"}
        onClick={nextStep}
      >
        {t("publishReport.publishButton")}
      </Button>
      <Box h={10}></Box>
      <IconButton
        icon={<ArrowBackIcon />}
        variant={colorMode === "light" ? "primaryOutline" : "secondaryOutline"}
        pos="absolute"
        top={2}
        left={5}
        onClick={prevStep}
      >
        {t("back")}
      </IconButton>
    </Stack>
  );
}

export default PublishReport;
