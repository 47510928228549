// New Landing Page
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  chakra,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import { useWizardStore } from "store";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "store/authStore";
import BackgroundPrimary from "assets/illustrations/climate-action-primary.svg";
import BackgroundSecondary from "assets/illustrations/climate-action-secondary.svg";

function Welcome() {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { setStep } = useWizardStore();
  const { isAuthenticated } = useAuthStore();

  const handleFindOutMoreClicked = () => setStep(1);

  const handleContinueClicked = () => {
    navigate("/questionnaire");
  };
  const { t } = useTranslation();

  return (
    <Center
      display="flex"
      justifyContent="center"
      w="100vw"
      h="100vh"
      id="welcome-section"
      bgImage={`url(${
        colorMode === "light" ? BackgroundPrimary : BackgroundSecondary
      })`}
      backgroundRepeat="no-repeat"
      backgroundPosition="left bottom"
      backgroundSize={["50%", "30%"]}
    >
      <Stack textAlign="center" display="flex" align="center" spacing={5}>
        <Image
          objectFit="cover"
          width="300px"
          src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
          alt="IDOONA Logo"
        />
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text
            fontSize="3xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("welcome.message1")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("welcome.message2")}{" "}
            <chakra.span fontWeight="bold">
              {t("welcome.corporateLeaders")}
            </chakra.span>{" "}
            {t("welcome.holisticOverview")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("welcome.uses")}{" "}
            <chakra.span fontWeight="bold">
              {t("welcome.gamification")}
            </chakra.span>{" "}
            {t("welcome.encourageData")}{" "}
            <chakra.span fontWeight="bold">
              {t("welcome.optimization")}
            </chakra.span>{" "}
            {t("welcome.implementIdeas")}
          </Text>
        </Stack>
        <Button
          alignSelf={["flex-end", "center"]}
          width={160}
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={
            isAuthenticated ? handleContinueClicked : handleFindOutMoreClicked
          }
        >
          {isAuthenticated ? t("continue") : t("welcome.findOutMore")}
        </Button>
      </Stack>
    </Center>
  );
}

export default Welcome;
