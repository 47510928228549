export const lightScrollBar = {
  "&::-webkit-scrollbar": {
    width: "10px",
    borderRadius: "8px",
    backgroundColor: `rgba(211, 211, 211, 0.40)`,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    backgroundColor: `rgba(211, 211, 211)`,
  },
};
