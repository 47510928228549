// Generating Questions
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  ScaleFade,
  HStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useQuestionnaireStore } from "store";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import LoadingAnimation from "assets/animations/loading-infinity.gif";
import checkMark from "assets/animations/check-mark.gif";
import { useState, useEffect } from "react";
import Bulb from "assets/icons/bulb.svg";
import Alert from "assets/icons/alert.svg";
import { fetchQuestionnaireFn } from "config/questionnaireApi";
import { useTranslation } from "react-i18next";

function Generating() {
  const { t, i18n } = useTranslation();
  const { colorMode } = useColorMode();
  const [questionnaireLoaded, setQuestionnaireLoaded] = useState(false);
  const [continueClicked, setContinueClicked] = useState(false);
  const handleStartClick = useQuestionnaireStore((state) => state.nextStep);
  const { questionBank, setQuestionBank } = useQuestionnaireStore();

  useEffect(() => {
    let timer;
    if (questionBank && questionBank.length) {
      timer = setTimeout(() => {
        setQuestionnaireLoaded(true);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [questionBank]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchQuestionnaireFn(i18n.language);
      setQuestionBank(data);
    };

    fetchData();
  }, [setQuestionBank]);

  const handleContinueClick = () => {
    setContinueClicked(true);
  };

  return (
    <Center h="100vh" w="100vw" align="center">
      <Stack align="center" spacing="5">
        <ScaleFade initialScale="0.8" in>
          <Image
            objectFit="cover"
            src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
            alt={t("idoonaLogoAlt")}
            w="100px"
          />
        </ScaleFade>
        {continueClicked ? (
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "gray.100"}
          >
            {t("generating.questionsReady")}
          </Text>
        ) : (
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "gray.100"}
          >
            {t("generating.generatingQuestions")}
          </Text>
        )}
        {!continueClicked ? (
          <Text
            width={["90vw", "40vw"]}
            color={colorMode === "light" ? "gray.900" : "gray.300"}
          >
            {t("generating.generatingMessage")}
          </Text>
        ) : (
          <Text
            width={["90vw", "40vw"]}
            color={colorMode === "light" ? "gray.900" : "gray.300"}
          >
            {t("generating.thankYouMessage")}
          </Text>
        )}
        {!questionnaireLoaded ? (
          <Image
            h="10"
            top="65vh"
            src={LoadingAnimation}
            alt={t("generating.loadingAlt")}
          />
        ) : !continueClicked ? (
          <HStack>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color={colorMode === "light" ? "primary" : "lightGreen"}
            >
              {t("generating.questionsReceived")}
            </Text>
            <Image
              h="10"
              top="65vh"
              src={checkMark}
              alt={t("generating.doneLoadingAlt")}
            />
          </HStack>
        ) : null}
        {!continueClicked ? (
          <Stack
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap={[null, 3]}
          >
            <HStack gap="10px" width={["90vw", "40vw"]}>
              <Image
                objectFit="cover"
                src={Alert}
                alt={t("generating.timerIconAlt")}
                w="30px"
              />
              <Text
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "black" : "gray.100"}
              >
                {t("generating.doNotCloseMessage")}
              </Text>
            </HStack>
            <Text
              textAlign={["center", "left"]}
              width={["90vw", "35vw"]}
              color={colorMode === "light" ? "black" : "gray.100"}
            >
              {t("generating.preparationMessage")}
            </Text>
            <HStack gap="10px" width={["90vw", "40vw"]}>
              <Image
                objectFit="cover"
                src={Bulb}
                alt={t("generating.alertIconAlt")}
                w="30px"
              />
              <UnorderedList
                paddingLeft={[null, "30px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "black" : "gray.100"}
              >
                <ListItem>{t("generating.infoItem1")}</ListItem>
                <ListItem>{t("generating.infoItem2")}</ListItem>
                <ListItem>{t("generating.infoItem3")}</ListItem>
              </UnorderedList>
            </HStack>
          </Stack>
        ) : (
          <Stack
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap={[null, 3]}
          >
            <Text
              width={["90vw", "50vw"]}
              color={colorMode === "light" ? "gray.900" : "gray.300"}
            >
              {t("generating.nextScreenMessage1")}
            </Text>
            <Text
              width={["90vw", "50vw"]}
              color={colorMode === "light" ? "gray.900" : "gray.300"}
            >
              {t("generating.nextScreenMessage2")}
            </Text>
            <Text
              width={["90vw", "50vw"]}
              color={colorMode === "light" ? "gray.900" : "gray.300"}
            >
              {t("generating.nextScreenMessage3")}
            </Text>
            <Text
              width={["90vw", "50vw"]}
              color={colorMode === "light" ? "gray.900" : "gray.300"}
            >
              {t("generating.helpMessage")}
            </Text>
          </Stack>
        )}
        {continueClicked ? (
          <Button
            margin={["5", "20"]}
            onClick={handleStartClick}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
          >
            {t("start")}
          </Button>
        ) : questionnaireLoaded ? (
          <Button
            margin={["5", "20"]}
            onClick={handleContinueClick}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
          >
            {t("continue")}
          </Button>
        ) : null}
      </Stack>
    </Center>
  );
}

export default Generating;
