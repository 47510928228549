/**
 * @DESC
 * Color Usage: <Box bg="gray.100">Welcome</Box>
 * or <Text color="primary">Hi</Text>
 */

const colors = {
  gray: {
    50: "#F2F2F2",
    100: "#DBDBDB",
    200: "#C4C4C4",
    300: "#ADADAD",
    400: "#969696",
    500: "#808080",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  primary: "#2A5050",
  secondary: "#6BA6C7",
  tertiary: "#F4EFA7",
  white: "#FFFFFF",
  blue: "#6BA6C7",
  orange: "#CA8D7B",
  accent: "#7EBCD8",
  secondaryAccent: "#E5E5E5",
  dark: "#2C2C2C",
  light: "#DADADA",
  current: "#009B51",

  // We can define custom variants through: https://chakra-ui.com/docs/theming/advanced
  // https://chakra-ui.com/docs/theming/customize-theme

  // Example Video: https://www.youtube.com/watch?v=Tx54S3UtRjI
};

export default colors;
