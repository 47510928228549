// New Home page index
import React, { useEffect, useRef, useMemo } from "react";
import Welcome from "./Welcome";
import Contact from "./Contact";
import FAQ from "./FAQ";
import OurProcess from "./OurProcess";
import TheProblem from "./TheProblem";
import UseCases from "./UseCases";
import {
  Box,
  Divider,
  Stack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useWizardStore } from "store";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";

const NewHome = () => {
  const steps = useWizardStore((state) => state.steps);
  const welcomeSectionRef = useRef(null);
  const problemSectionRef = useRef(null);
  const processSectionRef = useRef(null);
  const useCasesSectionRef = useRef(null);
  const faqSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const { setStep } = useWizardStore();
  const { colorMode } = useColorMode();

  const sectionRefs = useMemo(
    () => ({
      "welcome-section": welcomeSectionRef,
      "the-problem-section": problemSectionRef,
      "our-process-section": processSectionRef,
      "use-cases-section": useCasesSectionRef,
      "faq-section": faqSectionRef,
      "contact-section": contactSectionRef,
    }),
    []
  );

  useEffect(() => {
    const scrollToSection = (id) => {
      const element = sectionRefs[id]?.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    switch (steps) {
      case 0:
        scrollToSection("welcome-section");
        break;
      case 1:
        scrollToSection("the-problem-section");
        break;
      case 2:
        scrollToSection("our-process-section");
        break;
      case 3:
        scrollToSection("use-cases-section");
        break;
      case 4:
        scrollToSection("faq-section");
        break;
      case 5:
        scrollToSection("contact-section");
        break;
      default:
        setStep(0);
    }
  }, [steps, sectionRefs, setStep]);

  useEffect(() => {
    setStep(0);
  }, []);

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "flex-start"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
      overflowX="hidden"
      sx={
        colorMode === "light"
          ? !isMobile && darkScrollBar
          : !isMobile && lightScrollBar
      }
    >
      <Stack>
        <div ref={welcomeSectionRef}>
          <Welcome />
        </div>
        <Divider size="50" />
        <div ref={problemSectionRef}>
          <TheProblem />
        </div>
        <Divider size="50" />
        <div ref={processSectionRef}>
          <OurProcess />
        </div>
        <Divider size="50" />
        <div ref={useCasesSectionRef}>
          <UseCases />
        </div>
        <Divider size="50" />
        <div ref={faqSectionRef}>
          <FAQ />
        </div>
        <Divider size="50" />
        <div ref={contactSectionRef}>
          <Contact />
        </div>
      </Stack>
    </Box>
  );
};

export default NewHome;
