// Reset password Page
import React from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Text,
  Link,
  Image,
  FormControl,
  Divider,
  HStack,
  useColorMode,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import ForgotPasswordPrimary from "assets/illustrations/forgot-password-primary.svg";
import ForgotPasswordBlue from "assets/illustrations/forgot-password-blue.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CFaUserAlt = chakra(FaUserAlt);

const ResetPassword = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
          mb="60px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={
              colorMode === "light" ? ForgotPasswordPrimary : ForgotPasswordBlue
            }
            alt="Forgot Password Illustration"
            h="280px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <form marginleft={[null, "100px"]}>
              <Stack
                spacing={5}
                p="1.5rem"
                backgroundColor={
                  colorMode === "light" ? "whiteAlpha.900" : "gray.800"
                }
              >
                <Heading
                  size="2xl"
                  textAlign={["center", "left"]}
                  color={colorMode === "light" ? "primary" : "gray.100"}
                >
                  {t("resetPassword.title")}
                </Heading>
                <Text
                  width={["90vw", "30vw"]}
                  textAlign={["center", "left"]}
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("resetPassword.description")}
                </Text>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    />
                    <Input
                      type="email"
                      placeholder={t("resetPassword.emailPlaceholder")}
                    />
                  </InputGroup>
                </FormControl>
                <Button
                  type="submit"
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="250px"
                >
                  {t("resetPassword.resetButton")}
                </Button>
                <Divider size="50" />
                <Box textAlign="left">
                  <Link
                    color={colorMode === "light" ? "primary" : "secondary"}
                    onClick={handleClick}
                  >
                    {t("resetPassword.backToLogin")}
                  </Link>
                </Box>
              </Stack>
            </form>
          </Box>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default ResetPassword;
