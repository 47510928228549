import { Box, Textarea, useColorMode } from "@chakra-ui/react";

export const OtherText = ({ setAnswer, placeholder = "label", value }) => {
  let inputField;
  const { colorMode } = useColorMode();
  inputField = (
    <Textarea
      onChange={setAnswer}
      placeholder={placeholder}
      color={colorMode === "light" ? "primary" : "secondary"}
      width={["80vw", "40vw"]}
      height="5vh"
      resize="none"
      value={value}
    />
  );

  return <Box>{inputField}</Box>;
};
