// Report index
import { Box } from "@chakra-ui/react";
import { useReportStore } from "store";
import GenerateReport from "./GenerateReport";
import GeneratingReport from "./GeneratingReport";
import YourReport from "./YourReport";
import PublishReport from "./PublishReport";
import PublicOverview from "./PublicOverview";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const steps = useReportStore((state) => state.steps);
  const { surveyData } = useReportStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (surveyData && Object.keys(surveyData).length === 0) {
      navigate("/questionnaire");
    }
  }, []);

  let currentPage;
  switch (steps) {
    case 0:
      currentPage = <GenerateReport />;
      break;
    case 1:
      currentPage = <GeneratingReport />;
      break;
    case 2:
      currentPage = <YourReport />;
      break;
    case 3:
      currentPage = <PublishReport />;
      break;
    case 4:
      currentPage = <PublicOverview />;
      break;
    default:
      currentPage = <GenerateReport />;
      break;
  }

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
      overflowX="hidden"
    >
      {currentPage}
    </Box>
  );
};

export default Report;
