// Revenue Data
import { useState, useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Image,
  chakra,
  HStack,
  NumberInput,
  NumberInputField,
  useColorMode,
  ButtonGroup,
  Fade,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import "assets/css/flags.css";
import { useSignUpStore } from "store";
import { RevenueDataInput } from "components/Others";
import RevenueDataPrimary from "assets/illustrations/revenue-data-primary.svg";
import RevenueDataSecondary from "assets/illustrations/revenue-data-secondary.svg";
import currenciesData from "../../../constants/currencies.json";
import { CustomModal } from "components/Others";
import { useTranslation } from "react-i18next";

const RevenueData = () => {
  const { addRevenues, user, nextStep, prevStep, setStep, isRedirected } =
    useSignUpStore();
  const [revenueCountries, setRevenueCountries] = useState({
    total: {
      amount: "",
      currency: "Currency",
    },
    dateRange: { startDate: "", endDate: "" },
    currencies: {},
  });
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const modalTitle = t("revenueData.modalTitle");
  const modalDescription = t("revenueData.modalDescription");
  const icon = "mdi-light:currency-eur";

  useEffect(() => {
    if (Object.values(user.revenues).length > 0) {
      setRevenueCountries(user.revenues);
    }
  }, []);

  const handleDateChange = (e) => {
    setRevenueCountries((prevState) => ({
      ...prevState,
      dateRange: {
        ...prevState.dateRange,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const changeHandler = (e, country) => {
    setRevenueCountries(() => ({
      ...revenueCountries,
      [country]: e,
    }));
    addRevenues(revenueCountries);
  };

  const selectHandler = (e, country) => {
    setRevenueCountries(() => ({
      ...revenueCountries,
      currencies: {
        ...revenueCountries.currencies,
        [country]: e.target.value,
      },
    }));
    addRevenues(revenueCountries);
  };

  // To Ask

  const totalChangeHandler = (e) => {
    // TODO: Format Input number field to display large monetary numbers with commas
    // Hint: log e.length -> length of input by user on every keypress
    setRevenueCountries(() => ({
      ...revenueCountries,
      total: {
        ...revenueCountries.total,
        amount: e,
      },
    }));
    addRevenues(revenueCountries);
  };

  const totalSelectHandler = (e, country) => {
    setRevenueCountries(() => ({
      ...revenueCountries,
      total: {
        ...revenueCountries.total,
        currency: e,
      },
    }));
    addRevenues(revenueCountries);
  };

  const handleBackClick = () => {
    addRevenues(revenueCountries);
    prevStep();
  };

  const handleNextClick = () => {
    addRevenues(revenueCountries);

    // Redirection Logic from Confirmation
    isRedirected ? setStep(6) : nextStep();
  };

  const currencySelector = () => {
    return (
      <Select
        placeholder={t("currency")}
        variant="flushed"
        value={revenueCountries.total.currency}
        onChange={(e) => totalSelectHandler(e.target.value)}
      >
        {currenciesData?.map((currency, i) => (
          <option key={i} value={currency.code}>
            {currency.code}
          </option>
        ))}
      </Select>
    );
  };

  return (
    <Flex
      flexDirection="column"
      width={["90vw", "100vw"]}
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={
              colorMode === "light" ? RevenueDataPrimary : RevenueDataSecondary
            }
            alt="Revenue Chart Illustration"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={3}
              p="1.5rem"
              width={["90vw", "500px"]}
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("revenueData.title")}
              </Heading>
              <Text
                width={["85vw", "430px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("revenueData.description")}
              </Text>
              <Box display="flex" w={["85vw", "100%"]}>
                <chakra.span
                  onClick={onOpen}
                  cursor="pointer"
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("whatDoesThisMean")}
                </chakra.span>
              </Box>
              <Text
                textAlign={["center", "left"]}
                fontWeight="bold"
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("revenueData.reportingPeriod")}
              </Text>
              <HStack>
                <input
                  className="text-sky-500 dark:text-sky-400"
                  type="date"
                  id="start-date"
                  name="startDate"
                  value={revenueCountries?.dateRange?.startDate}
                  min="2000-01-01"
                  onChange={(e) => handleDateChange(e)}
                ></input>
                <Text
                  textAlign={["center", "left"]}
                  color={colorMode === "light" ? "gray.900" : "gray.100"}
                >
                  {t("general.to")}
                </Text>
                <input
                  type="date"
                  id="end-date"
                  name="endDate"
                  value={revenueCountries?.dateRange?.endDate}
                  min="2000-01-01"
                  onChange={(e) => handleDateChange(e)}
                ></input>
              </HStack>
              <Text
                textAlign={["center", "left"]}
                fontWeight="bold"
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("revenueData.globalRevenue")}
              </Text>
              <HStack
                display="flex"
                flexDirection={["column", "row"]}
                align="center"
                spacing={["0", "6"]}
              >
                <NumberInput
                  min={0}
                  value={revenueCountries.total.amount}
                  color={colorMode === "light" ? "primary" : "secondary"}
                  variant="flushed"
                >
                  <NumberInputField
                    w={["90vw", "270px"]}
                    onChange={(e) => totalChangeHandler(e.target.value)}
                    placeholder={t("revenueData.totalRevenuePlaceholder")}
                    paddingRight={0}
                  />
                </NumberInput>
                {currencySelector()}
              </HStack>
              {Object.keys(user.jurisdictions).map((country, i) => (
                <Fade in key={i}>
                  <RevenueDataInput
                    flag={country}
                    value={revenueCountries[country]}
                    label={user.jurisdictions[country]}
                    onChange={(e) => changeHandler(e, country)}
                    setValue={revenueCountries}
                    isDisabled={revenueCountries.total.amount}
                    onSelect={(e) => selectHandler(e, country)}
                    selectValue={revenueCountries.currencies[country]}
                    max={revenueCountries.total}
                    min={0}
                  />
                </Fade>
              ))}
              {/* {totalValidation} */}
              <ButtonGroup>
                {!isRedirected ? (
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                ) : null}
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleNextClick}
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </HStack>
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
    </Flex>
  );
};

export default RevenueData;
