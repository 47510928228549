export const RadioStyles = {
  // style object for base or default style
  baseStyles: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: () => ({
      control: {
        border: "1px",
        borderColor: "primary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "primary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "black",
      },
    }),
    secondary: () => ({
      control: {
        border: "1px",
        borderColor: "secondary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "secondary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "gray.900",
      },
    }),
    primaryOutline: () => ({
      container: {
        padding: "10px",
        border: "2px solid",
        borderRadius: "15px",
        borderColor: "gray.300",
      },
      control: {
        border: "1px",
        borderColor: "primary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "primary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "black",
      },
    }),
    secondaryOutline: () => ({
      container: {
        padding: "10px",
        border: "2px solid",
        borderRadius: "15px",
        borderColor: "gray.600",
      },
      control: {
        border: "1px",
        borderColor: "secondary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "secondary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "gray.100",
      },
    }),
    primaryOutlineLong: () => ({
      container: {
        padding: "10px",
        border: "2px solid",
        borderRadius: "15px",
        borderColor: "gray.300",
        width: "55vw",
      },
      control: {
        border: "1px",
        borderColor: "primary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "primary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "black",
      },
    }),
    secondaryOutlineLong: () => ({
      container: {
        padding: "10px",
        border: "2px solid",
        borderRadius: "15px",
        borderColor: "gray.600",
        width: "55vw",
      },
      control: {
        border: "1px",
        borderColor: "secondary",
        borderRadius: "50px",
        "input:checked + &": {
          "&::after": {
            backgroundColor: "secondary",
            borderRadius: "50%",
            content: "''",
            display: "inline-block",
            height: "10px",
            position: "absolute",
            width: "10px",
          },
        },
      },
      label: {
        fontWeight: "medium",
        color: "gray.100",
      },
    }),
    orange: () => ({
      control: {
        "&::after": {
          backgroundColor: "orange",
          borderRadius: "50%",
          content: "''",
          display: "inline-block",
          height: "10px",
          position: "relative",
          width: "10px",
        },
        border: "1px",
        borderColor: "orange",
        borderRadius: "50px",
      },
      label: {
        fontWeight: "medium",
        color: "gray.900",
      },
    }),
    // Declare more variants for checkboxes here "secondary: " etc..
  },
  // default values for size and variants
  defaultProps: {},
};
