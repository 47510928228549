// Questionnaire index
import Generating from "./Generating";
import Questions from "./Questions";
import { Box, useToast } from "@chakra-ui/react";
import { useQuestionnaireStore, useReportStore } from "store";
import SingleQuestion from "./SingleQuestion";
import ChooseModules from "./ChooseModules";
import { submitSurveyFn } from "config/surveyApi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Questionnaire = () => {
  const { t } = useTranslation();
  const steps = useQuestionnaireStore((state) => state.steps);
  const toast = useToast();
  const navigate = useNavigate();
  const { surveyData, setSurveyData } = useReportStore();

  const { allModuleAnswers: formState } = useQuestionnaireStore();

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  useEffect(() => {
    if (surveyData && Object.keys(surveyData).length) {
      navigate("/report");
    }
  }, []);

  const { mutate: submitSurvey } = useMutation(
    (submitSurveyData) => submitSurveyFn(submitSurveyData),
    {
      onSuccess: (submitSurveyData) => {
        setSurveyData(submitSurveyData);
        toastFunction("success", t("submissionSuccess"));
        setTimeout(() => {
          navigate("/report");
        }, 1000);
      },
      onError: () => {
        toastFunction("error", t("submissionError"));
      },
    }
  );

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm();

  const onSubmitHandler = () => {
    submitSurvey(formState);
    console.log(JSON.stringify(formState, null, 2));
  };

  let currentPage;

  switch (steps) {
    case 0:
      currentPage = <Generating />;
      break;
    case 1:
      currentPage = <ChooseModules isLoading={isSubmitting} />;
      break;
    case 2:
      currentPage = <Questions />;
      break;
    case 3:
      currentPage = <SingleQuestion />;
      break;
    default:
      currentPage = <Generating />;
      break;
  }

  return (
    <Box
      display="flex"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>{currentPage}</form>
    </Box>
  );
};

export default Questionnaire;
