// Pitch Deck Page
import React, { useEffect, useState } from "react";
import {
  Text,
  Stack,
  Image,
  useColorMode,
  Button,
  useToast,
  ButtonGroup,
  chakra,
  Center,
  Box,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import BackgroundPrimary from "assets/illustrations/thank-you-primary.svg";
import BackgroundSecondary from "assets/illustrations/thank-you-secondary.svg";
import { fetchPitchPdfFn } from "config/pdf";
import Loader from "components/Others/Loader";
import { useTranslation } from "react-i18next";

const PitchDeck = () => {
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000); // Removing <Loader /> spinner after 10 seconds

    handleDownloadPdf();

    return () => clearTimeout(timer);
  }, []);

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleSignUpClicked = () => {
    window.open(
      "https://idoona.us14.list-manage.com/subscribe?u=aabfd80d34cb83e83d15e0a8f&id=6ddb54356c",
      "_blank",
      "noreferrer"
    );
  };

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  const handleDownloadPdf = async () => {
    setIsLoading(true);
    try {
      const response = await fetchPitchPdfFn();
      if (response && response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `PitchDeck-IDOONA-2024.pdf`;
        link.click();
        setIsLoading(false);
        toastFunction("success", t("pitchDeck.downloadStarted"));
      }
    } catch (error) {
      setIsLoading(false);
      toastFunction("error", t("pitchDeck.downloadError"));
    }
  };

  return (
    <Center
      display="flex"
      justifyContent="center"
      w="100vw"
      h="100vh"
      id="welcome-section"
      bgImage={`url(${
        colorMode === "light" ? BackgroundPrimary : BackgroundSecondary
      })`}
      backgroundRepeat="no-repeat"
      backgroundPosition="left bottom"
      backgroundSize={["40%", "20%"]}
    >
      {isLoading && <Loader />}
      <Stack textAlign="center" display="flex" align="center" spacing={5}>
        <Image
          objectFit="cover"
          width="300px"
          src={colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue}
          alt="IDOONA Logo"
        />
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text
            fontSize="3xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("pitchDeck.thankYou")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("pitchDeck.downloading")}
          </Text>
          <Text
            fontSize="xl"
            w={["90vw", "60vw", "60vw", "50vw"]}
            textAlign={["left", "center"]}
          >
            {t("pitchDeck.signUpPrompt")}
            <chakra.span fontWeight="bold">
              {t("pitchDeck.companyNews")}
            </chakra.span>{" "}
            {t("pitchDeck.and")}
            <chakra.span fontWeight="bold">
              {t("pitchDeck.launchAnnouncement")}
            </chakra.span>
          </Text>
        </Stack>
        <ButtonGroup flexDirection={["column-reverse", "row"]} gap={4}>
          <Button
            marginLeft={2}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            aria-label="Sign up for email list"
            onClick={handleHomeClick}
          >
            {t("home")}
          </Button>
          <Button
            leftIcon={<EmailIcon />}
            iconSpacing={3}
            variant={
              colorMode === "light"
                ? "primarySlimPointer"
                : "secondarySlimPointer"
            }
            aria-label="Sign up for email list"
            onClick={handleSignUpClicked}
          >
            {t("pitchDeck.signUpButton")}
          </Button>
        </ButtonGroup>
        <Box h="100px"></Box>
        <Text
          color={colorMode === "light" ? "black" : "gray.200"}
          fontSize="l"
          w={["90vw", "50vw"]}
          textAlign={["right", "center"]}
        >
          {t("pitchDeck.retryPrompt")}{" "}
          <chakra.span
            onClick={handleDownloadPdf}
            cursor="pointer"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {t("pitchDeck.retry")}
          </chakra.span>
        </Text>
      </Stack>
    </Center>
  );
};

export default PitchDeck;
