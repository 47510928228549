// Credentials page
import { useState } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  InputGroup,
  Stack,
  Box,
  Text,
  Image,
  FormControl,
  FormHelperText,
  InputRightElement,
  Divider,
  HStack,
  useColorMode,
  ButtonGroup,
  chakra,
} from "@chakra-ui/react";
import { useSignUpStore } from "store";
import { useEffect } from "react";
import SignUpPrimary from "assets/illustrations/sign-up-primary.svg";
import SignUpBlue from "assets/illustrations/sign-up-blue.svg";
import { TextInput } from "components/Others/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Credentials = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  // Destructure the items you defined in your hooks like follows
  const { addCredentials, user, steps, nextStep, setStep, isRedirected } =
    useSignUpStore();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // ToDo: Set up user info storage. Confirm field type. Finalize font/color-theme.

  // Revalidate input if step is changed/triggered
  useEffect(() => {
    if (user.credentials.email) setEmail(user.credentials.email);
    if (user.credentials.password) setPassword(user.credentials.password);
    if (user.credentials.confirmPassword)
      setConfirmPassword(user.credentials.confirmPassword);
  }, [steps, user.credentials]);

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleBackClick = () => {
    navigate("/");
  };

  const handleNextClick = (e) => {
    let tempFields = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    for (let credKey of Object.keys(user.credentials)) {
      addCredentials(credKey, tempFields[credKey]);
    }
    isRedirected ? setStep(6) : nextStep();
  };

  let emailValidation = null;
  if (email !== "") {
    emailValidation = regex.test(email) ? null : (
      <Text width={["90vw", "30vw"]} textAlign={["center", "left"]} color="red">
        {t("credentials.invalidEmail")}
      </Text>
    );
  }

  let confirmPasswordValidation = null;
  if (confirmPassword !== "") {
    confirmPasswordValidation =
      password === confirmPassword ? null : (
        <Text
          width={["90vw", "30vw"]}
          textAlign={["center", "left"]}
          color="red"
        >
          {t("credentials.passwordMismatch")}
        </Text>
      );
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
          mb="60px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={colorMode === "light" ? SignUpPrimary : SignUpBlue}
            alt="IDOONA Logo"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={5}
              p="1.5rem"
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("credentials.title")}
              </Heading>
              <Text
                width={["90vw", "30vw"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("credentials.description")}
              </Text>
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("credentials.emailLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type="email"
                    placeholder={t("credentials.emailLabel")}
                    color={colorMode === "light" ? "primary" : "blue"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              {emailValidation}
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("credentials.passwordLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type={showPassword ? "text" : "password"}
                    placeholder={t("credentials.passwordLabel")}
                    color={colorMode === "light" ? "primary" : "blue"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? t("hide") : t("show")}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("credentials.confirmPasswordLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type={showPassword ? "text" : "password"}
                    placeholder={t("credentials.confirmPasswordLabel")}
                    color={colorMode === "light" ? "primary" : "blue"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? t("hide") : t("show")}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText
                  display="flex"
                  justifyContent="space-between"
                  textAlign="right"
                ></FormHelperText>
              </FormControl>
              {confirmPasswordValidation}
              <ButtonGroup>
                {!isRedirected ? (
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                ) : null}
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  isDisabled={password === confirmPassword ? false : true}
                  onClick={handleNextClick}
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
              <Divider size="50" />
              <Box textAlign="left">
                {t("credentials.loginLinkText")}{" "}
                <Link to="/login">
                  <chakra.span
                    color={colorMode === "light" ? "primary" : "secondary"}
                  >
                    {t("credentials.loginLink")}
                  </chakra.span>
                </Link>
              </Box>
            </Stack>
          </Box>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default Credentials;
