import {
  Box,
  Stack,
  Image,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import LoadingAnimation from "assets/animations/loading-infinity.gif";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Loader = () => {
  const { onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const bg = { light: "rgba(255, 255, 255, 0.6)", dark: "rgba(0, 0, 0, 0.6)" };
  const [loadingText, setLoadingText] = useState(t("loading"));

  useEffect(() => {
    let loadInterval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText.endsWith("...")) {
          return t("loading");
        } else {
          return prevText + ".";
        }
      });
    }, 300);
    return () => clearInterval(loadInterval);
  }, [t]);

  return (
    <Stack onClose={onClose} placement="center" zIndex={6}>
      <Box
        bg={bg[colorMode]}
        w="full"
        h="full"
        position="fixed"
        top={0}
        left={0}
        zIndex={7}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Image src={LoadingAnimation} alt="Loading..." rounded="full" />
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
            textAlign="center"
          >
            {loadingText}
          </Text>
        </Box>
      </Box>
    </Stack>
  );
};

export default Loader;
