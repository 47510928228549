import axios from "axios";
import Cookies from "js-cookie";
import { CONFIG } from "config";

const reportApi = axios.create({
  baseURL: CONFIG.baseURL,
  withCredentials: true,
});

export const fetchReportFn = async (surveyId) => {
  const endpoint = `report/survey/${surveyId}`;
  try {
    const token = Cookies.get("token");
    const response = await reportApi.post(
      endpoint,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching report data:", error);
  }
};
