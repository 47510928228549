import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const FileUpload = ({
  setAnswer,
  label = null,
  placeholder = "placeholder",
}) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="40vw"
      height="150px"
      alignItems="center"
      justifyContent="center"
      gap={5}
      padding="10px"
      border={["none", "2px dotted"]}
      borderRadius="15px"
      borderColor="gray.300"
    >
      <Text fontWeight="bold" display={["none", "inline"]}>
        {label ? label : t("fileUpload.label")}
      </Text>
      <Button variant={colorMode === "light" ? "primary" : "secondary"}>
        {t("fileUpload.selectFiles")}
      </Button>
    </Box>
  );
};
