export const getModuleQuestionList = (payload) => {
  return payload.map((item, i) => {
    const answers = item.answerChoices || [];

    if (item.allowOtherAnswer) {
      const hasOtherOption = answers.some(
        (answer) => answer.answerChoice === "Other"
      );

      if (!hasOtherOption) {
        const otherAnswer = {
          answerChoice: "Other",
          id: generateUniqueId(),
        };

        answers.push(otherAnswer);
      }
    }

    return {
      id: item.id,
      question: item.question,
      variant: "question",
      module: item.moduleId._id,
      label: `Question #${i + 1}`,
      description: item.description,
      answerType: item.answerType,
      answers: answers,
      isAnswered: item.isAnswered,
      regulations: item.regulationId || [],
      questionWeight: item.questionWeight || null,
      allowOtherAnswer: item.allowOtherAnswer,
    };
  });
};

const generateUniqueId = () => {
  const uniqueId = Math.random().toString(36).substring(2, 10);
  return uniqueId;
};

// Current BE Data Structure:
// allowOtherAnswer :true
// answerChoices:[{answerChoice: "Vice City", id: "6491ea422a2c1e70cb82333d"},…]
// answerType:"checkboxes"
// createdAt:"2023-05-01T03:08:32.087Z"
// description:"Default Description"
// isDeleted:false
// isUpdated:false
// label:"reference"
// moduleId:{isDeleted: false,
// _id: "647fcebe464098e0e74518ff",
// name: "Test Module",
// description: "Test",…}
// prevVersion:[]
// question:"DEV TEST 3: Where have you been?"
// updatedAt:
// "2023-06-20T18:25:17.735Z"
// __v:0
// id:"6491eea14eaf3163291f70a0"
// isAnswered:false
