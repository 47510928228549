import axios from "axios";
import { CONFIG } from "config";

export const authApi = axios.create({
  baseURL: CONFIG.baseURL,
  withCredentials: true,
});

authApi.defaults.headers.common["Content-Type"] = "application/json";

export const loginUserFn = async (user) => {
  const response = await authApi.post("auth/login", user);
  // const profileResponse = await authApi.get("auth/user/profile")
  return response.data;
};

export const signUpUserFn = async (user) => {
  const response = await authApi.post("auth/register", user);
  return response.data;
};
