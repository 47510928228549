// Dependent Questions
import {
  Text,
  Box,
  useColorMode,
  RadioGroup,
  Stack,
  Image,
  Divider,
} from "@chakra-ui/react";
import { MultipleChoice } from "components/AnswerTypes/MultipleChoice";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { useState } from "react";
import { SimpleText } from "components/AnswerTypes/SimpleText";
import { FileUrl } from "components/AnswerTypes/FileUrl";
import { FileUpload } from "components/AnswerTypes/FileUpload";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import { useQuestionnaireStore } from "store";
import { useTranslation } from "react-i18next";

function DependentQuestions({ dependentQuestions }) {
  const { colorMode } = useColorMode();
  const { currentDependentAnswers, addCurrentDependentAnswers } =
    useQuestionnaireStore();
  const [allUserAnswers, setAllUserAnswers] = useState(
    currentDependentAnswers ? currentDependentAnswers : []
  );
  const { t } = useTranslation();

  const handleUserAnswer = (questionId, answer, question) => {
    const existingAnswerIndex = allUserAnswers?.findIndex(
      (item) => item.questionId === questionId
    );

    if (existingAnswerIndex !== -1) {
      const updatedAllUserAnswers = [...allUserAnswers];
      updatedAllUserAnswers[existingAnswerIndex] = {
        questionId,
        question,
        answer,
      };
      setAllUserAnswers(updatedAllUserAnswers);
      addCurrentDependentAnswers(updatedAllUserAnswers);
    } else {
      setAllUserAnswers((prevAnswers) => [
        ...prevAnswers,
        {
          questionId,
          question,
          answer,
        },
      ]);
      addCurrentDependentAnswers((prevAnswers) => [
        ...prevAnswers,
        {
          questionId,
          question,
          answer,
        },
      ]);
    }
  };

  let render = null;
  if (dependentQuestions && Array.isArray(dependentQuestions)) {
    render = dependentQuestions?.map((question, i) => {
      let answerDisplay;
      switch (question?.answerType) {
        case "multipleChoice":
        case "checkBoxes":
          answerDisplay = (
            <RadioGroup
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              gap="2"
              w={["90vw", "60vw"]}
              maxHeight="300px"
              overflow="auto"
              key={i}
              sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
              onChange={(value) =>
                handleUserAnswer(question._id, value, question.question)
              }
              value={
                allUserAnswers?.find((item) => item.questionId === question._id)
                  ?.answer || ""
              }
            >
              {question.answers?.map((answer, j) => {
                return (
                  <MultipleChoice
                    key={j}
                    id={answer.id}
                    variant={
                      question.answers[0].answerChoice.length > 3 ? true : false
                    }
                    value={answer.answerChoice}
                    width={
                      Math.max(
                        ...question.answers.map(
                          (answer) => answer.answerChoice.length
                        )
                      ) < 25
                        ? "300px"
                        : "full"
                    }
                  />
                );
              })}
            </RadioGroup>
          );
          break;
        case "simpleText":
          answerDisplay = (
            <SimpleText
              placeholder={t("dependentQuestions.answerPlaceholder")}
              textType="string"
              key={i}
              value={
                allUserAnswers?.find((item) => item.questionId === question._id)
                  ?.answer || ""
              }
              setAnswer={(e) =>
                handleUserAnswer(
                  question._id,
                  e.target.value,
                  question.question
                )
              }
            />
          );
          break;
        case "fileUrl":
          answerDisplay = (
            <>
              <FileUrl
                label={t("dependentQuestions.policyUrlLabel")}
                placeholder={t("dependentQuestions.fileUrlPlaceholder")}
                key={i}
                value={
                  allUserAnswers?.find(
                    (item) => item.questionId === question._id
                  )?.answer || ""
                }
                setAnswer={(e) =>
                  handleUserAnswer(
                    question._id,
                    e.target.value,
                    question.question
                  )
                }
              />
              <FileUpload />
            </>
          );
          break;
        default:
          answerDisplay = "";
          break;
      }
      return (
        <>
          <Stack>
            <Divider
              paddingTop="15px"
              borderColor={colorMode === "light" ? "primary" : "secondary"}
            />
            <Text
              fontWeight="bold"
              fontSize="xl"
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              {`${t("dependentQuestions.dependentQuestionPrefix")}${i + 1} `}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              {question?.question}
            </Text>
            <Text
              w={["90vw", "60vw"]}
              fontSize="lg"
              fontStyle="italic"
              color={colorMode === "light" ? "black" : "gray.200"}
            >
              {question?.description}
            </Text>
          </Stack>
          {answerDisplay}
        </>
      );
    });
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      spacing="2"
      w={["90vw", "60vw"]}
      maxHeight="250px"
      mt={2}
    >
      <Text
        fontSize="xl"
        color={colorMode === "light" ? "black" : "gray.200"}
        mb={2}
      >
        {t("dependentQuestions.instructionText")}
      </Text>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        spacing="2"
      >
        {render}
      </Box>
      <Text color={colorMode === "light" ? "primary" : "secondary"} mt={10}>
        {t("dependentQuestions.ensureCompletionText")}
      </Text>
      <Image
        objectFit="cover"
        src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
        alt="IDOONA Logo"
        maxHeight="20px"
        mt={2}
      />
    </Box>
  );
}

export default DependentQuestions;
