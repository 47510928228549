// Confirm Choices
import { useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import EditIcon from "assets/icons/edit-primary.svg";
import EditIconSecondary from "assets/icons/edit-blue.svg";
import Timer from "assets/icons/timer.svg";
import Alert from "assets/icons/alert.svg";
import {
  Flex,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Image,
  HStack,
  useColorMode,
  Fade,
  ButtonGroup,
  IconButton,
  chakra,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import "assets/css/flags.css";
import { useSignUpStore } from "store";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { useTranslation } from "react-i18next";

const ConfirmChoices = ({ isLoading }) => {
  const { user, prevStep, setStep, setIsRedirected } = useSignUpStore();
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const handleBackClick = (e) => {
    prevStep();
  };

  const handleEditClick = (redirect) => {
    setIsRedirected(true);
    setStep(redirect);
  };

  useEffect(() => {
    setIsRedirected(false);
  }, []);

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const generalInformation = [
    {
      id: 1,
      title: t("confirmChoices.companyName"),
      type: 1,
      value: user.basic.companyName
        ? user.basic.companyName
        : t("confirmChoices.notAdded"),
      redirect: 1,
    },
    {
      id: 2,
      title: t("confirmChoices.userName"),
      type: 1,
      value: user.basic.username
        ? user.basic.username
        : t("confirmChoices.notAdded"),
      redirect: 1,
    },
    {
      id: 3,
      title: t("confirmChoices.contactPersonName"),
      type: 1,
      value: user.basic.name ? user.basic.name : t("confirmChoices.notAdded"),
      redirect: 1,
    },
    {
      id: 4,
      title: t("confirmChoices.hqAddress"),
      type: 1,
      value: user.basic.address
        ? user.basic.address
        : t("confirmChoices.notAdded"),
      redirect: 1,
    },
    {
      id: 5,
      title: t("confirmChoices.jurisdictions"),
      type: user.jurisdictions ? 2 : 1,
      value: user.jurisdictions
        ? user.jurisdictions
        : t("confirmChoices.notAdded"),
      redirect: 2,
    },
  ];

  const empRevDetails = [
    {
      id: 1,
      title: t("confirmChoices.numOfEmployees"),
      type: user.employees ? 3 : 1,
      value: user.employees ? user.employees : t("confirmChoices.notAdded"),
      redirect: 3,
    },
    {
      id: 2,
      title: t("confirmChoices.revenueReportingPeriod"),
      type: user.revenues.dateRange ? 4 : 1,
      value: user.revenues.dateRange
        ? user.revenues.dateRange
        : t("confirmChoices.notAdded"),
      redirect: 4,
    },
    {
      id: 3,
      title: t("confirmChoices.globalRevenue"),
      type: 6,
      value: user.revenues.total
        ? user.revenues.total
        : t("confirmChoices.notAdded"),
      redirect: 4,
    },
    {
      id: 4,
      title: t("confirmChoices.regionalRevenue"),
      type: user.revenues ? 5 : 1,
      value: user.revenues ? user.revenues : t("confirmChoices.notAdded"),
      redirect: 4,
    },
    {
      id: 5,
      title: t("confirmChoices.reportingScope"),
      type: 1,
      value: user.scope
        ? user.scope === "all"
          ? t("reportingScope.all.label")
          : user.scope === "minimum"
          ? t("reportingScope.minimum.label")
          : t("reportingScope.myJurisdictions.label")
        : t("confirmChoices.notAdded"),
      redirect: 5,
    },
  ];

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  const displayFields = (title, value, redirect, type) => {
    let dataDisplay;
    switch (type) {
      case 1:
        dataDisplay = (
          <Text color={colorMode === "light" ? "primary" : "secondary"}>
            {value ? value : null}
          </Text>
        );
        break;
      case 2:
        dataDisplay = Object.keys(value).map((country, i) => (
          <Text color={colorMode === "light" ? "primary" : "secondary"}>
            {value[country]}
          </Text>
        ));
        break;
      case 3:
        dataDisplay = Object.keys(value).map((country, i) => (
          <Text color={colorMode === "light" ? "primary" : "secondary"}>
            {country.charAt(0).toUpperCase() + country.slice(1)}:{" "}
            {formatNumberWithCommas(value[country])}
          </Text>
        ));
        break;
      case 4:
        dataDisplay = Object.keys(value).map((country, i) => (
          <Text color={colorMode === "light" ? "primary" : "secondary"}>
            {country.charAt(0).toUpperCase() +
              country.slice(1).split("D").join(" D")}
            : {value[country]}
          </Text>
        ));
        break;
      case 5:
        dataDisplay = Object.keys(value)
          .filter(
            (key) =>
              key !== "total" && key !== "currencies" && key !== "dateRange"
          )
          .map((country, i) => (
            <Text color={colorMode === "light" ? "primary" : "secondary"}>
              {[country]}: {formatNumberWithCommas(value[country])}{" "}
              {value.currencies[country]}
            </Text>
          ));
        break;
      case 6:
        dataDisplay = (
          <Text color={colorMode === "light" ? "primary" : "secondary"}>
            {value
              ? formatNumberWithCommas(value.amount) + " " + value.currency
              : null}
          </Text>
        );
        break;
      default:
        dataDisplay = null;
        break;
    }
    return (
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        <Box w={["90vw", "50vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "black" : "gray.100"}
          >
            {title}
          </Text>
          {dataDisplay}
        </Box>
        <IconButton
          variant="unstyled"
          icon={
            <Image
              display={["none", "block"]}
              objectFit="cover"
              src={colorMode === "light" ? EditIcon : EditIconSecondary}
              alt="Edit"
              h="15px"
            />
          }
          onClick={(e) => handleEditClick(redirect)}
        />
      </HStack>
    );
  };

  return (
    <Flex
      flexDirection="column"
      width={["90vw", "100vw"]}
      height="100vh"
      alignItems="center"
      sx={
        colorMode === "light"
          ? !isMobile && darkScrollBar
          : !isMobile && lightScrollBar
      }
      overflow="auto"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
          paddingTop="20px"
        />
        <HStack>
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={3}
              p="1.5rem"
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "center"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("confirmChoices.title")}
              </Heading>
              <Text
                width={["90vw", "70vw"]}
                textAlign={["center", "center"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("confirmChoices.description")}
              </Text>
              <Stack
                justify="center"
                flexDirection={["column", "row"]}
                align="flex-start"
              >
                <Stack
                  display="flex"
                  p="5vh"
                  spacing="2"
                  w={["90vw", "500px"]}
                  mt={"10px"}
                >
                  <Text
                    fontSize="2xl"
                    textAlign={["center", "left"]}
                    fontWeight="bold"
                    color={colorMode === "light" ? "black" : "gray.100"}
                    paddingBottom="10px"
                  >
                    {t("confirmChoices.generalInformation")}
                  </Text>
                  {generalInformation.map((field, i) => (
                    <Fade in key={i}>
                      {displayFields(
                        field.title,
                        field.value,
                        field.redirect,
                        field.type
                      )}
                    </Fade>
                  ))}
                </Stack>
                {isMobile && <Divider />}
                <Stack display="flex" p="5vh" spacing="2" w={["90vw", "500px"]}>
                  <Text
                    fontSize="2xl"
                    textAlign={["center", "left"]}
                    fontWeight="bold"
                    color={colorMode === "light" ? "black" : "gray.100"}
                    paddingBottom="10px"
                  >
                    {t("confirmChoices.empRevDetails")}
                  </Text>
                  {empRevDetails.map((field, i) => (
                    <Fade in key={i}>
                      {displayFields(
                        field.title,
                        field.value,
                        field.redirect,
                        field.type
                      )}
                    </Fade>
                  ))}
                </Stack>
              </Stack>
              <Stack
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <HStack gap="10px" width={["90vw", "50vw"]}>
                  <Image
                    objectFit="cover"
                    src={Timer}
                    alt="Timer Icon"
                    w="30px"
                  />
                  <Text
                    textAlign={["center", "left"]}
                    color={colorMode === "light" ? "black" : "gray.100"}
                    fontWeight="bold"
                  >
                    {t("confirmChoices.investmentEstimate")}
                    <chakra.span color="yellow">
                      {t("confirmChoices.investmentTime")}
                    </chakra.span>
                    {t("confirmChoices.investmentEnd")}
                  </Text>
                </HStack>
                <HStack gap="10px" width={["90vw", "50vw"]}>
                  <Image
                    objectFit="cover"
                    src={Alert}
                    alt="Alert Icon"
                    w="30px"
                  />
                  <Text
                    textAlign={["center", "left"]}
                    color={colorMode === "light" ? "black" : "gray.100"}
                    fontWeight="bold"
                  >
                    {t("confirmChoices.alert")}
                    <chakra.span color="red">
                      {t("confirmChoices.alertEmphasis")}
                    </chakra.span>
                    {t("confirmChoices.alertEnd")}
                  </Text>
                </HStack>
              </Stack>
              <ButtonGroup
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleBackClick}
                >
                  {t("back")}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                >
                  {t("confirm")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default ConfirmChoices;
