import { darken, mode, whiten } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  // style object for base or default style
  baseStyles: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props) => ({
      bg: "primary",
      borderRadius: "25",
      p: "6",
      color: "white",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: mode(whiten("primary", 10), darken("primary", 10))(props),
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primarySlim: () => ({
      bg: "primary",
      borderRadius: "25",
      color: "white",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        cursor: "default",
      },
    }),
    primarySlimPointer: (props) => ({
      bg: "primary",
      borderRadius: "25",
      color: "white",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: mode(whiten("primary", 10), darken("primary", 10))(props),
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondary: (props) => ({
      bg: "secondary",
      color: "gray.800",
      borderRadius: "25",
      fontSize: "xl",
      fontWeight: "bold",
      p: "6",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: mode(whiten("secondary", 10), darken("secondary", 10))(props),
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondarySlim: () => ({
      bg: "secondary",
      color: "gray.800",
      borderRadius: "25",
      fontWeight: "bold",
      _hover: {
        cursor: "default",
      },
    }),
    secondarySlimPointer: (props) => ({
      bg: "secondary",
      color: "gray.800",
      borderRadius: "25",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: mode(whiten("secondary", 10), darken("secondary", 10))(props),
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    orange: (props) => ({
      bg: "orange",
      color: "primary",
      borderRadius: "25",
      fontSize: "xl",
      fontWeight: "bold",
      p: "6",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: mode(whiten("orange", 10), darken("orange", 10))(props),
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryOutline: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "primary",
      color: "primary",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "primary",
        color: "white",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryMenu: (props) => ({
      borderRadius: "25",
      p: "3",
      color: "primary",
      fontSize: "2xl",
      fontWeight: "bold",
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryHelp: (props) => ({
      borderRadius: "25",
      color: "primary",
      fontSize: "4xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "primary",
        color: "white",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryHelp: (props) => ({
      borderRadius: "25",
      color: "secondary",
      fontSize: "4xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryRedOutline: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "red.700",
      color: "red.700",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "red.700",
        color: "white",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryRegular: (props) => ({
      borderRadius: "25",
      p: "3",
      color: "primary",
      fontSize: "xl",
      fontWeight: "bold",
      transform: "scale(1.90, 1.90)",
      _hover: {
        transform: "scale(1.00, 1.00)",
        bg: "primary",
        color: "white",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryRegular: (props) => ({
      borderRadius: "25",
      p: "3",
      color: "secondary",
      fontSize: "xl",
      fontWeight: "bold",
      transform: "scale(1.90, 1.90)",
      _hover: {
        transform: "scale(1.00, 1.00)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryOutline: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "secondary",
      color: "secondary",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryMenu: (props) => ({
      borderRadius: "25",
      p: "3",
      color: "secondary",
      fontSize: "2xl",
      fontWeight: "bold",
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryRedOutline: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "red.400",
      color: "red.400",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "red.400",
        color: "white",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    orangeOutline: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "orange",
      color: "orange",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "orange",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    primaryAnswer: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "primary",
      color: "primary",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "primary",
        color: "white",
        boxShadow: "md",
      },
      _focus: {
        transform: "scale(1.02, 1.02)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    secondaryAnswer: (props) => ({
      borderRadius: "25",
      p: "3",
      border: "2px solid",
      borderColor: "secondary",
      color: "secondary",
      fontSize: "xl",
      fontWeight: "bold",
      _hover: {
        transform: "scale(1.02, 1.02)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _focus: {
        transform: "scale(1.02, 1.02)",
        bg: "secondary",
        color: "dark",
        boxShadow: "md",
      },
      _active: {
        transform: "scale(0.98, 0.98)",
      },
    }),
    currentTag: () => ({
      bg: "current",
      borderRadius: "25",
      color: "white",
      fontWeight: "bold",
      _hover: {
        cursor: "default",
      },
    }),
    notPublishedTag: () => ({
      bg: "primary",
      borderRadius: "25",
      color: "white",
      fontWeight: "bold",
      _hover: {
        cursor: "pointer",
      },
    }),
    // Declare more variants for buttons here "secondary: " etc..
  },
  // default values for size and variants
  defaultProps: {},
};
