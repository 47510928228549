// Use Cases Section
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { InfoModule } from "InfoModule";
import { useWizardStore } from "store";

function UseCases() {
  const { colorMode } = useColorMode();
  const { setStep } = useWizardStore();
  const { t } = useTranslation();

  const handleClick = () => setStep(4);

  const useCasesData = [
    {
      title: t("useCases.holistic.title"),
      image: 5,
      type: "usecases",
      description: t("useCases.holistic.description1"),
      description2: t("useCases.holistic.description2"),
    },
    {
      title: t("useCases.decarbonization.title"),
      image: 6,
      type: "usecases",
      description: t("useCases.decarbonization.description1"),
      description2: t("useCases.decarbonization.description2"),
    },
    {
      title: t("useCases.saveMoney.title"),
      image: 7,
      type: "usecases",
      description: t("useCases.saveMoney.description1"),
      description2: t("useCases.saveMoney.description2"),
    },
  ];

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Stack align="center" spacing={[1, 10]}>
        <Text fontSize={["4xl", "6xl"]} fontWeight="bold">
          {t("useCases.title")}
        </Text>
        <Box display="flex" flexDirection={["column", "row"]} gap={[1, 5]}>
          {useCasesData.map((usecase, i) => (
            <InfoModule
              key={i}
              image={usecase.image}
              title={usecase.title}
              description={usecase.description}
              description2={usecase.description2}
            />
          ))}
        </Box>
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={handleClick}
        >
          {t("useCases.faqButton")}
        </Button>
      </Stack>
    </Center>
  );
}

export default UseCases;
