import { Input } from "@chakra-ui/react";

export const TextInput = ({ placeholder, type, color, onChange, value }) => {
  return (
    <Input
      variant="flushed"
      placeholder={placeholder}
      type={type}
      color={color}
      value={value}
      onChange={onChange}
    />
  );
};
