import {
  NumberInput,
  NumberInputField,
  Box,
  HStack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

export const EmployeeJurisInput = ({
  flag,
  label,
  setValue,
  onChange,
  max,
  min,
  isDisabled,
  onFocus,
  value,
}) => {
  let countryFlag = "react-tel-input flag " + flag.toLowerCase();
  const { colorMode } = useColorMode();
  return (
    <HStack
      display="flex"
      flexDirection="row"
      align="center"
      spacing={["2", "6"]}
    >
      <Box className={countryFlag} paddingRight="25px"></Box>
      <Text
        w={["85vw", "300px"]}
        textAlign="left"
        color={colorMode === "light" ? "gray.900" : "gray.300"}
      >
        {label}
      </Text>
      {isDisabled && (
        <NumberInput
          variant="flushed"
          setvalue={setValue}
          max={max}
          min={min}
          onFocus={onFocus}
          value={value}
          onChange={onChange}
        >
          <NumberInputField isDisabled={isDisabled} />
        </NumberInput>
      )}
    </HStack>
  );
};
