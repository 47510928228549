// Employee Data
import { useState, useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Image,
  chakra,
  HStack,
  NumberInput,
  NumberInputField,
  useColorMode,
  ButtonGroup,
  Fade,
  useDisclosure,
} from "@chakra-ui/react";
import "assets/css/flags.css";
import { useSignUpStore } from "store";
import { EmployeeJurisInput } from "components/Others";
import EmployeeDataPrimary from "assets/illustrations/employee-data-primary.svg";
import EmployeeDataSecondary from "assets/illustrations/employee-data-secondary.svg";
import { CustomModal } from "components/Others";
import { useTranslation } from "react-i18next";

const EmployeeData = () => {
  // To Do: Once store is updated with data, fetch and apply to components here
  const { addEmployees, user, nextStep, prevStep, setStep, isRedirected } =
    useSignUpStore();
  const [totalEmployees, setTotalEmployees] = useState("");
  const [employeeCountries, setEmployeeCountries] = useState({});
  const [currentMax, setCurrentMax] = useState(0);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const modalTitle = t("employeeData.modalTitle");
  const modalDescription = t("employeeData.modalDescription");
  const icon = "mdi-light:account";

  useEffect(() => {
    if (Object.values(employeeCountries).length > 1) {
      let reducedValues = Object.values(employeeCountries).reduce(
        (accum, value) => {
          return parseInt(value) + parseInt(accum);
        }
      );
      setCurrentMax(reducedValues - employeeCountries.total);
    }
  }, [employeeCountries, totalEmployees.total]);

  useEffect(() => {
    if (Object.values(user.employees).length > 0) {
      setTotalEmployees(user.employees.total);
      setTotalEmployees((prevState) => {
        return prevState;
      });
    }
    if (Object.values(user.employees).length > 1) {
      setEmployeeCountries(user.employees);
    }
  }, []);

  const changeHandler = (e, country) => {
    setEmployeeCountries(() => ({
      ...employeeCountries,
      [country]: e,
      total: totalEmployees,
    }));
    addEmployees(employeeCountries);
  };

  const totalChangeHandler = (e) => {
    setTotalEmployees(e);
    setEmployeeCountries(() => ({
      ...employeeCountries,
      total: e,
    }));
    addEmployees(employeeCountries);
  };

  const handleBackClick = () => {
    addEmployees(employeeCountries);
    prevStep();
  };

  const handleNextClick = () => {
    addEmployees(employeeCountries);
    isRedirected ? setStep(6) : nextStep();
  };

  let totalValidation = null;
  if (currentMax) {
    totalValidation =
      parseInt(currentMax) === parseInt(totalEmployees) ? null : (
        <Text
          width={["90vw", "30vw"]}
          textAlign={["center", "left"]}
          color="red"
        >
          {t("employeeData.totalValidation")}
        </Text>
      );
  }

  return (
    <Flex
      flexDirection="column"
      width={["90vw", "100vw"]}
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={
              colorMode === "light"
                ? EmployeeDataPrimary
                : EmployeeDataSecondary
            }
            alt="Employee Illustration"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={3}
              p="1.5rem"
              width={["90vw", "500px"]}
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("employeeData.title")}
              </Heading>
              <Text
                width={["85vw", "430px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("employeeData.description")}
              </Text>
              <Box display="flex" w={["85vw", "100%"]}>
                <chakra.span
                  onClick={onOpen}
                  cursor="pointer"
                  color={colorMode === "light" ? "primary" : "secondary"}
                >
                  {t("whatDoesThisMean")}
                </chakra.span>
              </Box>
              <Text
                textAlign={["center", "left"]}
                fontWeight="bold"
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("employeeData.globalEmployees")}
              </Text>
              <NumberInput min={0} value={totalEmployees} variant="flushed">
                <NumberInputField
                  onChange={(e) => totalChangeHandler(e.target.value)}
                  placeholder={t("employeeData.globalEmployeesPlaceholder")}
                  paddingRight={0}
                />
              </NumberInput>

              <Text
                fontSize="2xl"
                fontWeight="bold"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.100"}
              >
                {t("employeeData.employeesPerJurisdiction")}
              </Text>
              <Text
                width={["85vw", "430px"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("employeeData.jurisdictionDescription")}
              </Text>
              {Object.keys(user.jurisdictions).map((country, i) => (
                <Fade in key={i}>
                  <EmployeeJurisInput
                    flag={country}
                    value={employeeCountries[country]}
                    label={user.jurisdictions[country]}
                    isDisabled={totalEmployees}
                    onChange={(e) => changeHandler(e, country)}
                    setValue={employeeCountries}
                    min={0}
                  />
                </Fade>
              ))}
              {totalValidation}
              <ButtonGroup>
                {!isRedirected ? (
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                ) : null}
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleNextClick}
                  isDisabled={
                    parseInt(currentMax) === parseInt(totalEmployees)
                      ? false
                      : true
                  }
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </HStack>
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
      />
    </Flex>
  );
};

export default EmployeeData;
