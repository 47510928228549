// Basic Info Page
import { useState, useEffect } from "react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  chakra,
  Flex,
  Heading,
  Button,
  InputGroup,
  Stack,
  Box,
  Text,
  Image,
  FormControl,
  FormHelperText,
  InputRightElement,
  Divider,
  HStack,
  useColorMode,
  ButtonGroup,
} from "@chakra-ui/react";
import { useSignUpStore } from "store";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import BasicInfoPrimary from "assets/illustrations/basic-info-primary.svg";
import BasicInfoBlue from "assets/illustrations/basic-info-blue.svg";
import { TextInput } from "components/Others/TextInput";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";

const CFaCheck = chakra(FaCheckCircle);
const CFaExclamation = chakra(FaExclamationCircle);

const BasicInformation = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState(null);

  const { addBasic, user, steps, nextStep, prevStep, setStep, isRedirected } =
    useSignUpStore();
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.basic.name) setName(user.basic.name);
    if (user.basic.companyName) setCompanyName(user.basic.companyName);
    if (user.basic.username) setUsername(user.basic.username);
    if (user.basic.address) setAddress(user.basic.address);
  }, [steps, user.basic]);

  function saveToStore() {
    let tempFields = {
      name: name,
      companyName: companyName,
      username: username,
      address: address.label,
    };

    for (let basicKey of Object.keys(user.basic)) {
      if (tempFields[basicKey] !== undefined)
        addBasic(basicKey, tempFields[basicKey]);
    }
  }

  const handleBackClick = (e) => {
    if (address) saveToStore();
    prevStep();
  };

  const handleNextClick = (e) => {
    saveToStore();
    isRedirected ? setStep(6) : nextStep();
  };

  let usernameValidation = null;
  if (username !== "") {
    usernameValidation =
      username.length < 5 ? (
        <CFaExclamation color="red" />
      ) : (
        <CFaCheck color="secondary" />
      );
  }

  // ToDo: Set up username validation
  // let addressValidation = null;
  // if (address === null) {
  //   addressValidation =
  //   <Text
  //         width={["90vw", "30vw"]}
  //         textAlign={["center", "left"]}
  //         color="red"
  //       >
  //         Your address is required to continue.
  //       </Text>
  // }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
          mb="60px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={colorMode === "light" ? BasicInfoPrimary : BasicInfoBlue}
            alt="IDOONA Logo"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={5}
              p="1.5rem"
              backgroundColor={
                colorMode === "light" ? "whiteAlpha.900" : "gray.800"
              }
            >
              <Heading
                size="2xl"
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "primary" : "gray.100"}
              >
                {t("basicInformation.title")}
              </Heading>
              <Text
                width={["90vw", "30vw"]}
                textAlign={["center", "left"]}
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("basicInformation.description")}
              </Text>
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("basicInformation.yourNameLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type="text"
                    placeholder={t("basicInformation.namePlaceholder")}
                    color={colorMode === "light" ? "primary" : "secondary"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("basicInformation.companyNameLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type="text"
                    placeholder={t("basicInformation.companyNamePlaceholder")}
                    color={colorMode === "light" ? "primary" : "secondary"}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <Text
                  fontWeight="bold"
                  width={["90vw", "30vw"]}
                  textAlign="left"
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("basicInformation.userNameLabel")}
                </Text>
                <InputGroup>
                  <TextInput
                    type="text"
                    placeholder={t("basicInformation.usernamePlaceholder")}
                    color={colorMode === "light" ? "primary" : "secondary"}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    color="gray.300"
                    children={usernameValidation}
                  ></InputRightElement>
                </InputGroup>
                <FormHelperText
                  display="flex"
                  justifyContent="space-between"
                  textAlign="right"
                ></FormHelperText>
              </FormControl>
              <Text
                fontWeight="bold"
                width={["90vw", "30vw"]}
                textAlign="left"
                color={colorMode === "light" ? "gray.900" : "gray.300"}
              >
                {t("basicInformation.hqAddressLabel")}
              </Text>
              <GooglePlacesAutocomplete
                selectProps={{
                  placeholder: address
                    ? address
                    : t("basicInformation.addressPlaceholder"),
                  address,
                  onChange: setAddress,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  },
                }}
              />
              <ButtonGroup>
                {!isRedirected ? (
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                ) : null}
                <Button
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                  onClick={handleNextClick}
                  isDisabled={!address}
                >
                  {t("continue")}
                </Button>
              </ButtonGroup>
              <Divider size="50" />
            </Stack>
          </Box>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default BasicInformation;
