// Navigation Bar: Top
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  useColorMode,
  useDisclosure,
  useBreakpointValue,
  Collapse,
  Divider,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useAuthStore } from "store/authStore";
import { CustomModal } from "components/Others";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
  HamburgerIcon,
  MoonIcon,
  QuestionOutlineIcon,
  SunIcon,
} from "@chakra-ui/icons";
import IdoonaLogoFull from "assets/icons/idoona-full-logo-primary.svg";
import IdoonaLogoFullBlue from "assets/icons/idoona-full-logo-secondary.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, setIsAuthenticated } = useAuthStore();
  const [showLogin, setShowLogin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(true);
  const [showDemo, setShowDemo] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    switch (pathname) {
      case "/":
      case "/questionnaire":
      case "/report":
      case "/imprint":
      case "/forgot-password":
        setShowLogin(true);
        setShowDemo(true);
        break;
      case "/login":
      case "/register":
        setShowLogin(false);
        setShowDemo(true);
        break;
      case "/demo":
        setShowLogin(true);
        setShowDemo(false);
        break;
      default:
        setShowLogin(true);
    }
  }, [pathname]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  const handlehomeClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
    navigate("/");
  };

  const handleSearchCompanyReportsClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
    onOpen();
  };

  const handleLogInClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
    navigate("/login");
  };

  const handleSignUpClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
    navigate("/register");
  };

  const handleSignOutClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(true);
  }, []);

  const handleDemoClicked = () => {
    setIsMenuOpen(false);
    setIsDesktopMenuOpen(false);
    navigate("/demo");
  };

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Box
      pos="absolute"
      display="flex"
      flexDirection="row"
      top={0}
      right={[0, 4]}
      boxShadow="lg"
      padding={["10px", "20px"]}
      gap={["2", "5"]}
      backgroundColor={colorMode === "light" ? "white" : "gray.800"}
      zIndex={5}
      borderBottomLeftRadius={[isMenuOpen ? 0 : 25, 25]}
    >
      {isMobile ? (
        <Box>
          <IconButton
            as={motion.button}
            icon={isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            aria-label="Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            animate={{
              rotate: isMenuOpen ? 720 : 0,
              transition: { ease: "easeInOut" },
            }}
          />
          <Collapse in={isMenuOpen} animateOpacity>
            <Box display="flex" flexDirection="column" gap={7}>
              <Box w="95.5vw"></Box>
              {showLogin && !isAuthenticated ? (
                <>
                  <Button
                    variant={
                      colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                    }
                    onClick={handleLogInClicked}
                  >
                    {t("login")}
                  </Button>
                  <Divider />
                  <Button
                    variant={
                      colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                    }
                    onClick={handleSignUpClicked}
                  >
                    {t("signUp")}
                  </Button>
                  <Divider />
                </>
              ) : isAuthenticated ? (
                <>
                  <Button
                    onClick={handleSignOutClicked}
                    variant={
                      colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                    }
                  >
                    {t("signOut")}
                  </Button>
                  <Divider />
                </>
              ) : null}
              <>
                <Button
                  variant={
                    colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                  }
                  onClick={handleSearchCompanyReportsClicked}
                >
                  {t("searchCompanyReports")}
                </Button>
                <Divider />
              </>
              <Button
                onClick={toggleColorMode}
                variant={
                  colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                }
              >
                {colorMode === "light" ? t("darkMode") : t("lightMode")}
              </Button>
              <Divider />
              {showDemo && (
                <>
                  <Button
                    variant={
                      colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                    }
                    onClick={handleDemoClicked}
                  >
                    {t("demo")}
                  </Button>
                  <Divider />
                </>
              )}
              <>
                <Button
                  onClick={onOpen}
                  variant={
                    colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                  }
                >
                  {t("help")}
                </Button>
                <Divider />
              </>
              <Button
                onClick={() =>
                  switchLanguage(i18n.language === "en" ? "de" : "en")
                }
                variant={
                  colorMode === "light" ? "primaryMenu" : "secondaryMenu"
                }
              >
                {i18n.language === "en" ? t("deutsch") : t("english")}
              </Button>
              <Divider />
              <Image
                h={10}
                mb={5}
                src={
                  colorMode === "light" ? IdoonaLogoFull : IdoonaLogoFullBlue
                }
                alt="IDOONA Logo"
                onClick={handlehomeClicked}
              />
            </Box>
          </Collapse>
        </Box>
      ) : (
        <HStack>
          <IconButton
            as={motion.button}
            icon={isDesktopMenuOpen ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            aria-label="Menu"
            onClick={() => setIsDesktopMenuOpen(!isDesktopMenuOpen)}
            animate={{
              rotate: isDesktopMenuOpen ? 720 : 0,
              transition: { ease: "easeInOut" },
            }}
          />
          <Collapse in={isDesktopMenuOpen} animateOpacity>
            <motion.div
              initial={{ x: "100vw" }}
              animate={{ x: isDesktopMenuOpen ? 0 : "100vw" }}
              transition={{ duration: 0.3 }}
            >
              <Button
                ml={2}
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
                onClick={handlehomeClicked}
              >
                {t("home")}
              </Button>
              {showDemo && (
                <Button
                  ml={5}
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  onClick={handleDemoClicked}
                >
                  {t("demo")}
                </Button>
              )}
              <Button
                ml={5}
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
                onClick={handleSearchCompanyReportsClicked}
              >
                {t("searchCompanyReports")}
              </Button>
              {showLogin && !isAuthenticated ? (
                <>
                  <Button
                    ml={5}
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    onClick={handleLogInClicked}
                  >
                    {t("login")}
                  </Button>
                  <Button
                    ml={5}
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    onClick={handleSignUpClicked}
                  >
                    {t("signUp")}
                  </Button>{" "}
                </>
              ) : isAuthenticated ? (
                <Button
                  ml={5}
                  onClick={handleSignOutClicked}
                  variant={
                    colorMode === "light"
                      ? "primaryOutline"
                      : "secondaryOutline"
                  }
                  width="120px"
                >
                  {t("signOut")}
                </Button>
              ) : null}
              <Button
                ml={5}
                onClick={() =>
                  switchLanguage(i18n.language === "en" ? "de" : "en")
                }
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
              >
                {i18n.language === "en" ? t("de") : t("en")}
              </Button>
              <IconButton
                ml={5}
                icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
                aria-label="Color mode switcher"
                onClick={toggleColorMode}
              >
                {colorMode === "light" ? t("darkMode") : t("lightMode")}
              </IconButton>
              <IconButton
                ml={5}
                icon={<QuestionOutlineIcon />}
                variant={
                  colorMode === "light" ? "primaryHelp" : "secondaryHelp"
                }
                aria-label="Color mode switcher"
                onClick={onOpen}
              >
                {t("help")}
              </IconButton>
            </motion.div>
          </Collapse>
        </HStack>
      )}
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={t("modal.icon")}
        modalTitle={t("modal.title")}
        modalDescription={t("modal.description")}
      />
    </Box>
  );
};

export default NavBar;
