const isProduction = process.env.NODE_ENV === "production";
const isDevelopment = !isProduction;

// BaseURL for React App

export const CONFIG = {
  isProduction,
  isDevelopment,
  baseURL: isDevelopment
    ? "http://localhost:3001/v1/"
    : "https://api.idoona.eu/v1/",
};
