// FAQ Section
import {
  Text,
  Stack,
  Center,
  Button,
  useColorMode,
  chakra,
  Image,
  Divider,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useWizardStore } from "store";
import QuestionPrimary from "assets/illustrations/questions-primary.svg";
import QuestionSecondary from "assets/illustrations/questions-secondary.svg";

function FAQ() {
  const { colorMode } = useColorMode();
  const { setStep } = useWizardStore();
  const { t } = useTranslation();

  const {
    isOpen: isOpenQuestion1,
    onClose: onCloseQuestion1,
    onToggle: onToggleQuestion1,
  } = useDisclosure();
  const {
    isOpen: isOpenQuestion2,
    onClose: onCloseQuestion2,
    onToggle: onToggleQuestion2,
  } = useDisclosure();
  const {
    isOpen: isOpenQuestion3,
    onClose: onCloseQuestion3,
    onToggle: onToggleQuestion3,
  } = useDisclosure();
  const handleClick = () => setStep(5);
  const handleQuestionClick = (questionNumber) => {
    switch (questionNumber) {
      case 1:
        onToggleQuestion1();
        onCloseQuestion2();
        onCloseQuestion3();
        break;
      case 2:
        onToggleQuestion2();
        onCloseQuestion1();
        onCloseQuestion3();
        break;
      case 3:
        onToggleQuestion3();
        onCloseQuestion1();
        onCloseQuestion2();
        break;
      default:
        onCloseQuestion1();
        onCloseQuestion2();
        onCloseQuestion3();
    }
  };

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Stack align="center" spacing={5}>
        <Image
          objectFit="cover"
          src={colorMode === "light" ? QuestionPrimary : QuestionSecondary}
          alt="FAQ Illustration"
          height={["150px", "180px"]}
        />
        <Text fontSize={["3xl", "6xl"]} fontWeight="bold">
          {t("faq.title")}
        </Text>
        <Stack
          textAlign="left"
          spacing="2"
          color={colorMode === "light" ? "black" : "gray.200"}
        >
          <Text
            fontSize="xl"
            w={["90vw", "60vw", "60vw", "50vw"]}
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
            onClick={() => handleQuestionClick(1)}
            _hover={{ cursor: "pointer" }}
          >
            {t("faq.question1")}
          </Text>
          <Collapse in={isOpenQuestion1} startingHeight={1} animateOpacity>
            <Text fontSize="l" w={["90vw", "60vw", "60vw", "50vw"]}>
              {t("faq.answer1.part1")}{" "}
              <chakra.span fontWeight="bold">
                {t("faq.answer1.bold")}
              </chakra.span>
            </Text>
          </Collapse>
          <Divider size="50" />
          <Text
            fontSize="xl"
            w={["90vw", "60vw", "60vw", "50vw"]}
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
            onClick={() => handleQuestionClick(2)}
            _hover={{ cursor: "pointer" }}
          >
            {t("faq.question2")}
          </Text>
          <Collapse in={isOpenQuestion2} startingHeight={1} animateOpacity>
            <Text fontSize="l" w={["90vw", "60vw", "60vw", "50vw"]}>
              {t("faq.answer2.part1")}{" "}
              <chakra.span fontWeight="bold">
                {t("faq.answer2.bold1")}
              </chakra.span>
            </Text>
            <Text fontSize="l" w={["90vw", "60vw", "60vw", "50vw"]} mt={2}>
              <chakra.span fontWeight="bold">
                {t("faq.answer2.bold2")}
              </chakra.span>{" "}
              {t("faq.answer2.part2")}{" "}
              <chakra.span fontWeight="bold">
                {t("faq.answer2.bold3")}
              </chakra.span>{" "}
              {t("faq.answer2.part3")}
            </Text>
          </Collapse>
          <Divider size="50" />
          <Text
            fontSize="xl"
            w={["90vw", "60vw", "60vw", "50vw"]}
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
            onClick={() => handleQuestionClick(3)}
            _hover={{ cursor: "pointer" }}
          >
            {t("faq.question3")}
          </Text>
          <Collapse in={isOpenQuestion3} startingHeight={1} animateOpacity>
            <Text fontSize="l" w={["90vw", "60vw", "60vw", "50vw"]}>
              {t("faq.answer3.part1")}{" "}
              <chakra.span fontWeight="bold">
                {t("faq.answer3.bold1")}
              </chakra.span>{" "}
              {t("faq.answer3.part2")}
            </Text>
            <Text fontSize="l" w={["90vw", "60vw", "60vw", "50vw"]} mt={2}>
              {t("faq.answer3.part3")}{" "}
              <chakra.span fontWeight="bold">
                {t("faq.answer3.bold2")}
              </chakra.span>{" "}
              {t("faq.answer3.part4")}
            </Text>
          </Collapse>
        </Stack>
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={handleClick}
        >
          {t("faq.getInTouch")}
        </Button>
      </Stack>
    </Center>
  );
}

export default FAQ;
