import {
  Box,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
} from "@chakra-ui/react";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";

export const SimpleText = ({
  setAnswer,
  placeholder = "label",
  textType = "number",
  value,
}) => {
  let inputField;
  const { colorMode } = useColorMode();
  if (textType === "string") {
    inputField = (
      <Textarea
        onChange={setAnswer}
        placeholder={placeholder}
        color={colorMode === "light" ? "primary" : "secondary"}
        width={["80vw", "40vw"]}
        height="20vh"
        resize="none"
        value={value}
        sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
      />
    );
  } else if (textType === "number") {
    inputField = (
      <NumberInput>
        <NumberInputField
          onChange={setAnswer}
          variant="outline"
          placeholder={placeholder}
        />
      </NumberInput>
    );
  }

  return <Box>{inputField}</Box>;
};
