import create from "zustand";
import { persist } from "zustand/middleware";

let wizardStore = (set) => ({
  steps: 0,

  setStep: (stepNumber) => set((state) => ({ ...state, steps: stepNumber })),
  nextStep: () => set((state) => ({ ...state, steps: state.steps + 1 })),
  prevStep: () => set((state) => ({ ...state, steps: state.steps - 1 })),
  resetSteps: () => set((state) => ({ ...state, steps: 0 })),

  setIsRedirected: (isRedirected) =>
    set((state) => ({ ...state, isRedirected })),
});

let signUpStore = (set) => ({
  steps: 0,
  // Check if Redirected from Confirmation Page
  isRedirected: false,

  setStep: (stepNumber) => set((state) => ({ ...state, steps: stepNumber })),
  nextStep: () => set((state) => ({ ...state, steps: state.steps + 1 })),
  prevStep: () => set((state) => ({ ...state, steps: state.steps - 1 })),
  resetSteps: () => set((state) => ({ ...state, steps: 0 })),
  resetForm: () =>
    set((state) => ({
      ...state,
      user: {
        credentials: { email: "", password: "", confirmPassword: "" },
        basic: { name: "", companyName: "", username: "", address: "" },
        jurisdictions: {},
        employees: {},
        revenues: {},
        scope: null,
      },
    })),

  setIsRedirected: (isRedirected) =>
    set((state) => ({ ...state, isRedirected })),
  user: {
    credentials: { email: "", password: "", confirmPassword: "" },
    basic: { name: "", companyName: "", username: "", address: "" },
    jurisdictions: {},
    employees: {},
    revenues: {},
    scope: null,
  },
  addCredentials: (key, value) =>
    set((state) => ({
      user: {
        ...state.user,
        credentials: { ...state.user.credentials, [key]: value },
      },
    })),
  // In the set() you get access to entire state of this store, hence you need to
  // persist and call previous state to mutate/change/update the state.
  // You can also use the get() method instead of destructuring entire previous state, these are alternative methods to the logic I have used here

  addBasic: (key, value) =>
    set((state) => ({
      user: {
        ...state.user,
        basic: { ...state.user.basic, [key]: value },
      },
    })),
  addJurisdictions: (value) => {
    set((state) => ({
      user: {
        ...state.user,
        jurisdictions: value,
      },
    }));
  },
  addEmployees: (value) => {
    set((state) => ({
      user: {
        ...state.user,
        employees: value,
      },
    }));
  },
  addRevenues: (value) => {
    set((state) => ({
      user: {
        ...state.user,
        revenues: value,
      },
    }));
  },
  addScope: (value) => {
    set((state) => ({
      user: {
        ...state.user,
        scope: value,
      },
    }));
  },
});

/*

user {
  credentials {
    email: "xyz",
    password: "xyz"
  }
  basic {
    name: "xyz",
    companyName: "xyz",
    username: "xyz",
    address: "xyz"
  }
  jurisdictions {
    MX: "Mexico",
    US: "United States of America, All States"
  }
  employees {
    total: 1000,
    MX: 500,
    US: 500
  }
}

*/

let questionnaireStore = (set) => ({
  steps: 0,
  currentQuestionId: 1,
  questionBank: [],
  currentModuleData: {},
  currentModuleAnswers: [],
  allModuleAnswers: {},
  currentDependentAnswers: [],

  setStep: (stepNumber) => set((state) => ({ ...state, steps: stepNumber })),
  nextStep: () => set((state) => ({ ...state, steps: state.steps + 1 })),
  prevStep: () => set((state) => ({ ...state, steps: state.steps - 1 })),
  resetSteps: () => set((state) => ({ ...state, steps: 0 })),

  // QUESTION BANK METHODS - questionBank is our source of truth for all isAnswered and isModuleAnswered values
  // Set the initial questionBank with BE data
  setQuestionBank: (questionBank) =>
    set((state) => ({ ...state, questionBank: questionBank })),
  // Update all isAnswered values in questionBank for current module by checking currentModuleAnswers
  setQuestionsIsAnswered: () =>
    set((state) => {
      const questionBankCopy = state.questionBank.map((module) => {
        const updatedQuestions = module.questions.map((question) => {
          const foundAnswer = state.currentModuleAnswers.find(
            (answerObj) => answerObj.id === question.id
          );
          if (
            foundAnswer &&
            (foundAnswer.answer?.length ||
              foundAnswer.otherAnswer?.length ||
              foundAnswer.answers?.length)
          ) {
            return { ...question, isAnswered: true };
          } else {
            return { ...question, isAnswered: false };
          }
        });

        return { ...module, questions: updatedQuestions };
      });

      return { ...state, questionBank: questionBankCopy };
    }),
  // Update all isModuleAnswered values in questionBank for all modules by checking allModuleAnswers
  setModuleIsAnswered: () =>
    set((state) => {
      const questionBankCopy = state.questionBank.map((module) => {
        const moduleId = module.moduleId;
        const moduleAnswers = state.allModuleAnswers[moduleId] || [];
        const isLengthMatch = module.questions.length === moduleAnswers.length;
        const isAllAnswersHaveData = moduleAnswers.every(
          (answerObj) =>
            answerObj.answer?.length ||
            answerObj.otherAnswer?.length ||
            answerObj.answers?.length
        );
        const isModuleAnswered = isLengthMatch && isAllAnswersHaveData;
        return { ...module, isModuleAnswered };
      });

      return { ...state, questionBank: questionBankCopy };
    }),

  // CURRENT MODULE DATA METHODS
  setCurrentModuleData: (moduleDataObj) =>
    set((state) => ({ ...state, currentModuleData: moduleDataObj })),
  resetCurrentModuleData: () =>
    set((state) => ({ ...state, currentModuleData: {} })),

  // CURRENT QUESTION METHODS
  setCurrentQuestionId: (currentQuestionId) =>
    set((state) => ({ ...state, currentQuestionId: currentQuestionId })),
  resetCurrentQuestionId: () =>
    set((state) => ({ ...state, currentQuestionId: 0 })),

  // CURRENT MODULE ANSWERS METHODS
  // Check if answers exist for current module in allModuleAnswers and update currentModuleAnswers if so
  setCurrentModuleAnswers: () =>
    set((state) => {
      const { moduleId } = state.currentModuleData;
      const allModuleAnswersCopy = { ...state.allModuleAnswers };

      if (moduleId in allModuleAnswersCopy) {
        const currentModuleAnswers = allModuleAnswersCopy[moduleId];
        return { ...state, currentModuleAnswers: currentModuleAnswers };
      } else {
        const updatedModuleAnswers = state.currentModuleData.questions.map(
          (question) => ({
            id: question.id,
            answer: "",
            answers: [],
            dependentAnswers: [],
          })
        );

        const newState = {
          ...state,
          allModuleAnswers: {
            ...allModuleAnswersCopy,
            [moduleId]: updatedModuleAnswers,
          },
          currentModuleAnswers: updatedModuleAnswers,
        };

        return newState;
      }
    }),
  // Add answers to currentModuleAnswers from component with user input data
  addCurrentModuleAnswers: (
    id,
    answerType,
    question,
    answer,
    questionWeight = null,
    otherAnswer = null,
    answerWeight = null,
    answers = [],
    dependentAnswers = []
  ) =>
    set((state) => {
      const currentModuleAnswersCopy = [...state.currentModuleAnswers];
      const answerIndex = currentModuleAnswersCopy.findIndex(
        (answerObj) => answerObj.id === id
      );
      if (answerIndex === -1) {
        // if the answerObj is not found in the array, add it to the end
        otherAnswer
          ? currentModuleAnswersCopy.push({
              id,
              answerType,
              question,
              answer: null,
              questionWeight,
              otherAnswer,
              answerWeight,
              answers,
              dependentAnswers,
            })
          : currentModuleAnswersCopy.push(
              answer === "Other"
                ? {
                    id,
                    answerType,
                    question,
                    answer: "",
                    questionWeight,
                    answerWeight,
                    answers,
                    dependentAnswers,
                  }
                : {
                    id,
                    answerType,
                    question,
                    answer,
                    questionWeight,
                    answerWeight,
                    answers,
                    dependentAnswers,
                  }
            );
      } else {
        // if the answerObj is found, update the answer
        currentModuleAnswersCopy[answerIndex] = otherAnswer
          ? {
              id,
              answerType,
              question,
              answer: null,
              questionWeight,
              otherAnswer,
              answerWeight,
              answers,
              dependentAnswers,
            }
          : answer === "Other"
          ? {
              id,
              answerType,
              question,
              answer: "",
              questionWeight,
              answerWeight,
              answers,
              dependentAnswers,
            }
          : {
              id,
              answerType,
              question,
              answer,
              questionWeight,
              answerWeight,
              answers,
              dependentAnswers,
            };
      }
      return {
        ...state,
        currentModuleAnswers: currentModuleAnswersCopy,
      };
    }),
  resetCurrentModuleAnswers: () =>
    set((state) => ({ ...state, currentModuleAnswers: [] })),

  addCurrentDependentAnswers: (dependentAnswers) =>
    set((state) => ({
      ...state,
      currentDependentAnswers: dependentAnswers,
    })),

  resetCurrentDependentAnswers: () =>
    set((state) => ({
      ...state,
      currentDependentAnswers: [],
    })),

  loadDependentAnswers: (currentQuestionId, currentModuleAnswers) =>
    set((state) => {
      const currentQuestionAnswers = currentModuleAnswers.find(
        (answer) => answer.dependentAnswers && answer.id === currentQuestionId
      );
      return {
        ...state,
        currentDependentAnswers: currentQuestionAnswers?.dependentAnswers,
      };
    }),

  // ALL MODULE ANSWERS METHODS
  // Update allModuleAnswers when user exits a module
  setAllModuleAnswers: () =>
    set((state) => {
      const allModuleAnswersCopy = { ...state.allModuleAnswers };
      const { moduleId } = state.currentModuleData;
      allModuleAnswersCopy[moduleId] = state.currentModuleAnswers;
      return { ...state, allModuleAnswers: allModuleAnswersCopy };
    }),
  resetAllModuleAnswers: () =>
    set((state) => ({ ...state, allModuleAnswers: {} })),
});

let reportStore = (set) => ({
  steps: 0,
  surveyData: {},
  reportData: {},

  setStep: (stepNumber) => set((state) => ({ ...state, steps: stepNumber })),
  nextStep: () => set((state) => ({ ...state, steps: state.steps + 1 })),
  prevStep: () => set((state) => ({ ...state, steps: state.steps - 1 })),
  resetSteps: () => set((state) => ({ ...state, steps: 0 })),

  setSurveyData: (surveyDataObj) =>
    set((state) => ({ ...state, surveyData: surveyDataObj })),

  setReportData: (reportDataObj) =>
    set((state) => ({ ...state, reportData: reportDataObj })),
});

let modulesCheckedStore = (set) => ({
  checkedModules: null,
  addCheckedModules: (checkedItems) =>
    set(() => ({ checkedModules: checkedItems })),
});

let infoCheckedStore = (set) => ({
  checkedInfo: null,
  addCheckedInfo: (checkedItems) => set(() => ({ checkedInfo: checkedItems })),
});

wizardStore = persist(wizardStore, { name: "wizard" });
signUpStore = persist(signUpStore, { name: "signup" });
reportStore = persist(reportStore, { name: "report" });
questionnaireStore = persist(questionnaireStore, { name: "questionnaire" });
modulesCheckedStore = persist(modulesCheckedStore, { name: "modulesChecked" });
infoCheckedStore = persist(infoCheckedStore, { name: "infoChecked" });

export const useWizardStore = create(wizardStore);
export const useSignUpStore = create(signUpStore);
export const useQuestionnaireStore = create(questionnaireStore);
export const useReportStore = create(reportStore);
export const useModulesCheckedStore = create(modulesCheckedStore);
export const useInfoCheckedStore = create(infoCheckedStore);
