// Login Page
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import {
  Flex,
  Heading,
  Button,
  InputGroup,
  Stack,
  Checkbox,
  chakra,
  Box,
  Text,
  Image,
  FormControl,
  FormHelperText,
  InputRightElement,
  Divider,
  HStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import WelcomeImagePrimary from "assets/illustrations/welcome-back-primary.svg";
import WelcomeImageBlue from "assets/illustrations/welcome-back-blue.svg";
import { TextInput } from "components/Others/TextInput";
import { useAuthStore } from "store/authStore";
import { useMutation } from "react-query";
import { loginUserFn } from "config/authApi";
import { useTranslation } from "react-i18next";
import ButtonGroup from "antd/lib/button/button-group";
import { useReportStore } from "store";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { surveyData } = useReportStore();
  const { setIsAuthenticated } = useAuthStore();

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleBackClick = () => {
    navigate("/");
  };

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  const userNavigator = () => {
    const hasReceivedSurvey = surveyData && Object.keys(surveyData).length;
    return hasReceivedSurvey ? "/report" : "/questionnaire";
  };

  const { mutate: loginUser } = useMutation(
    (userData) => loginUserFn(userData),
    {
      onSuccess: (userData) => {
        setIsAuthenticated(true, userData);
        toastFunction("success", t("loginPage.successfulLogin"));

        setTimeout(() => {
          navigate(userNavigator());
          setIsLoading(false);
        }, 1000);
      },
      onError: (error) => {
        toastFunction("error", t("loginPage.failedLogin"));
        setIsLoading(false);
      },
    }
  );

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler = () => {
    const userdata = { email: email, password: password };
    setIsLoading(true);
    loginUser(userdata);
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
          w="100px"
          mb="60px"
        />
        <HStack>
          <Image
            display={["none", "block"]}
            objectFit="cover"
            src={colorMode === "light" ? WelcomeImagePrimary : WelcomeImageBlue}
            alt="IDOONA Logo"
            h="400px"
          />
          <Box minW={{ base: "90%", md: "468px" }}>
            <form
              marginleft={[null, "100px"]}
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Stack
                spacing={5}
                p="1.5rem"
                backgroundColor={
                  colorMode === "light" ? "whiteAlpha.900" : "gray.800"
                }
              >
                <Heading
                  size="2xl"
                  textAlign={["center", "left"]}
                  color={colorMode === "light" ? "primary" : "gray.100"}
                >
                  {t("loginPage.welcomeBack")}
                </Heading>
                <Text
                  width={["90vw", "30vw"]}
                  textAlign={["center", "left"]}
                  color={colorMode === "light" ? "gray.900" : "gray.300"}
                >
                  {t("loginPage.description")}
                </Text>
                <FormControl>
                  <Text
                    fontWeight="bold"
                    width={["90vw", "30vw"]}
                    textAlign={["center", "left"]}
                  >
                    {t("loginPage.usernameOrEmail")}
                  </Text>
                  <InputGroup>
                    <TextInput
                      type="text"
                      placeholder={t("loginPage.usernameOrEmail")}
                      color={colorMode === "light" ? "primary" : "secondary"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <Text
                    fontWeight="bold"
                    width={["90vw", "30vw"]}
                    textAlign={["center", "left"]}
                  >
                    {t("loginPage.password")}
                  </Text>
                  <InputGroup>
                    <TextInput
                      type={showPassword ? "text" : "password"}
                      placeholder={t("loginPage.password")}
                      color={colorMode === "light" ? "primary" : "secondary"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? t("hide") : t("show")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormHelperText
                    display="flex"
                    justifyContent="space-between"
                    textAlign="right"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      textAlign="right"
                      gap="2"
                    >
                      <Checkbox
                        variant={
                          colorMode === "light" ? "primary" : "secondary"
                        }
                        size="lg"
                      />
                      <Text
                        textAlign={["center", "left"]}
                        color={colorMode === "light" ? "gray.900" : "gray.300"}
                      >
                        {t("loginPage.rememberMe")}
                      </Text>
                    </Box>
                    <Link
                      to="/forgot-password"
                      color={colorMode === "light" ? "primary" : "secondary"}
                    >
                      <chakra.span
                        color={colorMode === "light" ? "primary" : "secondary"}
                      >
                        {t("loginPage.forgotPassword")}
                      </chakra.span>
                    </Link>
                  </FormHelperText>
                </FormControl>
                <ButtonGroup>
                  <Button
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    onClick={handleBackClick}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    type="submit"
                    variant={
                      colorMode === "light"
                        ? "primaryOutline"
                        : "secondaryOutline"
                    }
                    width="120px"
                    marginLeft="10px"
                    isLoading={isLoading}
                  >
                    {t("loginPage.signInButton")}
                  </Button>
                </ButtonGroup>
                <Divider size="50" />
                <Box textAlign="left">
                  {t("loginPage.newToIdoona")}{" "}
                  <Link to="/register">
                    <chakra.span
                      color={colorMode === "light" ? "primary" : "secondary"}
                    >
                      {t("loginPage.createAccount")}
                    </chakra.span>
                  </Link>
                </Box>
              </Stack>
            </form>
          </Box>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default Login;
