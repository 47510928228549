// Public Overview Page
import {
  Text,
  Stack,
  Image,
  useColorMode,
  Box,
  useDisclosure,
  IconButton,
  HStack,
  Button,
  chakra,
  useToast,
} from "@chakra-ui/react";
import IdoonaBadge from "assets/icons/idoona-badge-draft-3-2.svg";
import { CustomModal } from "components/Others/CustomModal";
import { useReportStore } from "store";
import { useAuthStore } from "store/authStore";
import {
  InfoOutlineIcon,
  ArrowBackIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoDark from "assets/icons/idoona-logo-blue.svg";
import FairTradeSeal from "assets/icons/fair-trade-seal.svg";
import PeopleNatureSeal from "assets/icons/people-nature-seal.svg";
import ReforestationPartnerSeal from "assets/icons/reforestation-partner-seal.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function PublicOverview() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { prevStep, reportData } = useReportStore();
  const regulationsArray = reportData.regulations;
  const [countryNames, setCountryNames] = useState([]); // State to store country names
  const toast = useToast();
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();

  const countryNameFinder = async (payload) => {
    try {
      const url = "https://restcountries.com/v3.1/alpha/" + payload;
      const response = await fetch(url);
      const jsonResponse = await response.json();
      const countryName = jsonResponse[0]?.name?.common || "Unknown";
      return countryName;
    } catch (error) {
      console.log(error);
      return "Error";
    }
  };

  const fetchCountryNames = async () => {
    const updatedCountryNames = [];
    for (const item of regulationsArray) {
      if (item.jurisdictionId.toLowerCase() === "eu") {
        updatedCountryNames.push("European Union");
      } else {
        const name = await countryNameFinder(item.jurisdictionId);
        updatedCountryNames.push(name);
      }
    }
    setCountryNames(updatedCountryNames);
  };

  useEffect(() => {
    fetchCountryNames();
  }, []);

  const formatDate = (inputDate) => {
    return new Date(inputDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const toastFunction = (status, title) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: false,
    });
  };

  function formatTimeFrame(timeFrame) {
    const fromDate = new Date(timeFrame.from);
    const toDate = new Date(timeFrame.to);

    const fromMonth = fromDate.toLocaleString("default", { month: "short" });
    const toMonth = toDate.toLocaleString("default", { month: "short" });

    const fromYear = fromDate.getFullYear();
    const toYear = toDate.getFullYear();

    const formattedTimeFrame = `${fromMonth} ${fromYear} to ${toMonth} ${toYear}`;
    return formattedTimeFrame;
  }

  const badgeImageUrl =
    "https://cs210032003553cfe23.blob.core.windows.net/idoona-images/badge-design-draft-3-2.svg";
  const redirectUrl = "https://www.idoona.eu"; // TODO: Redirect to Company's Public overview page

  const leftColHeaders = [
    { key: t("publicOverview.companyInfo.companyName") },
    { key: t("publicOverview.companyInfo.creationDate") },
  ];

  const leftColValues = [
    { key: reportData.displayData.companyName },
    { key: formatDate(reportData.displayData.creationDate) },
  ];

  const rightColHeaders = [
    { key: t("publicOverview.companyInfo.validity") },
    { key: t("publicOverview.companyInfo.timeframe") },
  ];

  const rightColValues = [
    { key: t("publicOverview.current") },
    {
      key: formatTimeFrame(reportData.displayData.timeFrame),
    },
  ];

  const handleGetBadge = () => {
    const badgeHtml = `<a href="${redirectUrl}" target="_blank"><img src="${badgeImageUrl}" alt="IDOONA Certification Badge" /></a>`;

    const textArea = document.createElement("textarea");
    textArea.value = badgeHtml;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    toastFunction("success", t("publicOverview.badgeCopied"));
  };

  return (
    <Stack align="center" spacing="3">
      <Box h={60}></Box>
      <Image objectFit="cover" src={IdoonaBadge} alt="Idoona Badge" />
      <Text fontSize="6xl" fontWeight="bold">
        {t("publicOverview.title")}
      </Text>
      <Box display="flex" gap="5">
        <Box display="flex" flexDirection={"column"} width={145}>
          {leftColHeaders.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text fontWeight="bold" p={2}>
                {item.key}
              </Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={200}>
          {leftColValues.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text p={2}>{item.key}</Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={145}>
          {rightColHeaders.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              <Text fontWeight="bold" p={2}>
                {item.key}
              </Text>
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDirection={"column"} width={185} marginTop={-1}>
          {rightColValues.map((item, i) => (
            <Box key={i} display="flex" p={2}>
              {i === 0 ? (
                <HStack p={0}>
                  <Button variant="currentTag">{item.key}</Button>
                  <IconButton
                    icon={<InfoOutlineIcon />}
                    aria-label="Info"
                    onClick={onOpen}
                    ml={2}
                    variant={
                      colorMode === "light"
                        ? "primaryRegular"
                        : "secondaryRegular"
                    }
                  >
                    {t("publicOverview.moreInfo")}
                  </IconButton>
                </HStack>
              ) : (
                <Text p={2}>{item.key}</Text>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Image
        objectFit="cover"
        src={colorMode === "light" ? IdoonaLogo : IdoonaLogoDark}
        alt="IDOONA Logo"
        w={10}
      />
      <Text w={["90vw", "50vw"]} fontSize="2xl" fontWeight="bold">
        {t("publicOverview.reportSection.title")}
      </Text>
      <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
        <Text w={["90vw", "50vw"]}>
          {t("publicOverview.reportSection.description")}
        </Text>
      </Stack>
      <Stack>
        {regulationsArray.map((item, i) => (
          <HStack key={i}>
            <CheckCircleIcon
              color={colorMode === "light" ? "green" : "lightGreen"}
              width={25}
              height={25}
            />
            <Text minWidth={300} fontWeight="bold" fontSize="xl" align="left">
              {item.regulationName}{" "}
              <chakra.span fontWeight="normal">
                {" "}
                ({item.jurisdictionId})
              </chakra.span>
            </Text>
            <Box width={10}></Box>
            <HStack width={200}>
              <Box
                className={`react-tel-input flag ${item.jurisdictionId.toLowerCase()}`}
              ></Box>
              <Text fontWeight="bold" fontSize="xl" align="left">
                {countryNames[i]}
              </Text>
            </HStack>
            <Button
              variant={
                colorMode === "light" ? "primaryOutline" : "secondaryOutline"
              }
              onClick={onOpen}
            >
              {t("publicOverview.reportSection.viewDetailsButton")}
            </Button>
          </HStack>
        ))}
      </Stack>
      <Image
        objectFit="cover"
        src={colorMode === "light" ? IdoonaLogo : IdoonaLogoDark}
        alt="IDOONA Logo"
        w={10}
      />
      <Text w={["90vw", "50vw"]} fontSize="2xl" fontWeight="bold">
        {t("publicOverview.certificatesSection.title")}
      </Text>
      <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
        <Text w={["90vw", "50vw"]}>
          {t("publicOverview.certificatesSection.description1")}
        </Text>
        <Text w={["90vw", "50vw"]}>
          {t("publicOverview.certificatesSection.description2")}
        </Text>
      </Stack>
      <Box h={5}></Box>
      <HStack align="center" gap={20}>
        <Stack align="center">
          <Image
            objectFit="cover"
            src={PeopleNatureSeal}
            alt="People Nature Seal"
          />
          <Stack color={colorMode === "light" ? "black" : "gray.200"}>
            <Text fontWeight="bold">People & Nature Seal</Text>
            <Text fontStyle="italic">Rainforest Alliance</Text>
          </Stack>
        </Stack>
        <Stack align="center">
          <Image objectFit="cover" src={FairTradeSeal} alt="Fair Trade Seal" />
          <Stack color={colorMode === "light" ? "black" : "gray.200"}>
            <Text fontWeight="bold">FAIRTRADE Seal</Text>
            <Text fontStyle="italic">FLOCERT</Text>
          </Stack>
        </Stack>
        <Stack align="center">
          <Image
            objectFit="cover"
            src={ReforestationPartnerSeal}
            alt="Reforestation Partner Seal"
          />
          <Stack color={colorMode === "light" ? "black" : "gray.200"}>
            <Text fontWeight="bold">Reforestation Partner</Text>
            <Text fontStyle="italic">One Tree Planted</Text>
          </Stack>
        </Stack>
      </HStack>
      <Box h={10}></Box>
      <IconButton
        icon={<ArrowBackIcon />}
        variant={colorMode === "light" ? "primaryOutline" : "secondaryOutline"}
        pos="absolute"
        top={2}
        left={5}
        onClick={prevStep}
      >
        {t("back")}
      </IconButton>
      {isAuthenticated ? (
        <Button
          onClick={handleGetBadge}
          pos="absolute"
          top={2}
          left={79}
          margin="20px"
          mr="140px"
          variant={
            colorMode === "light" ? "primaryOutline" : "secondaryOutline"
          }
        >
          {t("publicOverview.buttons.getBadge")}
        </Button>
      ) : null}
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={t("modal.icon")}
        modalTitle={t("modal.title")}
        modalDescription={t("modal.description")}
      />
    </Stack>
  );
}

export default PublicOverview;
