// Generating Report Page
import React, { useState, useEffect } from "react";
import {
  Text,
  Stack,
  Center,
  Image,
  useColorMode,
  Box,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import { CustomModal } from "components/Others/CustomModal";
import LoadingAnimation from "assets/animations/loading-infinity.gif";
import { useReportStore } from "store";
import { AdBanner } from "components/Others/AdBanner";
import { useTranslation } from "react-i18next";

function GeneratingReport() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const { nextStep, reportData } = useReportStore();
  const { t } = useTranslation();

  useEffect(() => {
    let timer;
    if (reportData && Object.keys(reportData).length) {
      timer = setTimeout(() => {
        setIsReportLoaded(true);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [reportData]);

  return (
    <Center display="flex" justifyContent="center" w="100vw" h="100vh">
      <Box
        pos="absolute"
        display="flex"
        align="center"
        flexDirection={["column", "row"]}
        top="0"
        right={["1", "75"]}
        marginTop={["5px", "20px"]}
        marginRight={["55px", "80px"]}
        gap={["2", "10"]}
      ></Box>
      <Stack align="center" spacing="5">
        <Image
          objectFit="cover"
          src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
          alt="IDOONA Logo"
        />
        <Text fontSize="6xl" fontWeight="bold">
          {isReportLoaded
            ? t("generatingReport.reportReady")
            : t("generatingReport.generatingReport")}
        </Text>
        <Stack spacing="2" color={colorMode === "light" ? "black" : "gray.200"}>
          <Text w={["90vw", "40vw"]}>{t("generatingReport.description")}</Text>
        </Stack>
        {isReportLoaded ? (
          <Button
            variant={colorMode === "light" ? "primary" : "secondary"}
            onClick={nextStep}
          >
            {t("generatingReport.viewReportButton")}
          </Button>
        ) : (
          <Image
            h="10"
            top="65vh"
            src={LoadingAnimation}
            alt="IDOONA Loading"
          />
        )}
        <AdBanner />
      </Stack>
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={t("modal.icon")}
        modalTitle={t("modal.title")}
        modalDescription={t("modal.description")}
      />
    </Center>
  );
}

export default GeneratingReport;
