import {
  Stack,
  Text,
  Button,
  useColorMode,
  Fade,
  Box,
  Image,
  ScaleFade,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModuleItem } from "components/Others";
import GeneralInfoPrimary from "assets/illustrations/general-info-primary.svg";
import GeneralInfoSecondary from "assets/illustrations/general-info-secondary.svg";
import { useInfoCheckedStore, useWizardStore } from "store";

// [{1:false}, {2:false}, {3:false}]

const GeneralInformation = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState([false, false, false]); // change to object in array
  const resetWizardStore = useWizardStore((state) => state.resetSteps);

  const saveCheckedState = useInfoCheckedStore((state) => state.addCheckedInfo);

  const handleClick = () => {
    let checkedObject = Object.assign(
      ...checkedItems.map((value, index) => ({ [index]: value }))
    );
    saveCheckedState(checkedObject);
    resetWizardStore();
    navigate("/");
  };

  const checkHandler = (e, id) => {
    let arr = [...checkedItems];
    arr[id - 1] = e.target.checked;
    setCheckedItems(arr);
  };

  //All Variables to be replaced by Sanity
  const lorem =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida enim purus, eget commodo metus interdum in. Curabitur consectetur nisl et est molestie, sed blandit ante congue. Donec molestie, risus et congue consectetur, quam orci egestas neque, sed pretium nisl erat eget sapien. Nunc tristique rhoncus elit ut varius. Praesent ornare maximus sapien, vel fringilla tellus dapibus quis.";

  const modulesData = [
    {
      id: 1,
      image: 0,
      variant: "module",
      label: "",
      description:
        "My organization is required to print, store, or send physical documents for compliance reasons (e.g. tax compliance or industry regulations).",
    },
    {
      id: 2,
      image: 0,
      variant: "module",
      label: "",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida enim purus, eget commodo metus interdum in.",
    },
    {
      id: 3,
      image: 0,
      variant: "module",
      label: "",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida enim purus, eget commodo metus interdum in.",
    },
  ];
  //End Sanity variable replacement block

  return (
    <Stack w={["90vw", "75vw"]} align="center">
      <ScaleFade initialScale="0.8" in>
        <Image
          w={["90vw", "15vw"]}
          src={
            colorMode === "light" ? GeneralInfoPrimary : GeneralInfoSecondary
          }
          alt="Photocopy Woman"
          marginTop={["2vh", null]}
        />
      </ScaleFade>
      <Text
        fontSize="5xl"
        fontWeight="bold"
        color={colorMode === "light" ? "primary" : "secondary"}
      >
        First Section
      </Text>
      <Text w={["90vw", "75vw"]}>{lorem}</Text>
      <Box h="2vh"></Box>
      <Text
        marginTop={["50px", "20px"]}
        fontWeight="bold"
        fontSize="3xl"
        color={colorMode === "light" ? "primary" : "secondary"}
      >
        General Information
      </Text>
      <Text as="b">
        First, we will require some general information about your organization.
        This includes item 1, item 2, item 3, and item 4. Your selection
        determines the questions you get in this section.
      </Text>
      <Text as="i">
        (Please mark all statements that are accurate for your organization)
      </Text>
      <Stack display="flex" p="5vh" spacing="2" w={["90vw", "45vw"]}>
        {modulesData.map((module, id) => (
          <Fade in key={id}>
            <ModuleItem
              key={module.id}
              id={module.id}
              image={module.image}
              variant={module.variant}
              label={module.label}
              description={module.description}
              onChange={(e) => checkHandler(e, module.id)}
            />
          </Fade>
        ))}
      </Stack>
      <Box>
        <Button
          variant={colorMode === "light" ? "primary" : "secondary"}
          onClick={() => handleClick()}
        >
          Confirm
        </Button>
      </Box>
      <Box h="2vh" display={["block", "none"]}></Box>
    </Stack>
  );
};

export default GeneralInformation;
