import {
  Text,
  Stack,
  Button,
  Box,
  useColorMode,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useWizardStore, useModulesCheckedStore } from "store";
import { useEffect } from "react";

const FirstStage = () => {
  const resetWizardStore = useWizardStore((state) => state.resetSteps);
  const checkedItems = useModulesCheckedStore((state) => state.checkedModules);

  const { colorMode } = useColorMode();
  const lorem =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida enim purus, eget commodo metus interdum in. Curabitur consectetur nisl et est molestie, sed blandit ante congue. Donec molestie, risus et congue consectetur, quam orci egestas neque, sed pretium nisl erat eget sapien. Nunc tristique rhoncus elit ut varius. Praesent ornare maximus sapien, vel fringilla tellus dapibus quis.";

  const handleClick = useWizardStore((state) => state.nextStep);

  useEffect(() => {
    resetWizardStore();
  });

  return (
    <Stack align="center" spacing={["5", "10"]}>
      {resetWizardStore}
      <Text
        marginTop={["50px", "0px"]}
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        color={colorMode === "light" ? "primary" : "secondary"}
      >
        Let's get started!
      </Text>
      <Stack spacing="2">
        <Text w={["90vw", "75vw"]}>{lorem}</Text>
        <Text w={["90vw", "75vw"]}>{lorem}</Text>
        <Stack
          w={["90vw", "75vw"]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <UnorderedList>
            <ListItem>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              gravida enim
            </ListItem>
            <ListItem>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              gravida enim
            </ListItem>
            <ListItem>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              gravida enim
            </ListItem>
            <ListItem>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              gravida enim
            </ListItem>
          </UnorderedList>
        </Stack>
        <Text w={["90vw", "75vw"]}>{lorem}</Text>
      </Stack>
      <Button
        variant={colorMode === "light" ? "primary" : "secondary"}
        onClick={handleClick}
      >
        I'm Ready!
      </Button>
      <Box h="2vh" display={["block", "none"]}></Box>
    </Stack>
  );
};

export default FirstStage;
