import "@fontsource/m-plus-rounded-1c/400.css";
import "tailwindcss/tailwind.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import theme from "assets/theme";
import GettingStarted from "pages/public/GettingStarted";
import "@fontsource/m-plus-rounded-1c";
import Login from "pages/public/Login";
import SignUp from "pages/public/SignUp";
import Report from "pages/public/Report";
import NewHome from "pages/public/NewHome";
import ResetPassword from "pages/public/ResetPassword";
import Questionnaire from "pages/public/Questionnaire";
import Imprint from "pages/public/Imprint";
import Demo from "pages/public/Demo";
import ComingSoon from "pages/public/ComingSoon";
import PitchDeck from "pages/public/PitchDeck";
import "./App.css";
import "antd/dist/antd.min.css";
import { Layout } from "../Layout";
import { PrivateRoute } from "../../router/PrivateRoute";
import { QueryClientProvider, QueryClient } from "react-query";
import NavBar from "components/NavBar";
import { CONFIG } from "config";

const queryClient = new QueryClient();
const isDevelopment = CONFIG.isDevelopment;

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <NavBar />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<NewHome />} />
            <Route path="/login" element={<Login />} />
            {isDevelopment ? (
              <>
                <Route path="/register" element={<SignUp />} />
                <Route path="/forgot-password" element={<ResetPassword />} />
              </>
            ) : (
              <>
                <Route path="/register" element={<ComingSoon />} />
                <Route path="/forgot-password" element={<ComingSoon />} />
              </>
            )}
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/pitch-deck" element={<PitchDeck />} />
            <Route element={<PrivateRoute />}>
              <Route path="/getting-started" element={<GettingStarted />} />
              <Route path="/questionnaire" element={<Questionnaire />} />
              <Route path="/report" element={<Report />} />
            </Route>
            <Route path="*" element={<NewHome />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
