// Amazing
import {
  Text,
  Stack,
  Center,
  Image,
  Button,
  useColorMode,
  ScaleFade,
} from "@chakra-ui/react";
import { useWizardStore } from "store";
import IdoonaLogo from "assets/icons/idoona-logo.svg";
import IdoonaLogoBlue from "assets/icons/idoona-logo-blue.svg";
import LoadingAnimation from "assets/animations/loading-infinity.gif";
import { useState, useEffect } from "react";

function Amazing() {
  const { colorMode } = useColorMode();
  const [done, setDone] = useState(false);

  const handleClick = useWizardStore((state) => state.nextStep);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDone(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Center h="100vh" w="100vw" align="center">
      <Stack align="center" spacing="5">
        <ScaleFade initialScale="0.8" in>
          <Image
            w="40"
            position="relative"
            objectFit="cover"
            src={colorMode === "light" ? IdoonaLogo : IdoonaLogoBlue}
            alt="IDOONA Logo"
          />
        </ScaleFade>
        <Text
          fontSize="5xl"
          fontWeight="bold"
          color={colorMode === "light" ? "primary" : "secondary"}
        >
          Amazing!
        </Text>
        <Text w={["90vw", "30vw"]}>
          We are now getting your survey ready. This takes a few seconds. You
          can get a cup of tea and the first question will be ready when you
          return.
        </Text>
        {!done ? (
          <Image
            h="10"
            top="65vh"
            src={LoadingAnimation}
            alt="IDOONA Loading"
          />
        ) : (
          <Button
            margin={["5", "20"]}
            onClick={handleClick}
            variant={colorMode === "light" ? "primary" : "secondary"}
          >
            Next
          </Button>
        )}
      </Stack>
      <Text
        position="absolute"
        w={["90vw", "24vw"]}
        bottom="20"
        right="0"
        display="none"
        margin={["5", "20"]}
        textAlign="right"
      >
        This is merely a prototype, so it would be very rude to hold you hostage
        on this screen. Below, you can find a shortcut that will bring you to
        the next stage of the prototype. Move along!
      </Text>
      <Button
        pos="absolute"
        bottom="0"
        right="0"
        display="none"
        margin={["5", "20"]}
        onClick={handleClick}
        variant={colorMode === "light" ? "primaryOutline" : "secondaryOutline"}
      >
        Take the Shortcut
      </Button>
    </Center>
  );
}

export default Amazing;
