import { Radio, Text, useColorMode } from "@chakra-ui/react";

export const MultipleChoice = ({ variant = false, onChange, value, width }) => {
  const { colorMode } = useColorMode();
  let currentVariant;
  if (variant) {
    currentVariant =
      colorMode === "light" ? "primaryOutlineLong" : "secondaryOutlineLong";
  } else {
    currentVariant =
      colorMode === "light" ? "primaryOutline" : "secondaryOutline";
  }
  return (
    <Radio
      flexDirection="row"
      gap="4"
      variant={currentVariant}
      onChange={onChange}
      value={value}
      w={width}
    >
      <Text textAlign="left">{value}</Text>
    </Radio>
  );
};
