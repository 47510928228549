import { Text, useColorMode, Image, Stack } from "@chakra-ui/react";
import dataEntryPrimary from "assets/illustrations/data-entry-primary.svg";
import dataEntrySecondary from "assets/illustrations/data-entry-secondary.svg";
import dataOverviewPrimary from "assets/illustrations/data-overview-primary.svg";
import dataOverviewSecondary from "assets/illustrations/data-overview-secondary.svg";
import getAScorePrimary from "assets/illustrations/get-a-score-primary.svg";
import getAScoreSecondary from "assets/illustrations/get-a-score-secondary.svg";
import holisticSustainabilityPrimary from "assets/illustrations/holistic-sustainability-primary.svg";
import holisticSustainabilitySecondary from "assets/illustrations/holistic-sustainability-secondary.svg";
import marketDataPrimary from "assets/illustrations/market-data-primary.svg";
import marketDataSecondary from "assets/illustrations/market-data-secondary.svg";
import saveMoneyPrimary from "assets/illustrations/save-money-primary.svg";
import saveMoneySecondary from "assets/illustrations/save-money-secondary.svg";
import manufacturingPrimary from "assets/illustrations/manufacturing-primary.svg";
import manufacturingSecondary from "assets/illustrations/manufacturing-secondary.svg";

export const InfoModule = ({
  index,
  image,
  title,
  description,
  description2,
}) => {
  const { colorMode } = useColorMode();

  let moduleImage;
  switch (image) {
    case 1:
      if (colorMode === "light") moduleImage = dataEntryPrimary;
      else moduleImage = dataEntrySecondary;
      break;
    case 2:
      if (colorMode === "light") moduleImage = marketDataPrimary;
      else moduleImage = marketDataSecondary;
      break;
    case 3:
      if (colorMode === "light") moduleImage = getAScorePrimary;
      else moduleImage = getAScoreSecondary;
      break;
    case 4:
      if (colorMode === "light") moduleImage = dataOverviewPrimary;
      else moduleImage = dataOverviewSecondary;
      break;
    case 5:
      if (colorMode === "light") moduleImage = holisticSustainabilityPrimary;
      else moduleImage = holisticSustainabilitySecondary;
      break;
    case 6:
      if (colorMode === "light") moduleImage = manufacturingPrimary;
      else moduleImage = manufacturingSecondary;
      break;
    case 7:
      if (colorMode === "light") moduleImage = saveMoneyPrimary;
      else moduleImage = saveMoneySecondary;
      break;
    default:
      if (colorMode === "light") moduleImage = dataEntryPrimary;
      else moduleImage = dataEntrySecondary;
      break;
  }

  return (
    <Stack
      display="flex"
      align="center"
      spacing={["0", "5"]}
      w={["90vw", "30vw", "20vw", "17vw"]}
      color={colorMode === "light" ? "black" : "gray.200"}
    >
      <Stack
        display="flex"
        flexDirection={["row", "column"]}
        alignItems={["flex-start", "center"]}
      >
        {image && (
          <Image
            maxWidth={["20", "40"]}
            h={["20", "40"]}
            src={moduleImage}
            paddingRight={["10px", "0px"]}
          />
        )}
        <Stack>
          {index && (
            <Text
              fontSize="xl"
              fontWeight="bold"
              textAlign={["left", "center"]}
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              Step {index}
            </Text>
          )}
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {title}
          </Text>
        </Stack>
      </Stack>
      <Text fontSize="l" textAlign="left">
        {description}
      </Text>
      {description2 && (
        <Text fontSize="l" textAlign="left">
          {description2}
        </Text>
      )}
    </Stack>
  );
};
