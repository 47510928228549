import {
  HStack,
  Text,
  Checkbox,
  Image,
  useColorMode,
  Divider,
  chakra,
  Radio,
  Box,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon, EditIcon } from "@chakra-ui/icons";
import officePrimary from "assets/illustrations/office-processes-primary.svg";
import officeSecondary from "assets/illustrations/office-processes-secondary.svg";
import paperPrimary from "assets/illustrations/paper-usage-primary.svg";
import paperSecondary from "assets/illustrations/paper-usage-secondary.svg";
import energyPrimary from "assets/illustrations/energy-usage-primary.svg";
import energySecondary from "assets/illustrations/energy-usage-secondary.svg";
import socialPrimary from "assets/illustrations/social-impact-primary.svg";
import socialSecondary from "assets/illustrations/social-impact-secondary.svg";
import workPrimary from "assets/illustrations/work-environment-primary.svg";
import workSecondary from "assets/illustrations/work-environment-secondary.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ModuleItem = ({
  id,
  variant,
  image,
  isAnswered,
  isChecked,
  code = "US",
  name,
  label = "Label",
  description = "Description",
  onChange,
  value,
  onClick,
  answer,
  answers = [],
  dependentAnswers = [],
}) => {
  const { colorMode } = useColorMode();
  let moduleImage, displayComponent, questionStatusIcon, questionGlow;
  let countryFlag = "react-tel-input flag " + code.toLowerCase();
  const { t } = useTranslation();
  let dividerComponent = () => {
    return id > 1 && variant !== "scope" && variant !== "question" ? (
      <Divider
        borderColor={colorMode === "light" ? "primary" : "secondary"}
        marginBottom={variant === "question" ? "10px" : "5px"}
      />
    ) : null;
  };

  const questionComponent = () => {
    return (
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "5"]}
        marginRight={[null, "10px"]}
        onClick={onClick}
        cursor="pointer"
        padding="10px"
        border="2px solid"
        borderRadius="15px"
        borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
        boxShadow={questionGlow}
      >
        <Box
          w={["80vw", "50vw"]}
          textAlign="left"
          color={colorMode === "light" ? "gray.900" : "gray.300"}
        >
          <Text>
            <chakra.span
              fontWeight="bold"
              color={colorMode === "light" ? "primary" : "gray.100"}
            >
              {label}
            </chakra.span>
            : {description}
          </Text>
          {answers.length ? (
            <>
              <br />
              <Text color={colorMode === "light" ? "primary" : "secondary"}>
                {answers.join(", ")}
              </Text>
            </>
          ) : null}
          {answer ? (
            <>
              <br />
              <Text color={colorMode === "light" ? "primary" : "secondary"}>
                {answer.toString()}
              </Text>
            </>
          ) : null}
          {dependentAnswers.length ? (
            <>
              <br />
              <Text
                fontWeight="bold"
                fontSize="sm"
                mb={2}
                color={colorMode === "light" ? "primary" : "secondary"}
              >
                {Array.isArray(dependentAnswers) &&
                  dependentAnswers &&
                  "Dependent Questions: "}
              </Text>
              {Array.isArray(dependentAnswers) &&
                dependentAnswers.map((answerObj, i) => {
                  return (
                    <HStack
                      display="flex"
                      flexDirection={["column", "row"]}
                      align="center"
                      spacing={["0", "5"]}
                      marginRight={[null, "10px"]}
                      key={answerObj.questionId}
                      padding="10px"
                      border="2px solid"
                      borderRadius="15px"
                      borderColor={
                        colorMode === "light" ? "primary" : "secondary"
                      }
                      boxShadow={questionGlow}
                    >
                      <Box
                        w={["70vw", "32vw"]}
                        textAlign="left"
                        color={colorMode === "light" ? "gray.900" : "gray.300"}
                      >
                        <Text>
                          <chakra.span
                            fontWeight="bold"
                            color={
                              colorMode === "light" ? "primary" : "gray.100"
                            }
                          >
                            {i + 1}
                          </chakra.span>
                          : {answerObj.question.toString()}
                        </Text>
                        {answerObj.answer ? (
                          <>
                            <br />
                            <Text
                              color={
                                colorMode === "light" ? "primary" : "secondary"
                              }
                            >
                              {answerObj.answer.toString()}
                            </Text>
                          </>
                        ) : null}
                      </Box>
                    </HStack>
                  );
                })}
            </>
          ) : null}
        </Box>
        {questionStatusIcon}
      </HStack>
    );
  };

  const moduleComponent = () => {
    return (
      <HStack
        display="flex"
        flexDirection={["column", "row"]}
        align="center"
        spacing={["0", "10"]}
      >
        {image && (
          <Image
            maxWidth="20"
            h="20"
            src={moduleImage}
            marginTop={["20px", "0px"]}
          />
        )}
        <Box w={["90vw", "50vw"]} textAlign="left">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "primary" : "secondary"}
          >
            {label}
          </Text>
          {description}
        </Box>
        <Box p="10px">
          <Checkbox
            variant={colorMode === "light" ? "primary" : "secondary"}
            size="lg"
            onChange={onChange}
          />
        </Box>
      </HStack>
    );
  };

  const scopeComponent = () => {
    return (
      <HStack display="flex" flexDirection="row" align="center">
        <Box p="10px">
          <Radio
            variant={colorMode === "light" ? "primary" : "secondary"}
            size="lg"
            value={value}
          />
        </Box>
        <Box
          w={["70vw", "370px"]}
          textAlign="left"
          display="flex"
          flexDirection="column"
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            color={colorMode === "light" ? "gray.900" : "gray.100"}
          >
            {label}
          </Text>
          <Text
            w={["70vw", "370px"]}
            color={colorMode === "light" ? "gray.900" : "gray.300"}
          >
            {description}
          </Text>
          <Link to="#">
            <chakra.span
              color={colorMode === "light" ? "primary" : "secondary"}
            >
              {t("details")}
            </chakra.span>
          </Link>
        </Box>
      </HStack>
    );
  };

  const countrySelectorComponent = () => {
    return (
      <HStack
        display="flex"
        flexDirection="row"
        align="center"
        spacing={["0", "6"]}
      >
        <Box p={["3px", "10px"]}>
          <Checkbox
            variant={colorMode === "light" ? "primary" : "secondary"}
            size="lg"
            onChange={onChange}
            isChecked={isChecked}
          />
        </Box>
        <Text
          w={["80vw", "300px"]}
          textAlign="left"
          color={colorMode === "light" ? "gray.900" : "gray.300"}
        >
          {name}
        </Text>
        <Box className={countryFlag}></Box>
      </HStack>
    );
  };

  const yourJurisdictionsComponent = () => {
    return (
      <HStack
        display="flex"
        flexDirection="row"
        align="center"
        spacing={["0", "6"]}
      >
        <Box p={["3px", "10px"]}>
          <Checkbox
            variant={colorMode === "light" ? "primary" : "secondary"}
            size="lg"
            isChecked={true}
          />
        </Box>
        <Text
          w={["85vw", "300px"]}
          textAlign="left"
          color={colorMode === "light" ? "gray.900" : "gray.300"}
        >
          {name}
        </Text>
        <Box className={countryFlag}></Box>
      </HStack>
    );
  };

  switch (image) {
    case 1:
      if (colorMode === "light") moduleImage = officePrimary;
      else moduleImage = officeSecondary;
      break;
    case 2:
      if (colorMode === "light") moduleImage = paperPrimary;
      else moduleImage = paperSecondary;
      break;
    case 3:
      if (colorMode === "light") moduleImage = energyPrimary;
      else moduleImage = energySecondary;
      break;
    case 4:
      if (colorMode === "light") moduleImage = socialPrimary;
      else moduleImage = socialSecondary;
      break;
    case 5:
      if (colorMode === "light") moduleImage = workPrimary;
      else moduleImage = workSecondary;
      break;
    default:
      moduleImage = null;
      break;
  }

  if (isAnswered === "true" || isAnswered === true) {
    questionStatusIcon = (
      <CheckCircleIcon color={colorMode === "light" ? "green" : "lightGreen"} />
    );
    questionGlow =
      colorMode === "light" ? "0 0 0 2px #2A5050" : "0 0 0 2px #6BA6C7";
  } else if (isAnswered === "false" || isAnswered === false) {
    questionStatusIcon = <WarningIcon color="darkorange" />;
    questionGlow = "none";
  } else {
    questionStatusIcon = (
      <EditIcon color={colorMode === "light" ? "primary" : "secondary"} />
    );
    questionGlow = "none";
  }

  switch (variant) {
    case "module":
      displayComponent = moduleComponent();
      break;
    case "question":
      displayComponent = questionComponent();
      break;
    case "jurisdictions":
      displayComponent = yourJurisdictionsComponent();
      break;
    case "scope":
      displayComponent = scopeComponent();
      break;
    default:
      displayComponent = countrySelectorComponent();
      break;
  }

  return (
    <>
      {dividerComponent()}
      {displayComponent}
    </>
  );
};
