// Single Question page
import {
  Box,
  Text,
  Stack,
  Button,
  ButtonGroup,
  useColorMode,
  RadioGroup,
  useDisclosure,
  IconButton,
  CheckboxGroup,
  Checkbox,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CustomModal } from "components/Others/CustomModal";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Module } from "components/Others";
import { useQuestionnaireStore } from "store";
import { useState, useEffect } from "react";
import { MultipleChoice } from "components/AnswerTypes/MultipleChoice";
import { SimpleText } from "components/AnswerTypes/SimpleText";
import { FileUrl } from "components/AnswerTypes/FileUrl";
import { darkScrollBar } from "assets/theme/components/scrollBar/darkScrollBar";
import { lightScrollBar } from "assets/theme/components/scrollBar/lightScrollBar";
import { FileUpload } from "components/AnswerTypes/FileUpload";
import { getModuleQuestionList } from "utils/getModuleQuestionList";
import { OtherText } from "components/AnswerTypes/OtherText";
import DependentQuestions from "./DependentQuestions";
import { useTranslation } from "react-i18next";

const SingleQuestion = () => {
  const { colorMode } = useColorMode();
  const {
    prevStep,
    currentQuestionId,
    currentDependentAnswers,
    addCurrentModuleAnswers,
    setCurrentQuestionId,
    currentModuleAnswers,
    questionBank,
    currentModuleData,
    resetCurrentDependentAnswers,
    loadDependentAnswers,
  } = useQuestionnaireStore();
  const [question, setQuestion] = useState({ id: "", question: "" });
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [isOtherAnswerSelected, setIsOtherAnswerSelected] = useState(false);
  const [dependentQuestions, setDependentQuestions] = useState(null);
  const { t } = useTranslation();
  const isLastQuestionChecker = () => {
    return currentModuleData.questions.findIndex((question) => {
      return question.id === currentQuestionId;
    }) ===
      currentModuleData.questions.length - 1
      ? true
      : false;
  };
  const nextQuestion =
    currentModuleData.questions[
      currentModuleData.questions.findIndex((question) => {
        return question.id === currentQuestionId;
      }) + 1
    ];
  const nextQuestionId = nextQuestion ? nextQuestion.id : 0;
  const [userAnswer, setUserAnswer] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [otherAnswer, setOtherAnswer] = useState("");
  const userQuestion = question?.question;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalTitle = t("singleQuestionPage.modalTitle");
  const modalDescription = t("singleQuestionPage.modalDescription");
  const icon = "mdi-light:alert";
  let transformedData;

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  useEffect(() => {
    setIsLastQuestion(isLastQuestionChecker());
  }, [currentQuestionId]);

  useEffect(() => {
    if (userAnswer === "Other") {
      setIsOtherAnswerSelected(true);
    } else {
      setIsOtherAnswerSelected(false);
      setOtherAnswer("");
    }
  }, [userAnswer]);

  useEffect(() => {
    if (userAnswers.includes("Other")) {
      setIsOtherAnswerSelected(true);
    } else {
      setIsOtherAnswerSelected(false);
      setOtherAnswer("");
    }
  }, [userAnswers]);

  const answerChoices = currentModuleData.questions.find(
    (currentQuestion) => currentQuestion.id === currentQuestionId
  ).answerChoices;
  const [newAnswer, setNewAnswer] = useState({});

  useEffect(() => {
    const answerObject = answerChoices.find(
      (selectedAnswer) => selectedAnswer.answerChoice === userAnswer
    );
    setNewAnswer(answerObject);
    setDependentQuestions(answerObject?.triggeredQuestions);
  }, [userAnswer]);

  useEffect(() => {
    loadDependentAnswers(currentQuestionId, currentModuleAnswers);
  }, [dependentQuestions]);

  useEffect(() => {
    if (currentModuleAnswers) {
      const [answer] = currentModuleAnswers.filter(
        (answerObj) => answerObj.id === currentQuestionId
      );
      if (answer) {
        if (answer.otherAnswer) {
          setOtherAnswer(answer.otherAnswer);
          setUserAnswer("Other");
          if (answer.answers && answer.answers.length) {
            setUserAnswers(answer.answers);
          }
        } else {
          setUserAnswer(answer.answer);
          setOtherAnswer("");
          if (answer.answers && answer.answers.length) {
            setUserAnswers(answer.answers);
          }
        }
      } else {
        setUserAnswer("");
        setOtherAnswer("");
        setUserAnswers([]);
      }
    }
  }, [currentQuestionId, currentModuleAnswers, setUserAnswer]);

  useEffect(() => {
    if (currentQuestionId) {
      transformedData = getModuleQuestionList(currentModuleData.questions);
      setQuestion(
        transformedData.find((question) => question.id === currentQuestionId)
      );
    }
  }, [currentQuestionId, questionBank, currentQuestionId]);

  const isCurrentQuestionnaireComplete = () =>
    currentModuleAnswers.length === currentModuleData.questions.length &&
    currentModuleAnswers.every((question) => {
      if (question.answerType === "multipleSelect") {
        return Boolean(question.answers.length) && otherAnswer
          ? otherAnswer.length > 0
          : true;
      } else {
        return Boolean(question.answer) && otherAnswer
          ? otherAnswer.length > 0
          : true;
      }
    });

  const getAnswerWeight = () => {
    const selectedAnswer = question.answers.find(
      (answerChoiceObj) => answerChoiceObj.answerChoice === userAnswer
    );
    return selectedAnswer ? selectedAnswer.answerWeight : null;
  };

  const handleButtonClick = (action) => {
    let answerWeight = null;
    if (question?.answerType === "multipleChoice") {
      answerWeight = getAnswerWeight();
    }
    if (question) {
      addCurrentModuleAnswers(
        question.id,
        question.answerType,
        userQuestion,
        userAnswer,
        question.questionWeight ? question.questionWeight : null,
        otherAnswer ? otherAnswer : null,
        answerWeight ? answerWeight : null,
        userAnswers,
        currentDependentAnswers
      );
    }
    if (action === "next") {
      setCurrentQuestionId(nextQuestionId);
      resetCurrentDependentAnswers();
      loadDependentAnswers(currentQuestionId, currentModuleAnswers);
    } else prevStep();
  };

  const handleBackClick = () => {
    let answerWeight = null;
    if (question?.answerType === "multipleChoice") {
      answerWeight = getAnswerWeight();
    }
    if (question) {
      addCurrentModuleAnswers(
        question.id,
        question.answerType,
        userQuestion,
        "",
        question.questionWeight ? question.questionWeight : null,
        otherAnswer ? "" : null,
        answerWeight ? answerWeight : null,
        userAnswers,
        currentDependentAnswers
      );
    }
    prevStep();
  };

  let answerDisplay;
  switch (question?.answerType) {
    case "multipleChoice":
    case "checkBoxes":
      answerDisplay = (
        <RadioGroup
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          gap="2"
          padding="10px"
          w={["90vw", "60vw"]}
          maxHeight="300px"
          overflow="auto"
          sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
          onChange={setUserAnswer}
          value={userAnswer}
        >
          {question.answers.map((answer, i) => {
            return (
              <MultipleChoice
                key={i}
                id={answer.id}
                variant={
                  question.answers[0].answerChoice.length > 3 ? true : false
                }
                value={answer.answerChoice}
                width={
                  Math.max(
                    ...question.answers.map(
                      (answer) => answer.answerChoice.length
                    )
                  ) < 25
                    ? "300px"
                    : "full"
                }
              />
            );
          })}
        </RadioGroup>
      );
      break;
    case "multipleSelect":
      answerDisplay = (
        <Box
          w={["90vw", "60vw"]}
          maxHeight="250px"
          overflow="auto"
          sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
        >
          <CheckboxGroup
            onChange={(newUserAnswers) => setUserAnswers(newUserAnswers)}
            value={userAnswers}
          >
            {question.answers.map((answer, i) => (
              <Checkbox
                key={i}
                flexDirection="row"
                marginTop={1}
                variant={
                  colorMode === "light" ? "primaryOutline" : "secondaryOutline"
                }
                onChange={(selectedValue) => {
                  setUserAnswers((prevUserAnswers) => {
                    if (prevUserAnswers?.includes(selectedValue)) {
                      return prevUserAnswers.filter(
                        (value) => value !== selectedValue
                      );
                    } else {
                      if (typeof selectedValue === "string") {
                        return [...prevUserAnswers, selectedValue];
                      }
                      return [...prevUserAnswers];
                    }
                  });
                }}
                isChecked={userAnswers.includes(answer.answerChoice)}
                value={answer.answerChoice}
                w={
                  Math.max(
                    ...question.answers.map(
                      (answer) => answer.answerChoice.length
                    )
                  ) < 25
                    ? "300px"
                    : "50vw"
                }
              >
                <Text textAlign="left">{answer.answerChoice}</Text>
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Box>
      );
      break;
    case "simpleText":
      answerDisplay = (
        <SimpleText
          placeholder={t("singleQuestionPage.placeholderText")}
          textType="string"
          value={userAnswer}
          setAnswer={(e) => setUserAnswer(e.target.value)}
        />
      );
      break;
    case "fileUrl":
      answerDisplay = (
        <>
          <FileUrl
            label={t("singleQuestionPage.policyUrl")}
            placeholder={t("singleQuestionPage.fileUrlPlaceholder")}
            value={userAnswer}
            setAnswer={(e) => setUserAnswer(e.target.value)}
          />
          <FileUpload />
        </>
      );
      break;
    default:
      answerDisplay = "";
      break;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={["flex-start", "center"]}
      justifyContent="center"
      w="100vw"
      h="100vh"
      gap="10px"
      overflow="auto"
      sx={colorMode === "light" ? darkScrollBar : lightScrollBar}
    >
      <Module
        name={currentModuleData.moduleName}
        description={currentModuleData.moduleDescription}
        regulations={question?.regulations}
      />
      <Stack>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={colorMode === "light" ? "primary" : "secondary"}
        >
          {question?.label}
        </Text>
        <Text fontSize="lg" fontWeight="bold" Text w={["90vw", "60vw"]}>
          {question?.question}
        </Text>
        <Text
          Text
          w={["90vw", "60vw"]}
          fontSize="lg"
          fontStyle="italic"
          color={colorMode === "light" ? "black" : "gray.200"}
        >
          {question?.description}
        </Text>
      </Stack>
      {answerDisplay}
      {isOtherAnswerSelected && (
        <OtherText
          placeholder={t("singleQuestionPage.addYourOption")}
          textType="string"
          value={otherAnswer}
          setAnswer={(e) => setOtherAnswer(e.target.value)}
        />
      )}
      <ButtonGroup>
        {userAnswer ? (
          <Button
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            width="200px"
            onClick={() => handleButtonClick()}
          >
            {(isLastQuestion && userAnswer) || isCurrentQuestionnaireComplete()
              ? t("singleQuestionPage.reviewQuestions")
              : t("singleQuestionPage.saveForLater")}
          </Button>
        ) : (
          <Button
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            width="200px"
            onClick={() => handleBackClick()}
          >
            {t("goBack")}
          </Button>
        )}
        {!isLastQuestion ? (
          <Button
            variant={
              colorMode === "light" ? "primaryOutline" : "secondaryOutline"
            }
            width="200px"
            onClick={() => handleButtonClick("next")}
          >
            {t("singleQuestionPage.nextQuestion")}
          </Button>
        ) : null}
      </ButtonGroup>
      {dependentQuestions && (
        <DependentQuestions dependentQuestions={dependentQuestions} />
      )}
      {!isMobile && (
        <IconButton
          icon={<ArrowBackIcon />}
          variant={
            colorMode === "light" ? "primaryOutline" : "secondaryOutline"
          }
          pos="absolute"
          top="0"
          left="0"
          margin="20px"
          onClick={userAnswer ? onOpen : () => handleBackClick()}
        >
          {t("goBack")}
        </IconButton>
      )}
      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        icon={icon}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        goBack={handleBackClick}
        save={handleButtonClick}
      />
    </Box>
  );
};

export default SingleQuestion;
